import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default () => {
  return [
    {
      title: 'Chart ID',
      dataIndex: '',
      key: 'chartid',
      sorter: (a, b) => a.chartid - b.chartid,
      render: (text, record) => (
        <Link
          to={`/landing-page/memberdetails/view/${record.internalmemberid}?chartid=${record.chartid}&memberid=${record.memberid}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.chartid}
        </Link>
      ),
    },
    {
      title: 'Full Name',
      dataIndex: 'memberfullname',
      key: 'memberfullname',
      sorter: (a, b) => (a.memberfullname ? a.pcpname.localeCompare(b.memberfullname) : null),
      render: (text, record) => record?.memberfullname,
    },
    {
      title: 'Rendering Provider',
      dataIndex: 'pcpname',
      key: 'pcpname',
      sorter: (a, b) => (a.pcpname ? a.pcpname.localeCompare(b.pcpname) : null),
      render: (text, record) => record?.pcpname,
    },
    {
      title: 'Status Name',
      dataIndex: 'statusname',
      key: 'statusname',
      sorter: (a, b) => (a.statusname ? a.statusname.localeCompare(b.statusname) : null),
      render: (text, record) => record?.statusname,
    },
    {
      title: 'Rendering Group',
      dataIndex: 'outreachname',
      key: 'outreachname',
      sorter: (a, b) => (a.outreachname ? a.outreachname.localeCompare(b.outreachname) : null),
      render: (text, record) => record?.outreachname,
    },
    {
      title: 'Visit Type',
      dataIndex: 'typename',
      key: 'typename',
      sorter: (a, b) => (a.typename ? a.typename.localeCompare(b.typename) : null),
      render: (text, record) => record?.typename,
    },
    {
      title: 'Assigned User',
      dataIndex: 'assigneduser',
      key: 'assigneduser',
      sorter: (a, b) => (a.assigneduser ? a.assigneduser.localeCompare(b.assigneduser) : null),
      render: (text, record) => record?.assigneduser,
    },
    {
      title: 'Appointment Date',
      dataIndex: 'appointmentdate',
      key: 'appointmentdate',
      sorter: (a, b) =>
        moment(a.appointmentdate)
          .format('MM/DD/YYYY')
          .localeCompare(moment(b.appointmentdate).format('MM/DD/YYYY')),
      render: (text, record) =>
        record.appointmentdate
          ? moment(record.appointmentdate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '',
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifieddate',
      key: 'modifieddate',
      sorter: (a, b) =>
        moment(a.modifieddate)
          .format('MM/DD/YYYY')
          .localeCompare(moment(b.modifieddate).format('MM/DD/YYYY')),
      render: (text, record) =>
        record.modifieddate ? moment(record.modifieddate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
    },
  ]
}
