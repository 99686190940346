import moment from 'moment'

export default () => {
  return [
    {
      title: 'Provider',
      dataIndex: 'providergroupname',
      key: 'providergroupname',
      width: 200,
    },
    {
      title: 'Topic',
      dataIndex: 'topic_id',
      key: 'topic_id',
      width: 200,
    },
    {
      title: 'HCC',
      dataIndex: 'hcc',
      key: 'hcc',
      width: 200,
    },
    {
      title: 'Education Details',
      dataIndex: 'details',
      key: 'details',
      width: 200,
    },
    {
      title: 'Member Name',
      dataIndex: 'member_name',
      key: 'member_name',
      width: 200,
    },
    {
      title: 'DOS',
      dataIndex: 'latestdos',
      key: 'latestdos',
      width: 200,
      render: (text, record) =>
        record.latestdos ? moment(record.latestdos).format('YYYY-MM-DD') : '',
    },
    {
      title: 'Coder',
      dataIndex: 'educator',
      key: 'educator',
      width: 200,
    },
  ]
}
