/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Spin, Table, Row, Col, Typography, Alert } from 'antd'

const { Title } = Typography

const View = ({ chartid }) => (
  <ViewComponent chartid={chartid}>
    {({ state, columns, listData }) => (
      <Spin spinning={state.memberchartauditlogs.status === 1}>
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={12} style={{ marginBottom: '0px' }}>
                <Title level={2}>Audit Log</Title>
              </Col>

              <Col span={24}>
                {state.memberchartauditlogs.status === 3 ? (
                  <Alert
                    message={
                      state.memberchartauditlogs.payload.details || 'Oops, something went wrong...'
                    }
                    type="error"
                  />
                ) : (
                  <Table
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={listData}
                    pagination={false}
                    scroll={{
                      x: 500,
                      y: 300,
                    }}
                    loading={state.memberchartauditlogs.status === 1}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View

View.propTypes = {
  chartid: PropTypes.number,
}
