import { thunk, action } from 'easy-peasy'
import { listPcpsApi, listMedicalrecordsApi, listChartsApi } from '../../api/chart'

export default {
  // List Charts
  charts: { status: 0, payload: null },
  setCharts: action((state, payload = null) => {
    state.charts = payload
  }),
  getCharts: thunk(async (actions, params = {}) => {
    actions.setCharts({ status: 1, payload: null })

    listChartsApi(params)
      .then(response => actions.setCharts({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCharts({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),

  // List PCP
  pcps: { status: 0, payload: null },
  setPCPs: action((state, payload = null) => {
    state.pcps = payload
  }),
  getPCPs: thunk(async (actions, params = {}) => {
    actions.setPCPs({ status: 1, payload: null })

    listPcpsApi(params)
      .then(response => actions.setPCPs({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPCPs({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),

  // List Medical Records
  medicalrecords: { status: 0, payload: null },
  setMedicalrecords: action((state, payload = null) => {
    state.medicalrecords = payload
  }),
  getMedicalrecords: thunk(async (actions, params = {}) => {
    actions.setMedicalrecords({ status: 1, payload: null })

    listMedicalrecordsApi(params)
      .then(response => actions.setMedicalrecords({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMedicalrecords({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),
}
