import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Table, Typography, Row, Col, Select, Input, Divider, Button, Alert } from 'antd'

const { Option } = Select

const { Title } = Typography

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      listData,
      pagination,
      setT,
      onSearch,
      onTableChange,
      onResultFilter,
      handleGroupChange,
      handleNameChange,
      t,
      groups,
      names,
    }) => (
      <>
        <AppPageMetadata title="Provider Groups" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={2}>Group Dashboard</Title>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Title level={5}>Rendering Group</Title>
                <Select
                  showSearch
                  defaultValue={(queries && queries?.pcpgrptaxid) || null}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: '100%' }}
                  placeholder="Select Rendering Group"
                  onChange={handleGroupChange}
                >
                  {groups &&
                    groups.map((g, index) => (
                      <Option key={index} value={g.pcpgrptaxid}>
                        {g.pcpgroupname}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={6}>
                <Title level={5}>Rendering Provider</Title>
                {!names && <Select style={{ width: '100%' }} disabled />}
                {names && (
                  <Select
                    showSearch
                    defaultValue={
                      (queries &&
                        names.filter(x => x.pcp_npi === queries?.pcp_npi).length > 0 &&
                        queries?.pcp_npi) ||
                      null
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All PCP"
                    onChange={handleNameChange}
                    allowClear
                  >
                    {names.map((n, index) => (
                      <Option key={index} value={n.pcp_npi}>
                        {n.pcpfullname}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col span={4}>
                <Title level={5}>&nbsp;</Title>
                <Button type="primary" onClick={() => onSearch()}>
                  Search
                </Button>
              </Col>
            </Row>
            <Divider />
            {queries.pcp_npi || queries.pcpgrptaxid ? (
              <>
                <Row>
                  <Col span={12}>
                    {state.memberlists.status === 2 && (
                      <Title level={4}>{state.memberlists.payload.count} item(s) found</Title>
                    )}
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Input
                      style={{ maxWidth: 300 }}
                      size="large"
                      placeholder="Search From Results"
                      onChange={e => {
                        if (t) clearTimeout(t)

                        setT(
                          setTimeout(() => {
                            onResultFilter(e.target.value)
                          }, 500),
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {state.memberlists.status === 3 ? (
                      <Alert
                        message={
                          state.memberlists.payload.details || 'Oops, something went wrong...'
                        }
                        type="error"
                      />
                    ) : (
                      <Table
                        columns={columns}
                        rowKey={record => record.internalmemberid}
                        dataSource={listData}
                        pagination={pagination}
                        loading={state.memberlists.status === 1}
                        onChange={onTableChange}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </AppRowContainer>
      </>
    )}
  </ViewComponent>
)

export default View
