import { thunk, action } from 'easy-peasy'
import { listPcprecordsApi, getPcprecordsApi } from '../../api/pcprecords'

export default {
  // List Pcprecords
  pcprecords: { status: 0, payload: null },
  setPcprecords: action((state, payload = null) => {
    state.pcprecords = payload
  }),
  getPcprecords: thunk(async (actions, params = {}) => {
    actions.setPcprecords({ status: 1, payload: null })

    listPcprecordsApi(params)
      .then(response => actions.setPcprecords({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcprecords({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve pcprecord
  pcprecord: { status: 0, payload: null },
  setPcprecord: action((state, payload = null) => {
    state.pcprecord = payload
  }),
  getPcprecord: thunk(async (actions, id) => {
    actions.setPcprecord({ status: 1, payload: null })

    getPcprecordsApi(id)
      .then(response => actions.setPcprecord({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcprecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
