const commonValidations = {
  icd10code: [{ required: true, message: 'ICD10 is required.' }],
  codestatusid: [{ required: true, message: 'Status is required.' }],
  codenewid: [{ required: true, message: 'New is required.' }],
  querystatusid: [{ required: true, message: 'Query is required.' }],
  failureid: [{ required: true, message: 'Failure Reason is required.' }],

  // failurecomments: [{ required: true, message: 'Comments is required.' }],
}

export const _rules = name => {
  return commonValidations[name] || []
}
