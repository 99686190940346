import React from 'react'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'
import { Spin, Table, Typography, Row, Col, Alert, Button, Modal } from 'antd'

const { Title } = Typography

const View = props => (
  <ViewComponent {...props}>
    {({
      state,
      columns,
      listData,
      clearFilters,
      handleChange,
      hccColumns,
      hccData,
      setHccData,
      hccPagination,
      viewHCC,
      setViewHCC,
    }) => (
      <Spin spinning={state.hccgaps.status === 1}>
        <AppCard>
          <AppRowContainer>
            <Modal
              title=""
              width={'90%'}
              visible={viewHCC}
              footer={null}
              maskClosable={true}
              onCancel={() => setViewHCC(false)}
              afterClose={() => setHccData(null)}
            >
              <Row gutter={16} style={{ paddingTop: 25 }}>
                <Col span={24}>
                  <Table
                    scroll={{ x: 1500, y: 480 }}
                    columns={hccColumns}
                    rowKey={record => record.key}
                    dataSource={hccData}
                    pagination={hccPagination}
                    loading={state.memberhcccurrents.status === 1}
                    bordered
                  />
                </Col>
              </Row>
            </Modal>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Title level={2}>HCC History</Title>
                </Col>
                <Col span={20}>
                  <Button onClick={clearFilters}>Clear filters</Button>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {state.hccgaps.status === 3 ? (
                    <Alert
                      message={state.hccgaps.payload || 'Oops, something went wrong...'}
                      type="error"
                    />
                  ) : (
                    columns && (
                      <Table
                        columns={columns}
                        rowKey={record => record.key}
                        dataSource={listData}
                        pagination={false}
                        scroll={{
                          x: 500,
                          y: 500,
                        }}
                        onChange={handleChange}
                        loading={state.hccgaps.status === 1}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Col>
          </AppRowContainer>
        </AppCard>
      </Spin>
    )}
  </ViewComponent>
)

export default View
