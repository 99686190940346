import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Typography, Table, Row, Col, Modal, Divider } from 'antd'

import ProviderEducationFilters from './ProviderEducationFilters'

import columnsModal from './columnsModal'

const { Title } = Typography

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      listData,
      pagination,
      setT,
      setSelectedProviderEducation,
      selectedProviderEducation,
      onResultFilter,
      handleTopicChange,
      handleUserChange,
      handleRegionChange,
      handleProviderChange,
      handleGroupSearch,
      handleGroupChange,
      t,
      users,
      names,

      // groups,
      educationtopics,
      allGroups,
      actions,
    }) => (
      <>
        <AppPageMetadata title="Rendering Groups" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={2}>Provider Education Dashboard</Title>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <ProviderEducationFilters
                  queries={queries}
                  users={users}
                  names={names}
                  groups={state.pcpactivegroups}
                  educationtopics={educationtopics}
                  handleTopicChange={handleTopicChange}
                  handleUserChange={handleUserChange}
                  handleRegionChange={handleRegionChange}
                  handleProviderChange={handleProviderChange}
                  handleGroupSearch={handleGroupSearch}
                  handleGroupChange={handleGroupChange}
                />
              </Col>
            </Row>
            <Divider />
            <Title level={3}>Education Filtering</Title>
            <Table
              columns={columns}
              rowKey={record => record.id}
              loading={state.summaryprovidereducations.status === 1}
              dataSource={listData}
            />
          </Col>
        </AppRowContainer>
        {state.providereducations.status === 2 && selectedProviderEducation && (
          <Modal
            title="Education Details"
            visible={!!selectedProviderEducation}
            width="100%"
            onOk={() => setSelectedProviderEducation(null)}
            onCancel={() => setSelectedProviderEducation(null)}
            okButtonProps={{ style: { display: 'none' } }}
            cancelText="Close"
          >
            <Table
              columns={columnsModal()}
              rowKey={record => record.id}
              loading={state.providereducations.status === 1}
              dataSource={state.providereducations.payload.results}
            />
          </Modal>
        )}
      </>
    )}
  </ViewComponent>
)

export default View
