/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewContainer from './container'

import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Typography, Row, Col, Form, Input, Select, Divider, Button } from 'antd'
import { SaveFilled } from '@ant-design/icons'

import { _rules } from './validations'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

const View = ({ chartid }) => {
  return (
    <ViewContainer chartid={chartid}>
      {({ state, initialValues, onFinish, onFinishFailed, onFieldsChange, form, statuses }) => (
        <>
          <AppRowContainer>
            <Col span={24}>
              <Spin spinning={state.createdMemberchartactivityrecord.status === 1}>
                <AppCard>
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onFieldsChange={onFieldsChange}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={2}>Chart Activity Form</Title>
                        <Divider />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Status: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="activitystatusid" rules={_rules('activitystatusid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select Status"
                          >
                            {statuses &&
                              statuses.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Notes: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="activitynotes"
                          className="form-field"
                          rules={_rules('activitynotes')}
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </AppCard>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View

View.propTypes = {
  chartid: PropTypes.number,
}
