import moment from 'moment'

export default onClickTableModal => {
  return [
    {
      title: 'Provider',
      dataIndex: 'providergroupname',
      key: 'providergroupname',
      width: 200,
    },
    {
      title: 'Topic',
      dataIndex: 'topic_id',
      key: 'topic_id',
      width: 200,
    },
    {
      title: 'HCC',
      dataIndex: 'hcc',
      key: 'hcc',
      width: 200,
      render: (text, record) => (
        <a href="#" onClick={e => onClickTableModal(e, record)}>
          {record.hcc}
        </a>
      ),
    },
    {
      title: 'Times reported',
      dataIndex: 'reported',
      key: 'reported',
      width: 200,
    },
    {
      title: 'Latest DOS Reported',
      dataIndex: 'latestdos',
      key: 'latestdos',
      width: 200,
      render: (text, record) => moment(record.latestdos).format('YYYY-MM-DD'),
    },
    {
      title: 'Education Provided',
      dataIndex: 'educationprovided',
      key: 'educationprovided',
      width: 200,
    },
    {
      title: 'Educator',
      dataIndex: 'educator',
      key: 'educator',
      width: 200,
    },
    {
      title: 'Education Provided Date',
      dataIndex: 'educationprovideddate',
      key: 'educationprovideddate',
      width: 200,
    },
  ]
}
