import React from 'react'
import { Space, Popconfirm, Button } from 'antd'
import { Link } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

export default (actions, user, basePath) => {
  return [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          {has(user, p.SUPER_USER_RW) ? (
            <>
              <Link to={`${basePath}/${record.id}/`}>
                <Button type="danger" ghost icon={<EditOutlined />} />
              </Link>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => actions.deleteSuperUser(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type="danger" ghost icon={<EditOutlined />} disabled />
              <Button type="primary" icon={<DeleteOutlined />} disabled />
            </>
          )}
        </Space>
      ),
    },
  ]
}
