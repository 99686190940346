import React from 'react'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Spin, Select, Table, Typography, Row, Col, Alert } from 'antd'
import {
  hccCurrentStatuses,
  HCC_STATUS_OPEN,
  HCC_STATUS_CLOSED,
} from '../../../../../../shared/constants/AppConst'

import './style.css'

const { Title } = Typography
const { Option } = Select

const View = props => (
  <ViewComponent {...props}>
    {({
      state,
      selectedStatuses,
      handleStatusChange,
      columns,
      listData,
      pagination,
      onTableChange,
    }) => (
      <Spin spinning={state.memberhcccurrents.status === 1}>
        <AppRowContainer>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={20}>
                <Col span={6}>
                  <Title level={5}>Status</Title>
                  <Select
                    mode="multiple"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Select status"
                    defaultValue={[HCC_STATUS_OPEN, HCC_STATUS_CLOSED]}
                    onChange={handleStatusChange}
                    optionLabelProp="label"
                  >
                    {hccCurrentStatuses &&
                      hccCurrentStatuses.map((status, index) => (
                        <Option key={index} value={status.value} label={status.label}>
                          <div className="demo-option-label-item">{status.label}</div>
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                {state.memberhcccurrents.status === 3 ? (
                  <Alert
                    message={
                      state.memberhcccurrents.payload.details || 'Oops, something went wrong...'
                    }
                    type="error"
                  />
                ) : (
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    rowKey={record => record.key}
                    dataSource={listData}
                    pagination={pagination}
                    loading={state.memberhcccurrents.status === 1}
                    onChange={onTableChange}
                    bordered
                  />
                )}
              </Col>
            </Row>
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View
