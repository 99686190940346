import { thunk, action } from 'easy-peasy'
import {
  listNonSuperUsersApi,
  getNonSuperUserApi,
  createNonSuperUserApi,
  updateNonSuperUserApi,
  deleteNonSuperUserApi,
} from '../../api/nonsuperuser'

export default {
  // List NonSuperUsers
  nonSuperUsers: { status: 0, payload: null },
  setNonSuperUsers: action((state, payload = null) => {
    state.nonSuperUsers = payload
  }),
  getNonSuperUsers: thunk(async (actions, params = {}) => {
    actions.setNonSuperUsers({ status: 1, payload: null })

    listNonSuperUsersApi(params)
      .then(response => actions.setNonSuperUsers({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setNonSuperUsers({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create NonSuperUser
  createdNonSuperUser: { status: 0, payload: null },
  setCreatedNonSuperUser: action((state, payload = null) => {
    state.createdNonSuperUser = payload
  }),
  createNonSuperUser: thunk(async (actions, data = {}) => {
    actions.setCreatedNonSuperUser({ status: 1, payload: null })

    createNonSuperUserApi(data)
      .then(response => actions.setCreatedNonSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedNonSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve NonSuperUser
  nonSuperUser: { status: 0, payload: null },
  setNonSuperUser: action((state, payload = null) => {
    state.nonSuperUser = payload
  }),
  getNonSuperUser: thunk(async (actions, id) => {
    actions.setNonSuperUser({ status: 1, payload: null })

    getNonSuperUserApi(id)
      .then(response => actions.setNonSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setNonSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete NonSuperUser
  deletedNonSuperUser: { status: 0, payload: null },
  setDeletedNonSuperUser: action((state, payload = null) => {
    state.deletedNonSuperUser = payload
  }),
  deleteNonSuperUser: thunk(async (actions, id) => {
    actions.setDeletedNonSuperUser({ status: 1, payload: null })

    deleteNonSuperUserApi(id)
      .then(response => actions.setDeletedNonSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedNonSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update NonSuperUser
  updatedNonSuperUser: { status: 0, payload: null },
  setUpdatedNonSuperUser: action((state, payload = null) => {
    state.updatedNonSuperUser = payload
  }),
  updateNonSuperUser: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedNonSuperUser({ status: 1, payload: null })

    updateNonSuperUserApi(id, data)
      .then(response => actions.setUpdatedNonSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedNonSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
