import { thunk, action } from 'easy-peasy'
import { listPcpnamesApi, getPcpnamesApi } from '../../api/pcpnames'

export default {
  // List Pcpname
  pcpnames: { status: 0, payload: null },
  setPcpnames: action((state, payload = null) => {
    state.pcpnames = payload
  }),
  getPcpnames: thunk(async (actions, params = {}) => {
    actions.setPcpnames({ status: 1, payload: null })

    listPcpnamesApi(params)
      .then(response => actions.setPcpnames({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpnames({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve pcpname
  pcpname: { status: 0, payload: null },
  setPcpname: action((state, payload = null) => {
    state.pcpname = payload
  }),
  getPcpname: thunk(async (actions, id) => {
    actions.setPcpname({ status: 1, payload: null })

    getPcpnamesApi(id)
      .then(response => actions.setPcpname({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpname({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
