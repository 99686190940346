import { useState } from 'react'
import { Row, Col, Typography, Divider } from 'antd'
import ChartHistoryList from './ChartHistoryList'
import DocumentList from './DocumentList'
import ICD10List from './ICD10List'

// import CP10CodeList from './CPTCodeList'
import CPTCodes from './CPTCodes'
import ChartActivityList from './ChartActivityList'
import ChartAuditLog from './ChartAuditLog'
import ChartStatus from './ChartStatus'

const { Title } = Typography

export default function Chart() {
  const [chartId, setCharId] = useState(null)

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <ChartHistoryList setCharId={setCharId} />
        </Col>
      </Row>
      {chartId && (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Title level={1}>Chart</Title>
              <Divider />
            </Col>
            <Col span={9}>{chartId && <ChartStatus chartid={chartId} />}</Col>
            <Col span={15}>
              <DocumentList chartid={chartId} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <CPTCodes chartId={chartId} />
              {/* <CP10CodeList chartid={chartId} /> */}
            </Col>
            <Col span={24}>
              <ICD10List chartid={chartId} />
            </Col>
            <Col span={24}>
              <ChartActivityList chartid={chartId} />
            </Col>

            <Col span={24}>
              <ChartAuditLog chartid={chartId} />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
