import React from 'react'
import ViewContainer from './container'
import { _rules } from './validations'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Select, Typography, Row, Col, Form, Input, Divider, Button } from 'antd'
import { SaveFilled, ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Title } = Typography

const View = () => {
  return (
    <ViewContainer>
      {({
        state,
        initialValues,
        onFinish,
        onFinishFailed,
        onRoleChange,
        onFieldsChange,
        permissions,
        loading,
        form,
        id,
        basePath,
      }) => (
        <>
          <AppPageMetadata title="Super Users" />
          <AppRowContainer>
            <Col span={24}>
              <Spin spinning={loading}>
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={onFieldsChange}
                >
                  <Row>
                    <Col span={12}>
                      <Title level={2}>
                        {id === 'new' ? 'Create User' : `Update User ID #${id}`}
                      </Title>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={12}>
                      <AppCard>
                        <Title level={3}>Basic Information</Title>

                        <Form.Item
                          name="first_name"
                          className="form-field"
                          rules={_rules(id, 'first_name')}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>

                        <Form.Item
                          name="last_name"
                          className="form-field"
                          rules={_rules(id, 'last_name')}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>

                        <Form.Item name="email" className="form-field" rules={_rules(id, 'email')}>
                          <Input placeholder="Email" />
                        </Form.Item>
                      </AppCard>
                    </Col>

                    <Col span={12}>
                      <AppCard>
                        <Title level={3}>Account Credentials</Title>

                        <Form.Item
                          name="username"
                          className="form-field"
                          rules={_rules(id, 'username')}
                        >
                          <Input placeholder="Username" />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          className="form-field"
                          rules={_rules(id, 'password')}
                        >
                          <Input.Password placeholder="Password" />
                        </Form.Item>
                      </AppCard>
                    </Col>
                    <Divider />
                    <Col span={12}>
                      <AppCard>
                        <Title level={3}>Roles</Title>
                        <Form.Item name="roles" className="form-field" rules={_rules(id, 'roles')}>
                          <Select
                            name="roles"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().includes(input.toLowerCase())
                            }
                            allowClear
                            required
                            onChange={onRoleChange}
                            mode="multiple"
                          >
                            {state.roles.status === 2 &&
                              state.roles.payload.results.map((d, index) => (
                                <Select.Option key={index} value={d.id}>
                                  {d.name}
                                </Select.Option>
                              ))}
                            {state.roles.status === 3 &&
                              state.roles.payload.results.length === 0 && (
                                <Select.Option disabled>No Result</Select.Option>
                              )}
                          </Select>
                        </Form.Item>
                        {permissions.length > 0 && (
                          <Row>
                            <Col span={24}>
                              <Title level={4}>Permissions</Title>
                              <div style={{ paddingLeft: 10 }}>
                                <ul>
                                  {permissions.map((p, index) => (
                                    <li key={index}>{p.name}</li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </AppCard>
                    </Col>
                    {/* <Col span={12}>
                      <AppCard>
                        <Title level={3}>Roles</Title>
                        <Form.Item name="role" className="form-field" rules={_rules(id, 'role')}>
                          <Select
                            name="role"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().includes(input.toLowerCase())
                            }
                            allowClear
                            required
                            onChange={onRoleChange}
                          >
                            {state.roles.status === 2 &&
                              state.roles.payload.results.map((d, index) => (
                                <Select.Option key={index} value={d.id}>
                                  {d.name}
                                </Select.Option>
                              ))}
                            {state.roles.status === 3 &&
                              state.roles.payload.results.length === 0 && (
                                <Select.Option disabled>No Result</Select.Option>
                              )}
                          </Select>
                        </Form.Item>
                        {permissions.length > 0 && (
                          <Row>
                            <Col span={24}>
                              <Title level={4}>Permissions</Title>
                              <div style={{ paddingLeft: 10 }}>
                                <ul>
                                  {permissions.map((p, index) => (
                                    <li key={index}>{p.name}</li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </AppCard>
                    </Col> */}
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <Link to={basePath}>
                        <Button type="primary" icon={<ArrowLeftOutlined />} ghost>
                          Back to list
                        </Button>
                      </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View
