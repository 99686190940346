import moment from 'moment'
import { authRole } from '../../shared/constants/AppEnums'

export const createRoutes = routeConfigs => {
  let allRoutes = []

  routeConfigs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)]
  })

  return allRoutes
}

export const setRoutes = config => {
  let routes = [...config.routes]

  if (config.auth) {
    routes = routes.map(route => {
      const auth = route.auth ? [...config.auth, ...route.auth] : [...config.auth]
      return { ...route, auth }
    })
  }

  return [...routes]
}

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet

  switch (breakpoint) {
    case 'xs':
      return valueSet.xs
    case 'sm':
      return valueSet.sm || valueSet.xs
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
    default:
      return valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
  }
}

export const getFileSize = bytes => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters)
  return products.filter(product => {
    return filterKeys.every(key => {
      if (!filters[key].length) return true

      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some(keyEle => filters[key].includes(keyEle))
      }

      // eslint-disable-next-line no-console
      console.log('key', key, filters[key], product[key])

      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase())
      }

      return filters[key].includes(product[key])
    })
  })
}

export const getCustomDateTime = (value = 0, unit = 'days', format = 'YYYY-MM-DD') => {
  if (value === 0) {
    return moment().format(format)
  } else {
    return moment().add(value, unit).format(format)
  }
}

export const timeFromNow = date => {
  const timestamp = moment(date).format('X')
  const newDate = moment.unix(timestamp)
  return moment(newDate).fromNow()
}

export const checkPermission = (routeAuth, userRole) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some(r => userRole.indexOf(r) >= 0)
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0
  }

  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some(r => userRole.indexOf(r) >= 0)
  }

  return routeAuth.indexOf(userRole) >= 0
}

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`
}

export const getUserFromAuth0 = user => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    }
  return user
}

export const getUserFromFirebase = user => {
  // eslint-disable-next-line no-console
  console.log('user: ', user)
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      role: authRole.user,
    }
  return user
}

export const getUserFromAWS = user => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    }
  return user
}

export const getUserFromJwtAuth = user => {
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      role: authRole.user,
    }
  return user
}

export const getUserFromOAuth2 = user => {
  if (user)
    return {
      id: 1,
      uid: user.id,
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      photoURL: user?.photo,
      role: user?.role,
      permissions: user?.permissions,
      is_superuser: user?.is_superuser,
    }
  return user
}
