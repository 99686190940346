import { thunk, action } from 'easy-peasy'
import {
  listMemberchartsicdcodesApi,
  updateMembercharticdcodesApi,
  createMembercharticdcodesApi,
  getMemberchartsicdcodeApi,
  deleteMembercharticdcodesApi,
} from '../../api/memberchartsicdcode'

export default {
  // List Memberchartsicdcodes
  memberchartsicdcodes: { status: 0, payload: null },
  setMemberchartsicdcodes: action((state, payload = null) => {
    state.memberchartsicdcodes = payload
  }),
  getMemberchartsicdcodes: thunk(async (actions, params = {}) => {
    actions.setMemberchartsicdcodes({ status: 1, payload: null })

    listMemberchartsicdcodesApi(params)
      .then(response => actions.setMemberchartsicdcodes({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartsicdcodes({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberchartsicdcode
  memberchartsicdcode: { status: 0, payload: null },
  setMemberchartsicdcode: action((state, payload = null) => {
    state.memberchartsicdcode = payload
  }),
  getMemberchartsicdcode: thunk(async (actions, id) => {
    actions.setMemberchartsicdcode({ status: 1, payload: null })

    getMemberchartsicdcodeApi(id)
      .then(response => actions.setMemberchartsicdcode({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartsicdcode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Memberchartsicdcode
  deletedMembercharticdcode: { status: 0, payload: null },
  setDeletedMembercharticdcode: action((state, payload = null) => {
    state.deletedMembercharticdcode = payload
  }),
  deleteMembercharticdcode: thunk(async (actions, id) => {
    actions.setDeletedMembercharticdcode({ status: 1, payload: null })

    deleteMembercharticdcodesApi(id)
      .then(response => actions.setDeletedMembercharticdcode({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedMembercharticdcode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Memberchartsicdcode
  updatedMembercharticdcode: { status: 0, payload: null },
  setUpdatedMembercharticdcode: action((state, payload = null) => {
    state.updatedMembercharticdcode = payload
  }),
  updateMembercharticdcode: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedMembercharticdcode({ status: 1, payload: null })

    updateMembercharticdcodesApi(id, data)
      .then(response => actions.setUpdatedMembercharticdcode({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedMembercharticdcode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Memberchartsicdcode
  createdMembercharticdcode: { status: 0, payload: null },
  setMembercharticdcode: action((state, payload = null) => {
    state.createdMembercharticdcode = payload
  }),
  createMembercharticdcode: thunk(async (actions, data = {}) => {
    actions.setMembercharticdcode({ status: 1, payload: null })

    createMembercharticdcodesApi(data)
      .then(response => actions.setMembercharticdcode({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMembercharticdcode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
