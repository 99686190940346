import { Space, Popconfirm, Button } from 'antd'
import moment from 'moment'

export default (setCharId, actions) => {
  return [
    {
      title: 'Type',
      dataIndex: 'typename',
      key: 'typename',
    },
    {
      title: 'Status',
      dataIndex: 'statusname',
      key: 'statusname',
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifieddate',
      key: 'modifieddate',
      render: (text, record) =>
        `${moment(record?.modifieddate, 'YYYY-MM-DD').format('MM/DD/YYYY')}`,
    },
    {
      title: 'Created Date',
      dataIndex: 'createddate',
      key: 'createddate',
      render: (text, record) => `${moment(record?.createddate, 'YYYY-MM-DD').format('MM/DD/YYYY')}`,
    },
    {
      title: 'Appointment Date',
      dataIndex: 'appointmentdate',
      key: 'appointmentdate',
      render: (text, record) =>
        `${moment(record?.appointmentdate, 'YYYY-MM-DD').format('MM/DD/YYYY')}`,
    },
    {
      title: 'Rendering Group',
      dataIndex: 'providergroupname',
      key: 'providergroupname',
    },
    {
      title: 'Rendering Provider',
      dataIndex: 'pcpname',
      key: 'pcpname',
    },
    {
      title: 'Action',
      width: 200,
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          <Button
            size="small"
            type="primary"
            title="View"
            onClick={() => setCharId(record.chartid)}
          >
            View
          </Button>
          {/* <Button
            size="small"
            type="primary"
            title="Edit"
            onClick={() => editChart(record.chartid)}
            ghost
          >
            Edit
          </Button> */}
          <Popconfirm
            title="Are you sure to delete this record?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => actions.deleteMemberchart(record.chartid)}
          >
            <Button size="small" type="danger" title="Delete">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]
}
