import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Spin, Table, Typography, Row, Col, Input, Divider, Button, Alert } from 'antd'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

const { Title } = Typography
const { Search } = Input

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      listData,
      pagination,
      onSearch,
      onTableChange,
      basePath,
      auth,
    }) => (
      <Spin spinning={state.permissions.status === 1}>
        <AppPageMetadata title="Permissions" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Title level={2}>Permissions</Title>
                <div style={{ color: 'red' }}>
                  NOTE: Updating code or deleting permission might affect the module that is linked
                  to it.
                </div>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Search
                  placeholder="Search Permission"
                  onSearch={value => onSearch(value)}
                  defaultValue={queries.search || ''}
                  type="primary-outline"
                  loading={state.permissions.status === 1}
                  className="standard-header-search"
                />
                {has(auth.user, p.PERMISSIONS_RW) && (
                  <Link to={`${basePath}/new`}>
                    <Button type="primary" icon={<PlusCircleOutlined />}>
                      Add Permission
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                {state.permissions.status === 3 ? (
                  <Alert
                    message={state.permissions.payload.details || 'Oops, something went wrong...'}
                    type="error"
                  />
                ) : (
                  <Table
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={listData}
                    pagination={pagination}
                    loading={state.permissions.status === 1}
                    onChange={onTableChange}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View
