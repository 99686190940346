import { thunk, action } from 'easy-peasy'
import {
  updateMemberchartfileApi,
  getMemberchartfileApi,
  listMemberchartsfilesApi,
  deleteMemberchartfileApi,
  createMemberchartfileApi,
} from '../../api/memberchartsfile'

export default {
  // Retrieve Memberchartfiles
  memberchartfile: { status: 0, payload: null },
  setMemberchartfile: action((state, payload = null) => {
    state.memberchartfile = payload
  }),
  getMemberchartfile: thunk(async (actions, id) => {
    actions.setMemberchartfile({ status: 1, payload: null })

    getMemberchartfileApi(id)
      .then(response => actions.setMemberchartfile({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartfile({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Memberchartfile
  updatedmemberchartfile: { status: 0, payload: null },
  setUpdatedMemberchartfile: action((state, payload = null) => {
    state.updatedmemberchartfile = payload
  }),
  updateMemberchartfile: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedMemberchartfile({ status: 1, payload: null })

    updateMemberchartfileApi(id, data)
      .then(response => actions.setUpdatedMemberchartfile({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedMemberchartfile({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // List Memberchartsfiles
  memberchartsfiles: { status: 0, payload: null },
  setMemberchartsfiles: action((state, payload = null) => {
    state.memberchartsfiles = payload
  }),
  getMemberchartsfiles: thunk(async (actions, params = {}) => {
    actions.setMemberchartsfiles({ status: 1, payload: null })

    listMemberchartsfilesApi(params)
      .then(response => actions.setMemberchartsfiles({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartsfiles({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update deletedMemberchartfile
  deletedmemberchartfile: { status: 0, payload: null },
  setDeletedMembercharfile: action((state, payload = null) => {
    state.deletedmemberchartfile = payload
  }),
  deleteMemberchartfile: thunk(async (actions, id) => {
    actions.setDeletedMembercharfile({ status: 1, payload: null })

    deleteMemberchartfileApi(id)
      .then(response => actions.setDeletedMembercharfile({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedMembercharfile({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Memberchartsfile
  createdMemberchartsfile: { status: 0, payload: null },
  setCreatedMemberchartsfile: action((state, payload = null) => {
    state.createdMemberchartsfile = payload
  }),
  createMemberchartsfile: thunk(async (actions, data = {}) => {
    actions.setCreatedMemberchartsfile({ status: 1, payload: null })

    createMemberchartfileApi(data)
      .then(response => actions.setCreatedMemberchartsfile({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedMemberchartsfile({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
