import React from 'react'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'
import { Spin, Modal, Table, Typography, Row, Col, Alert, Button } from 'antd'
import QualityGapDocuments from '../QualityGapDocuments'

const { Title } = Typography

const View = props => (
  <ViewComponent {...props}>
    {({
      state,
      columns,
      listData,
      clearFilters,
      handleChange,
      qualityGapId,
      viewDocument,
      setViewDocument,
    }) => (
      <Spin
        spinning={
          state.memberqualityrecords.status === 1 ||
          state.nonSuperUsers.status === 1 ||
          state.updatedMemberqualityrecord.status === 1
        }
      >
        <Modal
          title=""
          width={900}
          visible={viewDocument}
          footer={null}
          maskClosable={true}
          destroyOnClose={true}
          onCancel={() => setViewDocument(false)}
        >
          <Row gutter={16} style={{ paddingTop: 25 }}>
            <Col span={24}>
              <QualityGapDocuments qualityGapId={qualityGapId} />
            </Col>
          </Row>
        </Modal>
        <AppCard>
          <AppRowContainer>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Title level={2}>Quality Gap</Title>
                </Col>
                <Col span={20}>
                  <Button onClick={clearFilters}>Clear filters</Button>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {state.memberqualityrecords.status === 3 ? (
                    <Alert
                      message={
                        state.memberqualityrecords.payload.details ||
                        'Oops, something went wrong...'
                      }
                      type="error"
                    />
                  ) : (
                    columns && (
                      <Table
                        columns={columns}
                        rowKey={record => record.internalmemberid}
                        dataSource={listData}
                        pagination={false}
                        scroll={{
                          x: 500,
                          y: 500,
                        }}
                        onChange={handleChange}
                        loading={state.memberqualityrecords.status === 1}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Col>
          </AppRowContainer>
        </AppCard>
      </Spin>
    )}
  </ViewComponent>
)

export default View
