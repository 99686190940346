import React from 'react'
import ViewContainer from './container'
import { _rules } from './validations'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Typography, Row, Col, Form, Input, Divider, Button } from 'antd'
import { SaveFilled, ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Title } = Typography

const View = () => {
  return (
    <ViewContainer>
      {({
        initialValues,
        onFinish,
        onFinishFailed,
        onFieldsChange,
        loading,
        form,
        id,
        basePath,
      }) => (
        <>
          <AppPageMetadata title="Permission Groups" />
          <AppRowContainer>
            <Col span={24}>
              <Spin spinning={loading}>
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={onFieldsChange}
                >
                  <Row>
                    <Col span={12}>
                      <Title level={2}>
                        {id === 'new'
                          ? 'Create Permission Group'
                          : `Update Permission Group ID #${id}`}
                      </Title>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={12}>
                      <AppCard>
                        <Title level={3}>Basic Information</Title>

                        <Form.Item name="name" className="form-field" rules={_rules(id, 'name')}>
                          <Input placeholder="Name" />
                        </Form.Item>

                        <Form.Item name="code" className="form-field" rules={_rules(id, 'code')}>
                          <Input placeholder="Code" />
                        </Form.Item>
                      </AppCard>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <Link to={basePath}>
                        <Button type="primary" icon={<ArrowLeftOutlined />} ghost>
                          Back to list
                        </Button>
                      </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View
