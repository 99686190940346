import { thunk, action } from 'easy-peasy'
import { listHccgapsApi } from '../../api/hccgap'

export default {
  // List Hccgaps
  hccgaps: { status: 0, payload: null },
  setHccgaps: action((state, payload = null) => {
    state.hccgaps = payload
  }),
  getHccgaps: thunk(async (actions, params = {}) => {
    actions.setHccgaps({ status: 1, payload: null })

    listHccgapsApi(params)
      .then(response => actions.setHccgaps({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setHccgaps({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),
}
