import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listProvidereducationsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/membereducations/`, { params })

export const updateProvidereducationsApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/crm/membereducations/${id}/`, data)

export const createProvidereducationApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/crm/membereducations`, data)

export const deleteProvidereducationsApi = id =>
  Api.delete(`${API_URL}/api/v1/crm/membereducations/${id}/`)
