import zhMessages from '../locales/zh-Hans.json'
import zhCN from 'antd/lib/locale/zh_CN'

const ZhLan = {
  messages: {
    ...zhMessages,
  },
  antLocale: zhCN,
  locale: 'zh-Hans-CN',
}

export default ZhLan
