import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { sortBy } from 'lodash'

import moment from 'moment'

import tableColumns from './columns'

const Container = ({ children, chartid }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    memberchartsactivityrecords: s.memberchartsactivityrecords,
    deletedMemberchartactivityrecord: s.deletedMemberchartactivityrecord,
  }))
  const actions = useStoreActions(a => ({
    getMemberchartsactivityrecords: a.getMemberchartsactivityrecords,
    setMemberchartsactivityrecords: a.setMemberchartsactivityrecords,
    deleteMemberchartactivityrecord: a.deleteMemberchartactivityrecord,
    setDeletedMemberchartactivityrecord: a.setDeletedMemberchartactivityrecord,
  }))

  // Local states
  const [listData, setListData] = useState([])

  // Columns
  const [columns, setColumns] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState({})
  const [sortedInfo, setSortedInfo] = useState({})
  const [viewForm, setViewForm] = useState(false)

  const addChartActivity = () => {
    setViewForm(!viewForm)
  }

  const clearFilters = () => {
    setFilteredInfo({})
    setSortedInfo({})
  }

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  }

  // const handleFilters = payload => {
  //   const fields = ['activitystatusname']
  //   const filters = { activitystatusname: [] }

  //   payload.forEach(p => {
  //     fields.forEach(f => {
  //       if (filters[f].filter(x => x.value === p[f]).length === 0) {
  //         filters[f].push({ text: p[f], value: p[f] })
  //       }
  //     })
  //   })

  //   return filters
  // }

  useEffect(() => {
    chartid && actions.getMemberchartsactivityrecords({ chartid })
  }, [chartid])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberchartsactivityrecords

    if (status === 2) {
      const sortedData = sortBy(payload, function (o) {
        // eslint-disable-next-line new-cap
        return new moment(o.createddate)
      }).reverse()

      // Create choices of filters in column headers
      // const filters = handleFilters(sortedData)

      // Set created columns
      setColumns(tableColumns(actions))

      setListData(sortedData)

      actions.setMemberchartsactivityrecords({ status: 0, payload: null })
    } else if (status === 3) {
      setListData(null)
      actions.setMemberchartsactivityrecords({ status: 0, payload: null })
    }
  }, [state.memberchartsactivityrecords, filteredInfo, sortedInfo])

  useEffect(() => {
    const { status } = state.deletedMemberchartactivityrecord

    if (status === 2) {
      actions.getMemberchartsactivityrecords({ chartid })
      actions.setDeletedMemberchartactivityrecord({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setDeletedMemberchartactivityrecord({ status: 0, payload: null })
    }
  }, [state.deletedMemberchartactivityrecord])

  return children({
    state,
    columns,
    chartid,
    listData,
    clearFilters,
    handleChange,
    addChartActivity,
    setViewForm,
    viewForm,
  })
}

export default Container
