/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewContainer from './container'

import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Typography, Row, Col, Form, Select, Input, Divider, Button } from 'antd'
import { SaveFilled } from '@ant-design/icons'
import { _rules } from './validations'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

const View = ({ chartid, icdcodeid = null }) => {
  return (
    <ViewContainer chartid={chartid} icdcodeid={icdcodeid}>
      {({
        state,
        initialValues,
        onFinish,
        onFinishFailed,
        onFieldsChange,
        currentData,
        form,
        handleICD10Change,
        handleICD10Search,
        icd10s,
        statuses,
        queries,
        news,
        failures,
      }) => (
        <>
          <AppRowContainer>
            <Col span={24}>
              <Spin spinning={state.createdMembercharticdcode.status === 1}>
                <AppCard>
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onFieldsChange={onFieldsChange}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={2}>{icdcodeid ? 'Update Item' : 'Add Item'}</Title>
                        <Divider />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>ICD10: </Col>
                      <Col span={24}>
                        <Form.Item
                          name="icd10code"
                          className="form-field"
                          rules={_rules('icd10code')}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            onSearch={handleICD10Search}
                            onChange={handleICD10Change}
                            style={{ width: '100%' }}
                            placeholder=""
                          >
                            {icd10s &&
                              icd10s.map((g, index) => (
                                <Option key={index} value={g.icd10}>
                                  {g.icd10}
                                </Option>
                              ))}
                          </Select>
                          {/* <Select
                            showSearch
                            name="icd10code"
                            placeholder=""
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={handleICD10Search}
                            onChange={handleICD10Change}
                            notFoundContent={null}
                            allowClear
                          >
                            {state.icdhccrecords.status === 1 && (
                              <Option disabled>
                                <LoadingOutlined /> Searching...
                              </Option>
                            )}
                            {state.icdhccrecords.status === 2 &&
                              state.icdhccrecords.payload.results.map((d, index) => (
                                <Option key={index} value={d.icd10}>
                                  {d.icd10}
                                </Option>
                              ))}
                            {state.icdhccrecords.status === 2 &&
                              state.icdhccrecords.payload.results.length === 0 && (
                                <Option disabled>No Result</Option>
                              )}
                          </Select> */}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[32, 32]}>
                      <Col style={{ fontWeight: 'bold' }} span={5}>
                        ICD10 Descr:
                      </Col>
                      <Col span={19}>{currentData?.icd10_desc}</Col>
                      <Col style={{ fontWeight: 'bold' }} span={5}>
                        HCC:
                      </Col>
                      <Col span={19}>{currentData?.hcc}</Col>
                      <Col style={{ fontWeight: 'bold' }} span={5}>
                        HCC Description:
                      </Col>
                      <Col span={19}>{currentData?.hccdescription}</Col>
                    </Row>
                    <Row style={{ paddingTop: 20 }}>
                      <Col span={24}>
                        <Col span={24}>Status: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="codestatusid" rules={_rules('codestatusid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder=""
                          >
                            {statuses &&
                              statuses.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>New: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="codenewid" rules={_rules('codenewid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder=""
                          >
                            {news &&
                              news.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Coder Status: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="querystatusid" rules={_rules('querystatusid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder=""
                          >
                            {queries &&
                              queries.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Failure Reason: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="failureid" rules={_rules('failureid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder=""
                          >
                            {failures &&
                              failures.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>Comments:</Col>
                      <Col span={24}>
                        <Form.Item
                          name="failurecomments"
                          className="form-field"

                          // rules={_rules('failurecomments')}
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </AppCard>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View

View.propTypes = {
  chartid: PropTypes.any,
  icdcodeid: PropTypes.number,
}
