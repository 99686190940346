import { Row, Col } from 'antd'
import HCCGapList from './HCCGapList'

const HCCGap = props => (
  <Row gutter={16}>
    <Col span={24}>
      <HCCGapList {...props} />
    </Col>
  </Row>
)

export default HCCGap
