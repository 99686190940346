import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'
import { message, Form } from 'antd'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

const initialValues = {
  name: '',
  permissions: [],
}

const basePath = '/access-management/roles'

const Container = ({ children }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    createdRole: s.createdRole,
    updatedRole: s.updatedRole,
    role: s.role,
    permissions: s.permissions,
    permissionGroups: s.permissionGroups,
  }))

  const actions = useStoreActions(a => ({
    setRole: a.setRole,
    setCreatedRole: a.setCreatedRole,
    setUpdatedRole: a.setUpdatedRole,
    setPermissions: a.setPermissions,
    setPermissionGroups: a.setPermissionGroups,
    createRole: a.createRole,
    updateRole: a.updateRole,
    getRole: a.getRole,
    getPermissions: a.getPermissions,
    getPermissionGroups: a.getPermissionGroups,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)

  const navigate = useNavigate()
  const auth = useAuthUser()

  const onFinish = values => {
    if (id === 'new') {
      actions.createRole(values)
    } else {
      actions.updateRole({ id, ...values })
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  const onChangePermissions = permissions => {
    setFormValues(prev => ({ ...prev, permissions }))
  }

  useEffect(() => {
    if (!has(auth.user, [p.ROLES_RW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [])

  // On Load
  useEffect(() => {
    if (id !== 'new') {
      actions.getRole(id)
    }

    actions.getPermissionGroups({ no_pagination: 1 })

    // Reset retrieve state on unmount
    return () => {
      actions.setRole({ status: 0, payload: null })
      actions.setPermissionGroups({ status: 0, payload: null })
    }
  }, [])

  // Side effect of state.role
  useEffect(() => {
    const { status, payload } = state.role

    if (status === 2) {
      // Set initial values of form
      setFormValues({
        name: payload.name,
        permissions: (payload.permissions || []).map(p => p.id),
      })
    }
  }, [state.role])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  // Side effect of createdRole
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdRole

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedRole({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully added new record')
      navigate(basePath)
      actions.setCreatedRole({ status: 0, payload: null })
    }
  }, [state.createdRole])

  // Side effect of updatedRole
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedRole

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedRole({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully updated the record')
      navigate(basePath)
      actions.setUpdatedRole({ status: 0, payload: null })
    }
  }, [state.updatedRole])

  useEffect(() => {
    setLoading(
      state.role.status === 1 || state.createdRole.status === 1 || state.updatedRole.status === 1,
    )
  }, [state.role, state.createdRole, state.updatedRole])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    onChangePermissions,
    form,
    id,
    loading,
    basePath,
  })
}

export default Container
