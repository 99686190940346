// import React from 'react'

// import { Link } from 'react-router-dom'

export default (query, pagination, listData) => {
  const styleBottomBorder = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    }

    if (groupindex[row.hccdescription] === index) {
      obj.props.style = { borderBottom: '1px solid #000' }
    }

    return obj
  }

  const hccOrderColumn = (value, row, index) => {
    const trueIndex = index + pagination.pageSize * (pagination.current - 1)
    const obj = {
      children: value,
      props: {
        style: { borderBottom: '1px solid #000' },
      },
    }

    if (index >= 1 && row.hccdescription === listData[trueIndex - 1].hccdescription) {
      obj.props.rowSpan = 0
      obj.props.groupindex = 0
    } else {
      for (
        let i = 0;
        trueIndex + i !== listData.length &&
        row.hccdescription === listData[trueIndex + i].hccdescription;
        i += 1
      ) {
        obj.props.rowSpan = i + 1
        obj.props.groupindex = i

        groupindex[row.hccdescription] = trueIndex + i
      }
    }

    return obj
  }

  const groupindex = {}
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: hccOrderColumn,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      render: hccOrderColumn,
    },
    {
      title: 'HCC',
      dataIndex: 'hcc',
      key: 'hcc',
      render: hccOrderColumn,
    },
    {
      title: 'HCC Description',
      dataIndex: 'hccdescription',
      key: 'hccdescription',
      render: hccOrderColumn,
    },
    {
      title: 'ICD 10',
      dataIndex: 'icd10',
      key: 'icd10',
      render: styleBottomBorder,
    },
    {
      title: 'ICD 10 Description',
      dataIndex: 'icd10description',
      key: 'icd10description',
      render: styleBottomBorder,
    },
    {
      title: 'Last Reported By',
      dataIndex: 'lastreportedby',
      key: 'lastreportedby',
      render: styleBottomBorder,
    },
    {
      title: 'Last Reported Date',
      dataIndex: 'lastreporteddate',
      key: 'lastreporteddate',
      render: styleBottomBorder,
    },
    {
      title: 'Times Reported',
      dataIndex: 'timesreported',
      key: 'timesreported',
      render: styleBottomBorder,
    },
    {
      title: 'Suspect Source',
      dataIndex: 'suspectsource',
      key: 'suspectsource',
      render: styleBottomBorder,
    },
    {
      title: 'Suspect Reason',
      dataIndex: 'suspectreason',
      key: 'suspectreason',
      render: styleBottomBorder,
    },
  ]

  return columns
}
