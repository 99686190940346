import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberchartsfilesApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/memberchartsfiles/`, { params })
export const createMemberchartfileApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/crm/memberchartsfiles`, data)
export const getMemberchartfileApi = id => Api.get(`${API_URL}/api/v1/crm/memberchartsfiles/${id}`)
export const updateMemberchartfileApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/crm/memberchartsfiles/${id}`, data)
export const deleteMemberchartfileApi = id =>
  Api.delete(`${API_URL}/api/v1/crm/memberchartsfiles/${id}/`)
