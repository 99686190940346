import { authRole, AuthType } from './AppEnums'

export const sizePerPage = 10
export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  authType: AuthType.JWT_AUTH,
  token: 'access-token',
  role: authRole.admin,
  photoURL: '/assets/images/avatar/A11.jpg',
}

export const REGIONS = [
  { label: 'All', value: '' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Florida', value: 'Florida' },
]

export const initialUrl = '/landing-page/welcome' // this url will open after login

export const p = {
  SUPER_USER_RO: 'SUPER_USER_RO',
  SUPER_USER_RW: 'SUPER_USER_RW',
  USER_RO: 'USER_RO',
  USER_RW: 'USER_RW',
  ROLES_RO: 'ROLES_RO',
  ROLES_RW: 'ROLES_RW',
  PERMISSIONS_RO: 'PERMISSIONS_RO',
  PERMISSIONS_RW: 'PERMISSIONS_RW',
  PERMISSION_GROUPS_RO: 'PERMISSION_GROUPS_RO',
  PERMISSION_GROUPS_RW: 'PERMISSION_GROUPS_RW',
  DEMO1_RO: 'DEMO1_RO',
  DEMO1_RW: 'DEMO1_RW',
  DEMO2_RO: 'DEMO2_RO',
  DEMO2_RW: 'DEMO2_RW',
  REPORT_RO: 'REPORT_RO',
  REPORT_RW: 'REPORT_RW',
  PROFILE_RO: 'PROFILE_RO',
  PROFILE_RW: 'PROFILE_RW',
  ANALYTICS_RO: 'ANALYTICS_RO',
  ANALYTICS_RW: 'ANALYTICS_RW',
  MEMBER_DETAILS_VIEW_SEARCH_PAGE: 'MEMBER_DETAILS_VIEW_SEARCH_PAGE',
  MEMBER_DETAILS_VIEW_DETAILS_PAGE: 'MEMBER_DETAILS_VIEW_DETAILS_PAGE',
  MEMBER_DETAILS: 'MEMBER_DETAILS',
  GROUP_DASHBOARD_VIEW_SEARCH_PAGE: 'GROUP_DASHBOARD_VIEW_SEARCH_PAGE',
  FD_VIEW: 'FD_VIEW',
  AD_VIEW: 'AD_VIEW',
  CD_VIEW: 'CD_VIEW',
  PED_VIEW: 'PED_VIEW',
}

export const MEMBER_STATUS_ACTIVE = 'Active'

export const LOB_COMMERCIAL = 'Commercial'
export const LOB_EXCHANGE = 'Exchange'
export const LOB_DSNP = 'Medicaid DSNP'
export const LOB_ADVANTAGE = 'Medicare Advantage'
export const LOB_SELF_FUNDED = 'Self-funded'

export const PROVIDER = 'Provider'
export const PCP = 'PCP'

export const COVERAGE_PROVIDER = 'Provider Only'
export const COVERAGE_OFFICE = 'Office-wide'

export const QUALITY_GAP_PHA_CAPTURED = 'CAPTURED_ON_PHA'
export const QUALITY_GAP_PHA_MISSED = 'MISSED_ON_PHA'

export const lineOfBusinesses = [
  { label: LOB_COMMERCIAL, value: LOB_COMMERCIAL },
  { label: LOB_EXCHANGE, value: LOB_EXCHANGE },
  { label: LOB_DSNP, value: LOB_DSNP },
  { label: LOB_ADVANTAGE, value: LOB_ADVANTAGE },
  { label: LOB_SELF_FUNDED, value: LOB_SELF_FUNDED },
]

export const memberStatuses = [
  { label: 'Active', value: 'Active' },
  { label: 'Termed', value: 'Termed' },
]

export const HCC_STATUS_OPEN = 'Open'
export const HCC_STATUS_CLOSED = 'Closed'

export const HCC_SECTION_HISTORICAL = 'Historical'
export const HCC_SECTION_SUSPECTED = 'Suspected'

export const hccCurrentStatuses = [
  { label: 'Open', value: HCC_STATUS_OPEN },
  { label: 'Closed', value: HCC_STATUS_CLOSED },
]

export const QGAP_STATUS_OPEN = 0
export const QGAP_STATUS_CLOSED = 1

export const qGapStatuses = [
  { label: 'Open', value: QGAP_STATUS_OPEN },
  { label: 'Closed', value: QGAP_STATUS_CLOSED },
]

export const qGapCoderStatuses = [
  { label: 'Captured on PHA', value: QUALITY_GAP_PHA_CAPTURED },
  { label: 'Missed on PHA', value: QUALITY_GAP_PHA_MISSED },
]

export const providerOrPCP = [
  { label: PROVIDER, value: PROVIDER },
  { label: PCP, value: PCP },
]

export const coverageList = [
  { label: COVERAGE_PROVIDER, value: COVERAGE_PROVIDER },
  { label: COVERAGE_OFFICE, value: COVERAGE_OFFICE },
]

export const ICD_STATUS_PCF_DX = 'ICD03'

export const ICD_NEW_STATUS_RECAPTURE = 'INR02'
export const ICD_NEW_STATUS_NEW = 'INR01'

export const QUERY_STATUS_QUERY_PENDING = 'QUE01'

export const QUALITY_GAP_STATUS_OPEN = 0
export const QUALITY_GAP_STATUS_CLOSE = 1
export const QUALITY_GAP_STATUS_FOR_REVIEW = 2

export const CHART_STATUS_QUERY_PENDING = 'CHA07'
export const CHART_STATUS_PENDING = 'CHA01'

export const MINIMUM_CPT_COUNT = 10
export const CPT_CODES = [
  {
    value: '3079F',
    label: 'Diastolic between 80 and 89',
  },
  {
    value: '3078F',
    label: 'Diastolic < 80',
  },
  {
    value: '3075F',
    label: 'Systolic between 130 and 139',
  },
  {
    value: '3074F',
    label: 'Systolic < 130',
  },
  {
    value: '3072F',
    label: 'Negative/low risk retinopathy (prior year test)',
  },
  {
    value: '3052F',
    label: 'A1C between 8.0% and  9.0% inlcuding 8 and 9',
  },
  {
    value: '3051F',
    label: 'A1C between 7.0% and 7.9%',
  },
  {
    value: '3044F',
    label: 'A1C less than 7.0%',
  },
  {
    value: '2025F',
    label: 'DRE negative',
  },
  {
    value: '2024F',
    label: 'DRE positive',
  },
  {
    value: '2022F',
    label: 'DRE inadequate images',
  },
  {
    value: '1170F',
    label: 'Functional Assessment',
  },
  {
    value: '1160F',
    label: 'Review of all medications',
  },
  {
    value: '1159F',
    label: 'Medication list in record',
  },
  {
    value: '1126F',
    label: 'Pain Not Present',
  },
  {
    value: '1125F',
    label: 'Pain Present',
  },
  {
    value: '1111F',
    label: 'Discharge medications Reconciled',
  },
  {
    value: '99397',
    label: 'AWV EST 65 + yrs',
  },
  {
    value: '99396',
    label: 'AWV EST 64 to 40 yrs',
  },
  {
    value: '99395',
    label: 'AWV EST 18 to 39 yrs',
  },
  {
    value: '99387',
    label: 'AWV New 65 + yrs',
  },
  {
    value: '99386',
    label: 'AWV New 64 to 40 yrs',
  },
  {
    value: '99385',
    label: 'AWV New 18 to 39 yrs',
  },
  {
    value: '99214',
    label: 'Telehealth Est',
  },
  {
    value: '99204',
    label: 'Telehealth New',
  },
  {
    value: '95905',
    label: 'DPN, Motor and/or sensory nerve conduction',
  },
  {
    value: '93922',
    label: 'Quantaflo/Padnet Doppler waveform recording',
  },
  {
    value: '83036',
    label: 'A1c Test',
  },
  {
    value: '81000',
    label: 'Urinalysis, by dip stick or tablet',
  },
  {
    value: '76977',
    label: 'DEXA/Bone Density Screening',
  },
]

export const CPT_POS = [
  { value: '02', label: 'Telehealth' },
  { value: '11', label: 'Office Visit' },
  { value: '12', label: 'Home Visit' },
]
