import { useOAuth2, useOAuth2Actions } from '../services/auth/oauth2/OAuth2Provider'
import { getUserFromOAuth2 } from './Utils'
import * as qs from 'qs'
import { useSearchParams } from 'react-router-dom'
import * as axios from 'axios'

const OAUTH_ENDPOINT = `${process.env.REACT_APP_ADFS_HOST}adfs/oauth2/`

const defaultParams = {
  client_id: process.env.REACT_APP_ADFS_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin`,
}

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useOAuth2()
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromOAuth2(user),
  }
}

export const useAuthMethod = () => {
  const { signIn, logout } = useOAuth2Actions()

  return {
    signIn,
    logout,
  }
}

export const useAdfs = () => {
  const [searchParams] = useSearchParams()

  const getUser = async () => {
    const code = searchParams.get('code')
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/oauth/user_code/token?code=${code}`,
    )
    return response.data
  }

  const authorize = () => {
    const params = { response_type: 'code', scope: 'openid', ...defaultParams }
    const url = `${OAUTH_ENDPOINT}authorize?${qs.stringify(params)}`
    window.location.href = url
  }

  return {
    authorize,
    getUser,
  }
}
