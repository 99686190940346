import React, { Fragment } from 'react'
import ViewContainer from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Typography, Row, Col, Form, Input, Divider, Checkbox, Button } from 'antd'
import { SaveFilled, ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { _rules } from './validations'

const { Title } = Typography

const View = () => {
  return (
    <ViewContainer>
      {({
        state,
        initialValues,
        onFinish,
        onFinishFailed,
        onFieldsChange,
        onChangePermissions,
        loading,
        form,
        id,
        basePath,
      }) => (
        <>
          <AppPageMetadata title="Roles" />
          <AppRowContainer>
            <Col span={24}>
              <Spin spinning={loading}>
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={onFieldsChange}
                >
                  <Row>
                    <Col span={12}>
                      <Title level={2}>
                        {id === 'new' ? 'Create Role' : `Update Role ID #${id}`}
                      </Title>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={12}>
                      <AppCard>
                        <Title level={3}>Basic Information</Title>

                        <Form.Item name="name" className="form-field" rules={_rules(id, 'name')}>
                          <Input placeholder="Role Name" />
                        </Form.Item>
                      </AppCard>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={18}>
                      <Divider />
                      <AppCard>
                        <Title level={3}>Permissions</Title>
                        <Form.Item name="permissions">
                          <Checkbox.Group
                            style={{ width: '100%' }}
                            name="permissions"
                            onChange={onChangePermissions}
                          >
                            {state.permissionGroups.status === 2 &&
                              state.permissionGroups.payload.map(
                                (permissionGroup, key) =>
                                  permissionGroup.permissions.length > 0 && (
                                    <Fragment key={`permissionGroup.${key}`}>
                                      <Row>
                                        <Title level={5}>{permissionGroup.name}</Title>
                                      </Row>
                                      <Row style={{ marginBottom: 20 }}>
                                        {permissionGroup.permissions.map((permission, index) => (
                                          <Col span={8} key={`permission.${index}`}>
                                            <Checkbox value={permission.id}>
                                              <span style={{ fontSize: 12 }}>
                                                {permission.name}
                                              </span>
                                            </Checkbox>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Fragment>
                                  ),
                              )}
                          </Checkbox.Group>
                        </Form.Item>
                      </AppCard>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <Link to={basePath}>
                        <Button type="primary" icon={<ArrowLeftOutlined />} ghost>
                          Back to list
                        </Button>
                      </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View
