/* eslint-disable lines-around-comment */
import { Space, Button, Select, Popconfirm, Input, DatePicker } from 'antd'
import { coverageList } from '../../../../../../shared/constants/AppConst'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input

export default (
  state,
  newGroupsEntry,
  groups,
  educationtopics,
  handleUpdate,
  handleSave,
  handleChangeGroupChange,
  handleDeletePE,
) => {
  return [
    {
      title: 'Topic',
      dataIndex: 'topic_id',
      key: 'topic_id',
      width: 200,
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.topic_id}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.id) {
                  handleUpdate(record.id, 'topic_id', v)
                } else {
                  record.topic_id = v
                }
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {educationtopics &&
              educationtopics.map((et, index) => (
                <Option key={index} value={et.id}>
                  {et.name}
                </Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'HCC',
      dataIndex: 'hcc',
      key: 'hcc',
      width: 200,
      render: (text, record) => (
        <>
          <Input
            defaultValue={record?.hcc}
            onBlur={e => {
              if (record.hcc !== e.target.value) {
                if (record.id) {
                  handleUpdate(record.id, 'hcc', e.target.value)
                } else {
                  record.hcc = e.target.value
                }
              }
            }}
            placeholder=""
          />
        </>
      ),
    },
    {
      title: 'Latest DOS Reported',
      dataIndex: 'latestdos',
      key: 'latestdos',
      width: 200,
      render: (text, record) => (
        <>
          <DatePicker
            defaultValue={record.latestdos ? moment(record.latestdos) : null}
            onBlur={e => {
              if (record.latestdos !== e.target.value) {
                if (record.id) {
                  handleUpdate(record.id, 'latestdos', e.target.value)
                } else {
                  record.latestdos = e.target.value
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: 'Educational Details',
      dataIndex: 'details',
      key: 'details',
      width: 200,
      render: (text, record) => (
        <>
          <TextArea
            defaultValue={record?.details}
            onBlur={e => {
              if (record.details !== e.target.value) {
                if (record.id) {
                  handleUpdate(record.id, 'details', e.target.value)
                } else {
                  record.details = e.target.value
                }
              }
            }}
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder=""
          />
        </>
      ),
    },
    {
      title: 'Education Provided Date',
      dataIndex: 'educationprovideddate',
      key: 'educationprovideddate',
      width: 200,
      render: (text, record) => (
        <>
          <DatePicker
            defaultValue={
              record.educationprovideddate ? moment(record.educationprovideddate) : null
            }
            onBlur={e => {
              if (record.educationprovideddate !== e.target.value) {
                if (record.id) {
                  handleUpdate(record.id, 'educationprovideddate', e.target.value)
                } else {
                  record.educationprovideddate = e.target.value
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: 'Rendering Group',
      dataIndex: 'providergrouptin',
      key: 'providergrouptin',
      width: 200,
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.providergrouptin}
            options={groups}
            value={record?.providergrouptin}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.id) {
                  handleUpdate(record.id, 'providergrouptin', v)
                  handleUpdate(record.id, 'pcpnpi', '')
                }

                record.providergrouptin = v
                record.pcpnpi = ''

                handleChangeGroupChange(record, { providergrouptin: v })
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          ></Select>
        </>
      ),
    },
    {
      title: 'Rendering Provider',
      dataIndex: 'pcpnpi',
      key: 'pcpnpi',
      width: 200,
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.pcpnpi}
            value={record?.pcpnpi}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              handleUpdate(record.id, 'pcpnpi', v)

              record.pcpnpi = v
            }}
            style={{ width: '100%' }}
            options={(
              newGroupsEntry ||
              (groups || []).find(g => g.value === record.providergrouptin) || { pcps: [] }
            ).pcps.map((pcp, index) => ({
              key: index,
              value: pcp.pcpnpi,
              label: pcp.pcpname,
            }))}
            placeholder={
              state.pcpnames.status === 1 || state.pcpgroups.status === 1 ? 'Loading...' : ''
            }
            disabled={state.pcpnames.status === 1 || state.pcpgroups.status === 1}
          ></Select>
        </>
      ),
    },
    {
      title: 'Coverage',
      dataIndex: 'coverage',
      key: 'coverage',
      width: 200,
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.coverage}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.id) {
                  handleUpdate(record.id, 'coverage', v)
                } else {
                  record.coverage = v
                }
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {coverageList &&
              coverageList.map((c, index) => (
                <Option key={index} value={c.value}>
                  {c.value}
                </Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Educator',
      dataIndex: 'educator',
      key: 'educator',
      width: 200,
      render: (text, record) => (
        <>
          <Input
            defaultValue={record?.educator}
            onBlur={e => {
              if (record.educator !== e.target.value) {
                if (record.id) {
                  handleUpdate(record.id, 'educator', e.target.value)
                } else {
                  record.educator = e.target.value
                }
              }
            }}
            placeholder=""
          />
        </>
      ),
    },
    {
      title: 'Educational Comment',
      dataIndex: 'comments',
      key: 'comments',
      width: 200,
      render: (text, record) => (
        <>
          <TextArea
            defaultValue={record?.comments}
            onBlur={e => {
              if (record.comments !== e.target.value) {
                if (record.id) {
                  handleUpdate(record.id, 'comments', e.target.value)
                } else {
                  record.comments = e.target.value
                }
              }
            }}
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder=""
          />
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <Space>
          {record.id === 0 && (
            <Button size="small" type="primary" onClick={() => handleSave(record)}>
              Add
            </Button>
          )}

          {!!record.id && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleDeletePE(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]
}
