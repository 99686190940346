import moment from 'moment'
import { Popconfirm, Button } from 'antd'

export default (filters, filteredInfo, sortedInfo, addDocument, deleteDocument) => {
  return [
    {
      title: 'File Type',
      dataIndex: 'filetypedescription',
      key: 'filetypedescription',
    },
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
      render: (text, record) => (
        <>
          <a href={record.file_url} target="_blank" rel="noreferrer">
            {record.filename && record.filename.split('/').pop(-1)}
          </a>
        </>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'filenotes',
      key: 'filenotes',
    },
    {
      title: 'Uploaded By',
      dataIndex: 'createdby',
      key: 'createdby',
    },
    {
      title: 'Uploaded date',
      dataIndex: 'createddate',
      key: 'createddate',
      render: (text, record) => `${moment(record?.createddate).format('MM/DD/YYYY')}`,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size="small" type="primary" onClick={() => addDocument(record.fileid)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              deleteDocument(record.fileid)
            }}
          >
            <Button size="small" type="danger">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
}
