import { thunk, action } from 'easy-peasy'
import { listMemberdetailsApi, getMemberdetailApi } from '../../api/memberdetail'

export default {
  // List Memberdetails
  memberdetails: { status: 0, payload: null },
  setMemberdetails: action((state, payload = null) => {
    state.memberdetails = payload
  }),
  getMemberdetails: thunk(async (actions, params = {}) => {
    actions.setMemberdetails({ status: 1, payload: null })

    listMemberdetailsApi(params)
      .then(response => actions.setMemberdetails({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberdetails({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberdetail
  memberdetail: { status: 0, payload: null },
  setMemberdetail: action((state, payload = null) => {
    state.memberdetail = payload
  }),
  getMemberdetail: thunk(async (actions, id) => {
    actions.setMemberdetail({ status: 1, payload: null })

    getMemberdetailApi(id)
      .then(response => actions.setMemberdetail({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberdetail({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
