import { thunk, action } from 'easy-peasy'
import {
  listPermissionsApi,
  getPermissionApi,
  createPermissionApi,
  updatePermissionApi,
  deletePermissionApi,
} from '../../api/permission'

export default {
  // List Permissions
  permissions: { status: 0, payload: null },
  setPermissions: action((state, payload = null) => {
    state.permissions = payload
  }),
  getPermissions: thunk(async (actions, params = {}) => {
    actions.setPermissions({ status: 1, payload: null })

    listPermissionsApi(params)
      .then(response => actions.setPermissions({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPermissions({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Permission
  createdPermission: { status: 0, payload: null },
  setCreatedPermission: action((state, payload = null) => {
    state.createdPermission = payload
  }),
  createPermission: thunk(async (actions, data = {}) => {
    actions.setCreatedPermission({ status: 1, payload: null })

    createPermissionApi(data)
      .then(response => actions.setCreatedPermission({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedPermission({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Permission
  permission: { status: 0, payload: null },
  setPermission: action((state, payload = null) => {
    state.permission = payload
  }),
  getPermission: thunk(async (actions, id) => {
    actions.setPermission({ status: 1, payload: null })

    getPermissionApi(id)
      .then(response => actions.setPermission({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPermission({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Permission
  deletedPermission: { status: 0, payload: null },
  setDeletedPermission: action((state, payload = null) => {
    state.deletedPermission = payload
  }),
  deletePermission: thunk(async (actions, id) => {
    actions.setDeletedPermission({ status: 1, payload: null })

    deletePermissionApi(id)
      .then(response => actions.setDeletedPermission({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedPermission({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Permission
  updatedPermission: { status: 0, payload: null },
  setUpdatedPermission: action((state, payload = null) => {
    state.updatedPermission = payload
  }),
  updatePermission: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedPermission({ status: 1, payload: null })

    updatePermissionApi(id, data)
      .then(response => actions.setUpdatedPermission({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedPermission({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
