// ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data'

// ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data'

// ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data'

// CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data'

// CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data'

// CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data'

// METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data'

// WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data'
