import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message, Form } from 'antd'

const initialValues = {
  activitystatusid: '',
  activitynotes: '',
}

const Container = ({ children, chartid }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    createdMemberchartactivityrecord: s.createdMemberchartactivityrecord,
  }))

  const actions = useStoreActions(a => ({
    createMemberchartactivityrecord: a.createMemberchartactivityrecord,
    setCreatedMemberchartactivityrecord: a.setCreatedMemberchartactivityrecord,
    getMemberchart: a.getMemberchart,
    getMemberchartsactivityrecords: a.getMemberchartsactivityrecords,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [formValues, setFormValues] = useState(initialValues)
  const [statuses, setStatuses] = useState(null)

  const onFinish = values => {
    values.chartid = chartid
    values && actions.createMemberchartactivityrecord(values)
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      setStatuses(payload.filter(x => x.codetype === 'ChartStatus'))
    }
  }, [state.statuscodes])

  // Side effect of createdNonSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdMemberchartactivityrecord

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedMemberchartactivityrecord({ status: 0, payload: null })
    } else if (status === 2) {
      form.resetFields()
      message.success('Successfully added new record')
      actions.setCreatedMemberchartactivityrecord({ status: 0, payload: null })
      actions.getMemberchart(chartid)
      actions.getMemberchartsactivityrecords({ chartid })
    }
  }, [state.createdMemberchartactivityrecord])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    statuses,
  })
}

export default Container
