import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'
import { message, Form } from 'antd'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  password: '',
  roles: [],
}

const basePath = '/access-management/nonSuperusers'

const Container = ({ children }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    createdNonSuperUser: s.createdNonSuperUser,
    updatedNonSuperUser: s.updatedNonSuperUser,
    nonSuperUser: s.nonSuperUser,
    roles: s.roles,
  }))

  const actions = useStoreActions(a => ({
    setNonSuperUser: a.setNonSuperUser,
    setCreatedNonSuperUser: a.setCreatedNonSuperUser,
    setUpdatedNonSuperUser: a.setUpdatedNonSuperUser,
    setRoles: a.setRoles,
    createNonSuperUser: a.createNonSuperUser,
    updateNonSuperUser: a.updateNonSuperUser,
    getNonSuperUser: a.getNonSuperUser,
    getRoles: a.getRoles,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)
  const [permissions, setPermissions] = useState([])

  const navigate = useNavigate()
  const auth = useAuthUser()

  const onFinish = values => {
    if (!values.password) {
      delete values.password
    }

    if (id === 'new') {
      actions.createNonSuperUser(values)
    } else {
      actions.updateNonSuperUser({ id, ...values })
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const getPermissionsByRoles = values => {
    const results = state.roles.payload.results.filter(
      role => values.length && values.includes(role.id),
    )
    const permissions = []
    // eslint-disable-next-line no-console
    console.log('###', results, '-', values)

    results.forEach(result => {
      result.permissions.forEach(permission => {
        if (permissions.filter(p => p.name === permission.name).length === 0) {
          permissions.push(permission)
        }
      })
    })

    return permissions
  }

  const onRoleChange = value => {
    const permissions = getPermissionsByRoles(value)
    // eslint-disable-next-line no-console
    console.log(value, permissions)
    setPermissions(permissions)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    if (!has(auth.user, [p.USER_RW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [])

  // On Load
  useEffect(() => {
    if (id !== 'new') {
      actions.getNonSuperUser(id)
    }

    actions.getRoles({ per_page: 5000 })

    // Reset retrieve state on unmount
    return () => {
      actions.setNonSuperUser({ status: 0, payload: null })
      actions.setRoles({ status: 0, payload: null })
    }
  }, [])

  // Side effect of state.nonSuperUser
  useEffect(() => {
    const { status, payload } = state.nonSuperUser

    if (status === 2 && state.roles.status === 2) {
      // Set initial values of form
      const rolesList = payload.roles ? payload.roles.map(r => r.id) : []

      setFormValues(prev => ({
        ...prev,
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        username: payload.username,
        password: payload.password,
        roles: rolesList,
      }))

      const permissions = getPermissionsByRoles(rolesList)
      setPermissions(permissions)
    }
  }, [state.roles, state.nonSuperUser])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  // Side effect of createdNonSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdNonSuperUser

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedNonSuperUser({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully added new record')
      navigate(basePath)
      actions.setCreatedNonSuperUser({ status: 0, payload: null })
    }
  }, [state.createdNonSuperUser])

  // Side effect of updatedNonSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedNonSuperUser

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedNonSuperUser({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully updated the record')
      navigate(basePath)
      actions.setUpdatedNonSuperUser({ status: 0, payload: null })
    }
  }, [state.updatedNonSuperUser])

  useEffect(() => {
    setLoading(
      state.nonSuperUser.status === 1 ||
        state.createdNonSuperUser.status === 1 ||
        state.updatedNonSuperUser.status === 1,
    )
  }, [state.nonSuperUser, state.createdNonSuperUser, state.updatedNonSuperUser])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onRoleChange,
    onFieldsChange,
    permissions,
    form,
    id,
    loading,
    basePath,
  })
}

export default Container
