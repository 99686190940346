// SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST'
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST'
export const GET_BOARDS = 'GET_BOARDS'
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL'
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST'
export const ADD_LIST_CARD = 'ADD_LIST_CARD'
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD'
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD'
export const DELETE_BOARD = 'DELETE_BOARD'
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD'
export const DELETE_LIST = 'DELETE_LIST'
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL'
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST'
