import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'
import moment from 'moment'
import { sizePerPage, p } from '../../../../shared/constants/AppConst'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'

import tableColumns from './columns'
import qs from 'query-string'

const basePath = '/landing-page/abstractordashboard'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    nonSuperUsers: s.nonSuperUsers,
    memberqualityrecords: s.memberqualityrecords,
    updatedMemberqualityrecord: s.updatedMemberqualityrecord,
  }))
  const actions = useStoreActions(a => ({
    getNonSuperUsers: a.getNonSuperUsers,
    setNonSuperUsers: a.setNonSuperUsers,
    getMemberqualityrecords: a.getMemberqualityrecords,
    updateMemberqualityrecord: a.updateMemberqualityrecord,
    setUpdatedMemberqualityrecord: a.setUpdatedMemberqualityrecord,
  }))

  const [t, setT] = useState(null)
  const [users, setUsers] = useState(null)

  // const [aggre, setAggre] = useState(null)
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    showSizeChanger: false,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
  })

  // Columns
  const columns = tableColumns(actions.updateMemberqualityrecord)

  useEffect(() => {
    actions.getNonSuperUsers({ with_created_chart: 1, per_page: 9999 })
  }, [])

  useEffect(() => {
    if (!has(auth.user, [p.AD_VIEW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(`${location.search}`)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    if (queries?.assignedviewer || queries?.requestdate__gt || queries?.requestdate__lt) {
      actions.getMemberqualityrecords(queries)
    }
  }, [queries])

  useEffect(() => {
    const { status } = state.updatedMemberqualityrecord

    if (status === 2) {
      message.success('Successfully updated the record')
      actions.getMemberqualityrecords(queries)
      actions.setUpdatedMemberqualityrecord({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setUpdatedMemberqualityrecord({ status: 0, payload: null })
    }
  }, [state.updatedMemberqualityrecord])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberqualityrecords

    if (status === 2) {
      setListData(payload)

      // setPagination(prev => ({
      //   ...prev,
      //   total: payload.count,
      // }))
    }
  }, [state.memberqualityrecords])

  useEffect(() => {
    const { status, payload } = state.nonSuperUsers

    if (status === 2 && payload) {
      const { results } = payload
      setUsers(results)

      actions.setNonSuperUsers({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setNonSuperUsers({ status: 0, payload: null })
    }
  }, [state.nonSuperUsers])

  // antd Table component's handleTableChange event function
  // const onTableChange = (paginate, filters, sorter) => {
  //   // Update Pagination Data
  //   setPagination(paginate)

  //   // Prepare Query Strings Filters
  //   const query = {
  //     ...queries,
  //     page: paginate.current,
  //   }

  //   delete query.ordering

  //   if (sorter.columnKey && sorter.order) {
  //     query.ordering = `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`
  //   }

  //   setQueries(query)

  //   // Change URL
  //   navigate(`${basePath}/?${qs.stringify(query)}`)
  // }

  const onResultFilter = value => {
    const query = {
      ...queries,
      search: value,
      page: 1,
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const handleUserChange = value => {
    const v = value || null

    setQueries({ ...queries, assignedviewer: v, page: 1 })
  }

  const handleDateRange = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      requestdate__gt: startDate,
      requestdate__lt: endDate,
      page: 1,
    }

    setQueries(query)
  }

  return children({
    state,
    columns,
    queries,
    listData,
    pagination,
    setT,
    onResultFilter,
    handleUserChange,
    handleDateRange,
    t,
    users,
  })
}

export default Container
