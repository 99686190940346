export const GET_TASK_LIST = 'get_task_list'
export const CREATE_NEW_TASK = 'create_new_task'
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer'
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST'
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST'
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST'
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST'
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER'
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL'
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS'
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL'
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL'
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST'
