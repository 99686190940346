import React from 'react'
import ViewContainer from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'

import ProviderEducation from './ProviderEducation'
import MemberDetails from './MemberDetails'
import HCCGap from './HCCGap'
import HCCCurrent from './HCCCurrent'
import QualityGap from './QualityGap'
import Chart from './Chart'

// import Appointment from './Appointment'

import { Spin, Typography, Tabs, Row, Col, Card } from 'antd'
import { Link } from 'react-router-dom'

// import moment from 'moment'

const { Title } = Typography
const { TabPane } = Tabs

const View = () => {
  return (
    <ViewContainer>
      {({
        state,
        setActiveTab,
        query,
        id,
        details,
        basePath,
        memberDetails,
        activeTab,
        setPcpDetails,
        pcpDetails,
      }) => (
        <>
          <AppPageMetadata title="Member Details View" />
          <AppRowContainer>
            <Col span={24}>
              <Spin spinning={state.memberenrollmentlatestrecord.status === 1}>
                <Row>
                  <Col style={{ marginBottom: 0 }} span={24}>
                    <Title level={2}>Member View</Title>
                  </Col>
                  {state.memberenrollmentlatestrecord.status === 2 &&
                    state.memberlists.status === 2 && (
                      <>
                        <Col
                          span={24}
                          style={{
                            paddingBottom: 9,
                            fontWeight: 'bold',
                            borderBottom: '1px solid #AAAAAA',
                          }}
                        >
                          <Link
                            to={`${basePath}?search=${query.search}&lob=${query.lob}&memberstatus=${query.memberstatus}`}
                          >
                            Member Search
                          </Link>{' '}
                          {details.memberid ? '>' : ''} {details?.memberid}{' '}
                          {details.memberlastname ? `${details.memberlastname},` : ''}
                          {details.memberfirstname ? `${details.memberfirstname} > ` : ''}
                          {details?.dob}
                        </Col>

                        {/** TABS */}
                        <Col span={24}>
                          <Card style={{ width: '100%' }}>
                            <Tabs
                              onTabClick={tab => setActiveTab(tab)}
                              defaultActiveKey={activeTab}
                            >
                              <TabPane tab="Member Details" key="member_details">
                                {activeTab === 'member_details' && (
                                  <MemberDetails
                                    details={details}
                                    pcpDetails={pcpDetails || {}}
                                    memberDetails={memberDetails}
                                    id={id}
                                  />
                                )}
                              </TabPane>
                              <TabPane tab="HCC History" key="hcc_gap">
                                {activeTab === 'hcc_gap' && <HCCGap id={id} />}
                              </TabPane>
                              <TabPane tab="HCC Current" key="hcc_current">
                                {activeTab === 'hcc_current' && <HCCCurrent id={id} />}
                              </TabPane>
                              <TabPane tab="Quality Gap" key="quality_gap">
                                {activeTab === 'quality_gap' && <QualityGap id={id} />}
                              </TabPane>
                              {/* <TabPane tab="Appointments" key="appointments">
                                {activeTab === 'appointments' && (
                                  <Appointment setPcpDetails={setPcpDetails} id={id} />
                                )}
                              </TabPane> */}
                              <TabPane tab="Charts" key="charts">
                                {activeTab === 'charts' && <Chart id={id} />}
                              </TabPane>
                              <TabPane tab="Provider Education" key="provider_education">
                                {activeTab === 'provider_education' && (
                                  <ProviderEducation id={id} />
                                )}
                              </TabPane>
                            </Tabs>
                          </Card>
                        </Col>
                      </>
                    )}
                </Row>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View
