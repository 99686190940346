const commonValidations = {
  first_name: [{ required: true, message: 'First name is required.' }],
  last_name: [{ required: true, message: 'Last name is required.' }],
  username: [{ required: true, message: 'Username is required.' }],
  email: [
    { required: true, message: 'Email is required.' },
    { type: 'email', message: 'Email is invalid' },
  ],
  roles: [{ required: true, message: 'Must have at least 1 role.' }],
}

const createValidations = {
  ...commonValidations,
  password: [{ required: true, message: 'Password is required.' }],
}

export const _rules = (id, name) => {
  // Return Create Validations if New
  if (id === 'new') {
    return createValidations[name] || []
  }

  return commonValidations[name] || []
}
