import { Row, Col } from 'antd'
import ProviderEducationList from './ProviderEducationList'

const ProviderEducation = props => (
  <Row gutter={16}>
    <Col span={24}>
      <ProviderEducationList {...props} />
    </Col>
  </Row>
)

export default ProviderEducation
