/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewContainer from './container'

import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Typography, Row, Col, Form, Input, Select, Upload, Divider, Button } from 'antd'
import { SaveFilled, RightOutlined } from '@ant-design/icons'

import { _rules } from './validations'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

const View = ({ chartid, fileid }) => {
  return (
    <ViewContainer chartid={chartid} fileid={fileid}>
      {({
        state,
        initialValues,
        onFinish,
        onFinishFailed,
        onFieldsChange,
        form,
        statuses,
        fileList,
        handleFileRemove,
        beforeFileUpload,
        handleFileUpload,
      }) => (
        <>
          <AppRowContainer>
            <Col span={24}>
              <Spin
                spinning={
                  state.updatedmemberchartfile.status === 1 ||
                  state.memberchartfile.status === 1 ||
                  state.createdMemberchartsfile.status === 1 ||
                  state.createdFile.status === 1
                }
              >
                <AppCard>
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onFieldsChange={onFieldsChange}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={2}>{fileid ? 'Udpdate Document' : 'Document Form'}</Title>
                        <Divider />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>Source:</Col>
                      <Col span={24}>
                        <Form.Item name="filetypecode" rules={_rules('filetypecode')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select Source"
                          >
                            {statuses &&
                              statuses.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>Notes:</Col>
                      <Col span={24}>
                        <Form.Item
                          name="filenotes"
                          className="form-field"

                          // rules={_rules('filenotes')}
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {!fileid && (
                      <Row>
                        <Col span={24}>File:</Col>
                        <Col span={24}>
                          <Upload
                            fileList={fileList}
                            beforeUpload={beforeFileUpload}
                            multiple={false}
                            onRemove={handleFileRemove}
                            customRequest={handleFileUpload}
                          >
                            <Button icon={<RightOutlined />}>Upload</Button>
                          </Upload>
                        </Col>
                      </Row>
                    )}

                    <Divider />
                    <Row>
                      <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </AppCard>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View

View.propTypes = {
  chartid: PropTypes.number,
  fileid: PropTypes.number,
}
