import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberqualityrecordsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/memberqualityrecords/`, { params })
export const getMemberqualityrecordApi = id =>
  Api.get(`${API_URL}/api/v1/crm/memberqualityrecords/${id}`)

export const updateMemberqualityrecordApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/crm/memberqualityrecords/${id}/`, data)
