import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberchartsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/membercharts/`, { params })

export const createMemberchartApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/crm/membercharts/`, data)

export const getMemberchartApi = id => Api.get(`${API_URL}/api/v1/crm/membercharts/${id}`)

export const updateMemberchartApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/crm/membercharts/${id}/`, data)

export const deleteMemberchartApi = id => Api.delete(`${API_URL}/api/v1/crm/membercharts/${id}/`)
