import { thunk, action } from 'easy-peasy'
import { listMemberChartAuditLogsApi } from '../../api/memberchartauditlog'

export default {
  // List Memberchartsactivityrecords
  memberchartauditlogs: { status: 0, payload: null },
  setMemberChartAuditLogs: action((state, payload = null) => {
    state.memberchartauditlogs = payload
  }),
  getMemberChartAuditLogs: thunk(async (actions, params = {}) => {
    actions.setMemberChartAuditLogs({ status: 1, payload: null })

    listMemberChartAuditLogsApi(params)
      .then(response => actions.setMemberChartAuditLogs({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberChartAuditLogs({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
