import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'
import { useLocation } from 'react-router-dom'
import * as _ from 'lodash'

import tableColumns from './columns'

const Container = ({ children, chartid }) => {
  // Global state and actions
  const location = useLocation()
  const baseurl = location.pathname

  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    memberchartsicdcodes: s.memberchartsicdcodes,
    updatedMembercharticdcode: s.updatedMembercharticdcode,
    deletedMembercharticdcode: s.deletedMembercharticdcode,
    createdMembercharticdcode: s.createdMembercharticdcode,
    icdhccrecords: s.icdhccrecords,
  }))
  const actions = useStoreActions(a => ({
    getMemberchartsicdcodes: a.getMemberchartsicdcodes,
    setMemberchartsicdcodes: a.setMemberchartsicdcodes,
    setUpdatedMembercharticdcode: a.setUpdatedMembercharticdcode,
    updateMembercharticdcode: a.updateMembercharticdcode,
    setDeletedMembercharticdcode: a.setDeletedMembercharticdcode,
    deleteMembercharticdcode: a.deleteMembercharticdcode,
    createMembercharticdcode: a.createMembercharticdcode,
    setMembercharticdcode: a.setMembercharticdcode,
    getIcdhccrecords: a.getIcdhccrecords,
  }))

  // Local states
  const [listData, setListData] = useState([])

  // Columns
  const [columns, setColumns] = useState(null)
  const [viewForm, setViewForm] = useState(false)
  const [icdcodeid, setIcdcodeid] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [news, setNews] = useState(null)
  const [queries, setQueries] = useState(null)
  const [failures, setFailures] = useState(null)

  const [icd10s, setIcd10s] = useState(null)
  const [totalIcd10, setTotalIcd10] = useState(0)
  const [totalUniqueHcc, setTotalUniqueHcc] = useState(0)

  const handleSave = data => {
    const { icd10code, icd10description } = data

    if (!icd10code || !icd10description) {
      message.error('Please fill out the fields')
    } else {
      data.chartid = chartid
      actions.createMembercharticdcode(data)
    }
  }

  const handleCreate = records => {
    records.forEach(rec => {
      handleSave(rec)
    })
  }

  const handleClear = () => {
    setListData(listData.filter(p => p.icdcodeid !== 0))
    actions.getMemberchartsicdcodes({ chartid })
  }

  const handleUpdate = (id, name, icdRecordOrValue) => {
    let data

    if (name === 'icd10code') {
      const [record] = icdRecordOrValue

      data = {
        icd10code: record.icd10,
        hcccode: record.hcc,
        hcccodedescription: record.hccdescription,
      }
    } else {
      data = { [name]: icdRecordOrValue }
    }

    actions.updateMembercharticdcode({ id, ...data })
  }

  useEffect(() => {
    if (chartid) {
      actions.getIcdhccrecords({ per_page: 20000 })
      actions.getMemberchartsicdcodes({ chartid })
    }
  }, [chartid])

  useEffect(() => {
    const { status, payload } = state.icdhccrecords

    if (status === 2 && payload && payload.results && payload.results.length > 0) {
      setIcd10s(payload.results)
    } else if (status === 3) {
      setIcd10s(null)
    }
  }, [state.icdhccrecords])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      setStatuses(payload.filter(x => x.codetype === 'ICDStatus'))
      setNews(payload.filter(x => x.codetype === 'ICDNewStatus'))
      setQueries(payload.filter(x => x.codetype === 'QueryStatus'))
      setFailures(payload.filter(x => x.codetype === 'ChartIcdFail'))
    }
  }, [state.statuscodes])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberchartsicdcodes

    if (status === 2 && icd10s) {
      // Set created columns
      setColumns(
        tableColumns(
          statuses,
          news,
          queries,
          failures,
          icd10s,
          handleUpdate,
          handleSave,
          handleClear,
          payload,
          setListData,
          actions,
          handleCreate,
        ),
      )

      payload.push({
        icdcodeid: 0,
        hcccode: '',
        hcccodedescription: '',
        icd10code: '',
        icd10description: '',
        codestatusid: '',
        codenewid: '',
        querystatusid: '',
        failureid: 0,
        failurecomments: '',
      })

      const sortedPayload = payload ? _.sortBy(payload, p => parseInt(p.hcccode)) : []
      setListData(sortedPayload)

      const results = payload.filter(result => result.codenewid !== '')

      setTotalIcd10(results.length)

      setTotalUniqueHcc(
        results
          .map(result => result.hcccode)
          .filter((value, index, self) => self.indexOf(value) === index && value !== '').length,
      )

      actions.setMemberchartsicdcodes({ status: 0, payload: null })
    } else if (status === 3) {
      setListData(null)
      actions.setMemberchartsicdcodes({ status: 0, payload: null })
    }
  }, [state.memberchartsicdcodes, icd10s])

  useEffect(() => {
    const { status, payload } = state.deletedMembercharticdcode

    if (status === 2) {
      setListData(listData.filter(p => p.icdcodeid !== 0))
      actions.getMemberchartsicdcodes({ chartid })
      actions.setDeletedMembercharticdcode({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setDeletedMembercharticdcode({ status: 0, payload: null })
      message.error(`Oops, something went wrong!: ${JSON.stringify(payload)}`)
    }
  }, [state.deletedMembercharticdcode])

  // Side effect of createdMembercharticdcode
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdMembercharticdcode

    if (status === 2) {
      setListData(listData.filter(p => p.icdcodeid !== 0))
      message.success('Successfully added new record')
      actions.setMembercharticdcode({ status: 0, payload: null })
      actions.getMemberchartsicdcodes({ chartid })
    } else if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setMembercharticdcode({ status: 0, payload: null })
    }
  }, [state.createdMembercharticdcode])

  // Side effect of updatedMembercharticdcode
  useEffect(() => {
    // code here will execute
    const { status } = state.updatedMembercharticdcode

    if (status === 2) {
      actions.setUpdatedMembercharticdcode({ status: 0, payload: null })
      actions.getMemberchartsicdcodes({ chartid })
    } else if (status === 3) {
      actions.setUpdatedMembercharticdcode({ status: 0, payload: null })
    }
  }, [state.updatedMembercharticdcode])

  return children({
    state,
    columns,
    listData,
    chartid,
    setViewForm,
    setIcdcodeid,
    totalIcd10,
    totalUniqueHcc,
    viewForm,
    icdcodeid,
    baseurl,
  })
}

export default Container
