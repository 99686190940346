import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { HCC_STATUS_CLOSED, HCC_STATUS_OPEN } from '../../../../../../shared/constants/AppConst'

import tableColumns from './columns'
import qs from 'query-string'

const Container = ({ children, id }) => {
  const location = useLocation()

  // Global state and actions
  const state = useStoreState(s => ({
    memberhcccurrents: s.memberhcccurrents,
  }))
  const actions = useStoreActions(a => ({
    getMemberhcccurrents: a.getMemberhcccurrents,
    setMemberhcccurrents: a.setMemberhcccurrents,
  }))

  // Local states
  const [selectedStatuses, setSelectedStatuses] = useState(null)
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 500,
  })

  // Columns
  const columns = tableColumns(qs.parse(location.search), pagination, listData)

  useEffect(() => {
    if (state.memberhcccurrents.status === 0) {
      actions.getMemberhcccurrents({ internalmemberid: id })
      setSelectedStatuses([HCC_STATUS_OPEN, HCC_STATUS_CLOSED])
    }
  }, [])

  // Side effect of Get List
  // useEffect(() => {
  //   const { status, payload } = state.memberhcccurrents

  //   if (status === 2) {
  //     const data = []

  //     payload &&
  //       payload.forEach((v, i) => {
  //         v.key = i
  //         data.push(v)
  //       })

  //     setListData(payload)

  //     setPagination(prev => ({
  //       ...prev,
  //       total: payload.length,
  //     }))
  //   }
  // }, [state.memberhcccurrents])

  useEffect(() => {
    if (state.memberhcccurrents.status === 2) {
      const currentStatus = selectedStatuses || [HCC_STATUS_OPEN]

      const results = state.memberhcccurrents.payload.filter(row =>
        currentStatus.includes(row.status),
      )

      setPagination(prev => ({
        ...prev,
        total: results.length,
      }))

      setListData(results)
    } else if (state.memberhcccurrents.status === 3) {
      actions.setMemberhcccurrents({ status: 0, payload: null })
    }
  }, [selectedStatuses, state.memberhcccurrents])

  // antd Table component's handleTableChange event function
  const onTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data
    setPagination(paginate)
  }

  const handleStatusChange = value => {
    setSelectedStatuses(value)
  }

  return children({
    state,
    selectedStatuses,
    handleStatusChange,
    columns,
    listData,
    pagination,
    onTableChange,
  })
}

export default Container
