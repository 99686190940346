import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import moment from 'moment'
import { sizePerPage, p, CHART_STATUS_QUERY_PENDING } from '../../../../shared/constants/AppConst'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { message } from 'antd'
import tableColumns from './columns'
import qs from 'query-string'
import * as _ from 'lodash'

const basePath = '/landing-page/fieldcoderdashboard'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    pcpactivegroups: s.pcpactivegroups,
    pcpactivenames: s.pcpactivenames,
    statuscodes: s.statuscodes,
    nonSuperUsers: s.nonSuperUsers,
    memberchartsv2: s.memberchartsv2,
    activevisittypes: s.activevisittypes,
    updatedMultipleMemberCharts: s.updatedMultipleMemberCharts,
  }))
  const actions = useStoreActions(a => {
    return {
      getPcpactivegroups: a.getPcpactivegroups,
      getPcpactivenames: a.getPcpactivenames,
      setPcpactivenames: a.setPcpactivenames,
      getStatuscodes: a.getStatuscodes,
      getNonSuperUsers: a.getNonSuperUsers,
      setNonSuperUsers: a.setNonSuperUsers,
      getMemberchartsv2: a.getMemberchartsv2,
      getActiveVisitTypes: a.getActiveVisitTypes,
      updateMultipleMemberCharts: a.updateMultipleMemberCharts,
    }
  })

  // Local states
  const [selectedMember, setSelectedMember] = useState(null)

  const [t, setT] = useState(null)
  const [users, setUsers] = useState(null)
  const [allUsers, setAllUsers] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [allStatuses, setAllStatuses] = useState(null)
  const [dxStatuses, setDxStatuses] = useState(null)
  const [allDxStatuses, setAllDxStatuses] = useState(null)
  const [kpiCards, setKpiCards] = useState(null)
  const [groups, setGroups] = useState(null)
  const [allGroups, setAllGroups] = useState(null)
  const [names, setNames] = useState(null)
  const [allVisitTypes, setAllVisitTypes] = useState(null)
  const [visitTypes, setVisitTypes] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [assignedUser, setAssignedUser] = useState(null)

  // const [aggre, setAggre] = useState(null)
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    showSizeChanger: true,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
    with_created_chart: 1,
  })

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  // Columns
  const columns = tableColumns()

  useEffect(() => {
    actions.getActiveVisitTypes()
    actions.getPcpactivegroups()
    actions.getStatuscodes()
    actions.getNonSuperUsers({ with_created_chart: 1, per_page: 9999 })
    setSelectedRowKeys([])
    handleStatusChange(CHART_STATUS_QUERY_PENDING)
  }, [])

  useEffect(() => {
    if (!has(auth.user, [p.FD_VIEW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  useEffect(() => {
    const { status } = state.updatedMultipleMemberCharts

    // update the list
    if (status === 2) {
      const query = { ...queries }
      setQueries(query)
      setSelectedRowKeys([])
      setAssignedUser(null)
      message.success('Charts reassigned')
    }
  }, [state.updatedMultipleMemberCharts])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(`${location.search}`)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    if (
      queries?.providergrouptin ||
      queries?.assigneduser__in ||
      queries?.assigneduser__in === '' ||
      queries?.appointmentdate__gte ||
      queries?.appointmentdate__lte ||
      queries?.modifieddate__gte ||
      queries?.modifieddate__lte ||
      queries?.dxstatus__in
    ) {
      const queriesCopy = _.cloneDeep(queries)

      if (queriesCopy.appointmentdate__lte) {
        queriesCopy.appointmentdate__lte = queriesCopy.appointmentdate__lte + ' 23:59:59'
      }

      if (queriesCopy.modifieddate__lte) {
        queriesCopy.modifieddate__lte = queriesCopy.modifieddate__lte + ' 23:59:59'
      }

      const pcpgrptaxid = queries.providergrouptin || null

      pcpgrptaxid && actions.getPcpactivenames({ pcpgrptaxid })
      actions.getMemberchartsv2(queriesCopy)
    }
  }, [queries])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberchartsv2

    if (status === 2) {
      const { aggregates, results } = payload

      const currentUsers = getAggre(aggregates?.assigneduser, 'username', allUsers)
      const currentStatuses = getAggre(aggregates?.statusid, 'statuscode', allStatuses)
      const dxStatus = getAggre(aggregates?.dxstatus, 'statuscode', allDxStatuses)
      const currentGroups = getAggre(aggregates?.providergrouptin, 'pcpgrptaxid', allGroups)
      const currentVisitTypes = getAggre(aggregates?.typeid, 'typeid', allVisitTypes)

      setUsers(currentUsers)
      setStatuses(currentStatuses)
      setDxStatuses(dxStatus)
      setGroups(currentGroups)
      setVisitTypes(currentVisitTypes)

      // setAggre(aggregates)
      setKpiCards(payload.counts.statuses)
      setListData(results)

      setPagination(prev => ({
        ...prev,
        total: payload.count,
      }))
    }
  }, [state.memberchartsv2])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.activevisittypes

    if (status === 2) {
      setVisitTypes(payload)
      setAllVisitTypes(payload)
    }
  }, [state.activevisittypes])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.pcpactivegroups

    if (status === 2) {
      setGroups(payload)
      setAllGroups(payload)
    }
  }, [state.pcpactivegroups])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.pcpactivenames

    if (status === 2 && payload && payload.length > 0) {
      setNames(payload)
      actions.setPcpactivenames({ status: 0, payload: null })
    } else if (status === 3) {
      setNames(null)
      actions.setPcpactivenames({ status: 0, payload: null })
    }
  }, [state.pcpactivenames])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      const charts = payload.filter(x => x.codetype === 'ChartStatus')
      const dxstatuses = payload.filter(x => x.codetype === 'QueryStatus')

      setStatuses(charts)
      setAllStatuses(charts)
      setDxStatuses(dxstatuses)
      setAllDxStatuses(dxstatuses)
    }
  }, [state.statuscodes])

  useEffect(() => {
    const { status, payload } = state.nonSuperUsers

    if (status === 2 && payload) {
      const { results } = payload
      setAllUsers(results)

      actions.setNonSuperUsers({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setNonSuperUsers({ status: 0, payload: null })
    }
  }, [state.nonSuperUsers])

  useEffect(() => {
    if (allUsers) {
      const user = allUsers.filter(x => x.email === auth.user?.email)

      const query = {
        ...queries,
        assigneduser__in: '',
        page: 1,
      }

      if (user.length > 0) {
        const { username } = user[0]
        query.assigneduser__in = username
      }

      setSelectedMember(query.assigneduser__in || [])
      setQueries(query)
    }
  }, [allUsers])

  // antd Table component's handleTableChange event function
  const onTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data
    setPagination(paginate)

    // Prepare Query Strings Filters
    const query = {
      ...queries,
      page: paginate.current,
      per_page: paginate.pageSize,
    }

    delete query.ordering

    if (sorter.columnKey && sorter.order) {
      query.ordering = `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`
    }

    setQueries(query)

    // Change URL
    // navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const onResultFilter = value => {
    const query = {
      ...queries,
      search: value,
      page: 1,
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const getAggre = (data, field, reference) => {
    const list = []

    data &&
      data.filter(p => {
        const r = reference?.filter(g => g[field] === p)

        if (r && r[0]) list.push(r[0])

        return p
      })

    return list || null
  }

  const handleGroupChange = value => {
    const v = value || null
    v && actions.getPcpactivenames({ pcpgrptaxid: value })
    setNames(null)

    const query = {
      ...queries,
      providergrouptin: value,
      pcpnpi: '',
      page: 1,
    }

    setQueries(query)
  }

  const handleNameChange = value => {
    const query = {
      ...queries,
      pcpnpi: value,
      page: 1,
    }
    setQueries(query)
  }

  const handleVisitTypeChange = value => {
    const query = {
      ...queries,
      typeid: value,
      page: 1,
    }
    setQueries(query)
  }

  const handleStatusChange = value => {
    const query = {
      ...queries,
      statusid: value,
      page: 1,
    }

    setQueries(query)
  }

  const handleDxStatusChange = value => {
    const query = {
      ...queries,
      dxstatus__in: value,
      page: 1,
    }

    setQueries(query)
  }

  const handleUserChange = value => {
    const v = value || null

    setSelectedMember(v)

    if (selectedMember.length > v.length) {
      setQueries({ ...queries, assigneduser__in: v.join(','), page: 1 })
    } else if (Object.keys(v).length === 0) {
      setQueries({ ...queries, assigneduser__in: '', page: 1 })
    }
  }

  const handleUserBlur = () => {
    const query = {
      ...queries,
      assigneduser__in: selectedMember.join(','),
      page: 1,
    }

    setQueries(query)
  }

  const handleDateRange = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      appointmentdate__gte: startDate,
      appointmentdate__lte: endDate,
      page: 1,
    }

    setQueries(query)
  }

  const handleModifiedDateRange = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      modifieddate__gte: startDate,
      modifieddate__lte: endDate,
      page: 1,
    }

    setQueries(query)
  }

  const clearFilters = () => {
    const query = {
      ...queries,
      providergrouptin: '',
      dxstatus__in: '',
      appointmentdate__gte: null,
      appointmentdate__lte: null,
      modifieddate__gte: null,
      modifieddate__lte: null,
      statusid: '',
      typeid: '',
      pcpnpi: '',
      page: 1,
    }
    setQueries(query)
  }

  const handleAssignedUserChange = input => {
    setAssignedUser(input)
  }

  const onAssignUser = () => {
    if (selectedRowKeys.length <= 0) {
      message.error('Please select charts to reassign')
      return
    }

    if (!assignedUser) {
      message.error('Please select a user to assign charts to')
      return
    }

    actions.updateMultipleMemberCharts({
      ids: selectedRowKeys,
      data: {
        assigneduser: assignedUser,
      },
    })
  }

  return children({
    state,
    columns,
    queries,
    listData,
    pagination,
    setT,
    onTableChange,
    onResultFilter,
    handleGroupChange,
    handleNameChange,
    handleStatusChange,
    handleDxStatusChange,
    handleUserChange,
    handleUserBlur,
    handleDateRange,
    handleModifiedDateRange,
    handleVisitTypeChange,
    t,
    groups,
    names,
    kpiCards,
    statuses,
    dxStatuses,
    users,
    selectedMember,
    visitTypes,
    clearFilters,
    rowSelection,
    allUsers,
    assignedUser,
    onAssignUser,
    handleAssignedUserChange,
  })
}

export default Container
