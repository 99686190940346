import { thunk, action } from 'easy-peasy'
import { listMemberchartsV2Api, getMemberchartV2Api } from '../../api/memberchartv2'
import * as Bluebird from 'bluebird'
import { updateMemberchartApi } from 'api/memberchart'

export default {
  // List Membercharts V2
  memberchartsv2: { status: 0, payload: null },
  setMemberchartsv2: action((state, payload = null) => {
    state.memberchartsv2 = payload
  }),
  getMemberchartsv2: thunk(async (actions, params = {}) => {
    actions.setMemberchartsv2({ status: 1, payload: null })

    listMemberchartsV2Api(params)
      .then(response => actions.setMemberchartsv2({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartsv2({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberchart V2
  memberchartv2: { status: 0, payload: null },
  setMemberchartV2: action((state, payload = null) => {
    state.memberchartv2 = payload
  }),
  getMemberchartV2: thunk(async (actions, id) => {
    actions.setMemberchartV2({ status: 1, payload: null })

    getMemberchartV2Api(id)
      .then(response => actions.setMemberchartV2({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartV2({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Multiple Memberchart
  updatedMultipleMemberCharts: { status: 0, payload: null },
  setUpdatedMultipleMemberCharts: action((state, payload = null) => {
    state.updatedMultipleMemberCharts = payload
  }),
  updateMultipleMemberCharts: thunk(async (actions, { ids, data }) => {
    actions.setUpdatedMultipleMemberCharts({ status: 1, payload: null })

    Bluebird.map(ids, id => {
      return updateMemberchartApi(id, data)
    })
      .then(response => {
        actions.setUpdatedMultipleMemberCharts({ status: 2, payload: response })
      })
      .catch(e => {
        actions.setUpdatedMultipleMembercharts({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        })
      })
  }),
}
