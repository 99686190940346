export const GET_MAIL_LIST = 'get_mail_list'
export const GET_FOLDER_LIST = 'get_folder_list'
export const GET_LABEL_LIST = 'get_label_list'
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer'
export const COMPOSE_MAIL = 'compose_mail'
export const GET_MAIL_DETAIL = 'get_mail_detail'
export const UPDATE_MAIL_FOLDER = 'update_mail_folders'
export const UPDATE_MAIL_LABEL = 'update_mail_label'
export const UPDATE_STARRED_STATUS = 'update_starred_status'
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail'
export const CHANGE_READ_STATUS = 'change_read_status'
export const GET_CONNECTION_LIST = 'get_connection_list'
export const NULLIFY_MAIL = 'nullify_mail'
