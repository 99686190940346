import { thunk, action } from 'easy-peasy'
import {
  listMemberenrollmentlatestrecordsApi,
  getMemberenrollmentlatestrecordApi,
} from '../../api/memberenrollmentlatestrecord'

export default {
  // List Memberenrollmentlatestrecords
  memberenrollmentlatestrecords: { status: 0, payload: null },
  setMemberenrollmentlatestrecords: action((state, payload = null) => {
    state.memberenrollmentlatestrecords = payload
  }),
  getMemberenrollmentlatestrecords: thunk(async (actions, params = {}) => {
    actions.setMemberenrollmentlatestrecords({ status: 1, payload: null })

    listMemberenrollmentlatestrecordsApi(params)
      .then(response =>
        actions.setMemberenrollmentlatestrecords({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setMemberenrollmentlatestrecords({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberenrollmentlatestrecord
  memberenrollmentlatestrecord: { status: 0, payload: null },
  setMemberenrollmentlatestrecord: action((state, payload = null) => {
    state.memberenrollmentlatestrecord = payload
  }),
  getMemberenrollmentlatestrecord: thunk(async (actions, id) => {
    actions.setMemberenrollmentlatestrecord({ status: 1, payload: null })

    getMemberenrollmentlatestrecordApi(id)
      .then(response =>
        actions.setMemberenrollmentlatestrecord({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setMemberenrollmentlatestrecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
