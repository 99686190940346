import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'
import { message, Form } from 'antd'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

const initialValues = {
  name: '',
}

const basePath = '/access-management/permissions'

const Container = ({ children }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    createdPermission: s.createdPermission,
    updatedPermission: s.updatedPermission,
    permissionGroups: s.permissionGroups,
    permission: s.permission,
  }))

  const actions = useStoreActions(a => ({
    setPermission: a.setPermission,
    setCreatedPermission: a.setCreatedPermission,
    setUpdatedPermission: a.setUpdatedPermission,
    createPermission: a.createPermission,
    updatePermission: a.updatePermission,
    getPermission: a.getPermission,
    getPermissionGroups: a.getPermissionGroups,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)

  const navigate = useNavigate()
  const auth = useAuthUser()

  const onFinish = values => {
    if (id === 'new') {
      actions.createPermission(values)
    } else {
      actions.updatePermission({ id, ...values })
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    if (!has(auth.user, [p.PERMISSIONS_RW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [])

  // On Load
  useEffect(() => {
    if (id !== 'new') {
      actions.getPermission(id)
    }

    actions.getPermissionGroups({ per_page: 5000 })

    // Reset retrieve state on unmount
    return () => {
      actions.setPermission({ status: 0, payload: null })
    }
  }, [])

  // Side effect of state.permission
  useEffect(() => {
    const { status, payload } = state.permission

    if (status === 2) {
      // Set initial values of form
      setFormValues({
        name: payload.name,
        code: payload.code,
        description: payload.description,
        permission_group: payload.permission_group,
      })
    }
  }, [state.permission])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  // Side effect of createdPermission
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdPermission

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedPermission({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully added new record')
      navigate(basePath)
      actions.setCreatedPermission({ status: 0, payload: null })
    }
  }, [state.createdPermission])

  // Side effect of updatedPermission
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedPermission

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedPermission({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully updated the record')
      navigate(basePath)
      actions.setUpdatedPermission({ status: 0, payload: null })
    }
  }, [state.updatedPermission])

  useEffect(() => {
    setLoading(
      state.permission.status === 1 ||
        state.createdPermission.status === 1 ||
        state.updatedPermission.status === 1,
    )
  }, [state.permission, state.createdPermission, state.updatedPermission])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    id,
    loading,
    basePath,
  })
}

export default Container
