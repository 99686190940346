import React from 'react'
import { Alert } from 'antd'

const visible = false

export default function NotificationBar() {
  const onClose = () => {
    // eslint-disable-next-line no-console
    console.log('I was closed.')
  }

  return (
    visible && (
      <Alert
        className="header-alert"
        message="Welcome!"
        type="warning"
        closable
        onClose={onClose}
      />
    )
  )
}
