import { thunk, action } from 'easy-peasy'
import { listPcpgroupsApi, getPcpgroupsApi } from '../../api/pcpgroups'

export default {
  withpcps: null,
  setWithPcps: action((state, payload = null) => {
    state.withpcps = payload
  }),

  // List Pcpgroups
  pcpgroups: { status: 0, payload: null },
  setPcpgroups: action((state, payload = null) => {
    state.pcpgroups = payload
  }),
  getPcpgroups: thunk(async (actions, params = {}) => {
    actions.setPcpgroups({ status: 1, payload: null })

    listPcpgroupsApi(params)
      .then(response => actions.setPcpgroups({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpgroups({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve pcpgroup
  pcpgroup: { status: 0, payload: null },
  setPcpgroup: action((state, payload = null) => {
    state.pcpgroup = payload
  }),
  getPcpgroup: thunk(async (actions, id) => {
    actions.setPcpgroup({ status: 1, payload: null })

    getPcpgroupsApi(id)
      .then(response => actions.setPcpgroup({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpgroup({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
