import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { sizePerPage, p } from '../../../../shared/constants/AppConst'
import { message } from 'antd'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'

import tableColumns from './columns'
import qs from 'query-string'

const basePath = '/access-management/permissions'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    permissions: s.permissions,
    deletedPermission: s.deletedPermission,
  }))
  const actions = useStoreActions(a => ({
    getPermissions: a.getPermissions,
    deletePermission: a.deletePermission,
    setDeletedPermission: a.setDeletedPermission,
  }))

  // Local states
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
  })

  // Columns
  const columns = tableColumns(actions, auth.user, basePath)

  useEffect(() => {
    if (!has(auth.user, [p.PERMISSIONS_RO, p.PERMISSIONS_RW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(location.search)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    actions.getPermissions(queries)
  }, [queries])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.permissions

    if (status === 2) {
      setListData(payload.results)

      setPagination(prev => ({
        ...prev,
        total: payload.count,
      }))
    }
  }, [state.permissions])

  // Side effect of Delete
  useEffect(() => {
    const { status, payload } = state.deletedPermission

    if (status === 2) {
      // Reload list
      actions.getPermissions(queries)

      // Show show success message
      message.success('Succefully deleted')

      // Reset State
      actions.setDeletedPermission({ status: 0, payload: null })
    } else if (status === 3) {
      // Show error message
      message.error(payload.details || 'Oops, something went wrong')

      // Reset State
      actions.setDeletedPermission({ status: 0, payload: null })
    }
  }, [state.deletedPermission])

  // antd Table component's handleTableChange event function
  const onTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data
    setPagination(paginate)

    // Prepare Query Strings Filters
    const query = {
      ...queries,
      page: paginate.current,
    }
    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  // On Search
  const onSearch = value => {
    // Prepare Query Strings Filters
    const query = {
      ...queries,
      search: value,
      page: 1,
    }
    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  return children({
    state,
    columns,
    queries,
    listData,
    pagination,
    onSearch,
    onTableChange,
    basePath,
    auth,
  })
}

export default Container
