import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message, Form } from 'antd'

const initialValues = {
  filetypecode: '',
  filenotes: '',
}

const Container = ({ children, chartid, fileid }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    createdFile: s.createdFile,
    updatedmemberchartfile: s.updatedmemberchartfile,
    createdMemberchartsfile: s.createdMemberchartsfile,
    memberchartsfiles: s.memberchartsfiles,
    memberchartfile: s.memberchartfile,
  }))

  const actions = useStoreActions(a => ({
    createMemberchartactivityrecord: a.createMemberchartactivityrecord,
    createFile: a.createFile,
    setCreatedFile: a.setCreatedFile,
    createMemberchartsfile: a.createMemberchartsfile,
    setCreatedMemberchartsfile: a.setCreatedMemberchartsfile,
    setMemberchartsfile: a.setMemberchartsfile,
    setUpdatedMemberchartfile: a.setUpdatedMemberchartfile,
    updateMemberchartfile: a.updateMemberchartfile,
    getMemberchartsfiles: a.getMemberchartsfiles,
    getMemberchartfile: a.getMemberchartfile,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [formValues, setFormValues] = useState(initialValues)
  const [statuses, setStatuses] = useState(null)

  // Upload Data
  const [fD, setFD] = useState({})
  const [result, setResult] = useState([])
  const [fileList, setFileList] = useState([])

  const isEmptyObject = obj => {
    return Object.keys(obj).length === 0
  }

  const beforeFileUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isLt2M) {
      message.error('File size must be smaller than 2MB!')
    }

    return isLt2M
  }

  const handleFileRemove = file => {
    // const newFileList = fileList.filter(i => i.uid !== file.uid)
    setFileList([])
  }

  const handleFileUpload = ({ file, onSuccess }) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('reference_id', file.uid)
    setResult(r => [...r, { id: file.uid, onSuccess }])
    setFD({ formData })
  }

  const onFinish = values => {
    if (fileid) {
      actions.updateMemberchartfile({ id: fileid, ...values })
    } else {
      values.chartid = chartid
      values.fileid = fileList && fileList[0] ? fileList[0].uid : null

      values && actions.createMemberchartsfile(values)
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      setStatuses(payload.filter(x => x.codetype === 'ChartFileType'))
    }
  }, [state.statuscodes])

  useEffect(() => {
    if (!isEmptyObject(fD)) {
      actions.createFile(fD.formData)
    }
  }, [fD])

  useEffect(() => {
    const { status, payload } = state.createdFile

    if (status === 2) {
      const imgName = payload.file.split('/').pop(-1)
      const current = result.filter(i => i.id === payload.reference_id)

      setFileList(f => [
        ...f,
        {
          uid: payload.id,
          name: imgName,
          status: 'done',
          url: payload.file,
        },
      ])

      current[0].onSuccess('done')
      actions.setCreatedFile({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setCreatedFile({ status: 0, payload: null })
    }
  }, [state.createdFile])

  // Side effect of createdNonSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdMemberchartsfile

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedMemberchartsfile({ status: 0, payload: null })
    } else if (status === 2) {
      form.resetFields()
      setFileList([])
      setResult([])
      setFD({})
      message.success('Successfully added new record')
      actions.setCreatedMemberchartsfile({ status: 0, payload: null })
      actions.getMemberchartsfiles({ chartid })
    }
  }, [state.createdMemberchartsfile])

  // Side effect of createdNonSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedmemberchartfile

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedMemberchartfile({ status: 0, payload: null })
    } else if (status === 2) {
      form.resetFields()
      setFileList([])
      setResult([])
      setFD({})
      message.success('Successfully updated record')
      actions.setUpdatedMemberchartfile({ status: 0, payload: null })
      actions.getMemberchartsfiles({ chartid })
    }
  }, [state.updatedmemberchartfile])

  useEffect(() => {
    if (fileid) {
      actions.getMemberchartfile(fileid)
    } else {
      setTimeout(() => {
        setFormValues({
          filetypecode: '',
          filenotes: '',
        })
      })
    }
  }, [fileid])

  // Side effect of state.memberchartfile
  useEffect(() => {
    const { status, payload } = state.memberchartfile

    if (status === 2) {
      // Set initial values of form

      setFormValues({
        filetypecode: payload.filetypecode,
        filenotes: payload.filenotes,
      })
    }
  }, [state.memberchartfile])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    statuses,
    fileList,
    beforeFileUpload,
    handleFileUpload,
    handleFileRemove,
  })
}

export default Container
