import { thunk, action } from 'easy-peasy'
import { listEducationtopicsApi } from '../../api/educationtopics'

export default {
  // List Education Topics
  educationtopics: { status: 0, payload: null },
  setEducationtopics: action((state, payload = null) => {
    state.educationtopics = payload
  }),
  getEducationtopics: thunk(async (actions, params = {}) => {
    actions.setEducationtopics({ status: 1, payload: null })

    listEducationtopicsApi(params)
      .then(response => actions.setEducationtopics({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setEducationtopics({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),
}
