import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import moment from 'moment'
import * as _ from 'lodash'
import {
  sizePerPage,
  p,
  CHART_STATUS_PENDING,
  CHART_STATUS_QUERY_PENDING,
} from '../../../../shared/constants/AppConst'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'

import tableColumns from './columns'
import qs from 'query-string'

const basePath = '/landing-page/fieldcoderdashboard'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    pcpactivegroups: s.pcpactivegroups,
    pcpactivenames: s.pcpactivenames,
    statuscodes: s.statuscodes,
    nonSuperUsers: s.nonSuperUsers,
    memberchartsv2: s.memberchartsv2,
    activevisittypes: s.activevisittypes,
  }))
  const actions = useStoreActions(a => ({
    getPcpactivegroups: a.getPcpactivegroups,
    getPcpactivenames: a.getPcpactivenames,
    setPcpactivenames: a.setPcpactivenames,
    getStatuscodes: a.getStatuscodes,
    getNonSuperUsers: a.getNonSuperUsers,
    setNonSuperUsers: a.setNonSuperUsers,
    getMemberchartsv2: a.getMemberchartsv2,
    getActiveVisitTypes: a.getActiveVisitTypes,
  }))

  // Local states
  const [selectedMember, setSelectedMember] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([
    CHART_STATUS_PENDING,
    CHART_STATUS_QUERY_PENDING,
  ])
  const [selectedDxStatus, setSelectedDxStatus] = useState([])
  const [selectedVisitTypes, setSelectedVisitTypes] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedProviders, setSelectedProviders] = useState([])

  const [t, setT] = useState(null)
  const [users, setUsers] = useState(null)
  const [allUsers, setAllUsers] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [allStatuses, setAllStatuses] = useState(null)
  const [dxStatuses, setDxStatuses] = useState(null)
  const [allDxStatuses, setAllDxStatuses] = useState(null)
  const [kpiCards, setKpiCards] = useState(null)
  const [groups, setGroups] = useState(null)
  const [allGroups, setAllGroups] = useState(null)
  const [names, setNames] = useState(null)
  const [allVisitTypes, setAllVisitTypes] = useState(null)
  const [visitTypes, setVisitTypes] = useState(null)

  // Setter of queries
  const [visitTypeFilter, setVisitTypeFilter] = useState(null)
  const [nameFilter, setNameFilter] = useState()
  const [groupFilter, setGroupFilter] = useState(null)

  // const [aggre, setAggre] = useState(null)
  const paginationDefaults = {
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  }
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    ...paginationDefaults,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
    with_created_chart: 1,
  })

  // Columns
  const columns = tableColumns()

  useEffect(() => {
    actions.getActiveVisitTypes()
    actions.getPcpactivegroups()
    actions.getStatuscodes()
    actions.getNonSuperUsers({ with_created_chart: 1, per_page: 9999 })
    handleStatusChange([CHART_STATUS_PENDING, CHART_STATUS_QUERY_PENDING])
  }, [])

  useEffect(() => {
    if (!has(auth.user, [p.FD_VIEW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(`${location.search}`)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
        ...paginationDefaults,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    if (
      queries?.providergrouptin__in ||
      queries?.assigneduser__in ||
      queries?.assigneduser__in === '' ||
      queries?.appointmentdate__gte ||
      queries?.appointmentdate__lte ||
      queries?.postreviewcompleteddate__gte ||
      queries?.postreviewcompleteddate__lte ||
      queries?.modifieddate__gte ||
      queries?.modifieddate__lte ||
      queries?.auditdate__gte ||
      queries?.auditdate__lte ||
      queries?.audit_by ||
      queries?.dxstatus__in
    ) {
      const queriesCopy = _.cloneDeep(queries)

      if (queriesCopy.appointmentdate__lte) {
        queriesCopy.appointmentdate__lte = queriesCopy.appointmentdate__lte + ' 23:59:59'
      }

      if (queriesCopy.postreviewcompleteddate__lte) {
        queriesCopy.postreviewcompleteddate__lte =
          queriesCopy.postreviewcompleteddate__lte + ' 23:59:59'
      }

      if (queriesCopy.modifieddate__lte) {
        queriesCopy.modifieddate__lte = queriesCopy.modifieddate__lte + ' 23:59:59'
      }

      const pcpgrptaxid = queries.providergrouptin__in || null

      pcpgrptaxid && actions.getPcpactivenames({ pcpgrptaxid__in: pcpgrptaxid })
      actions.getMemberchartsv2(queriesCopy)
    }
  }, [queries])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberchartsv2

    if (status === 2) {
      const { aggregates, results } = payload

      const currentUsers = getAggre(aggregates?.assigneduser, 'username', allUsers)
      const currentStatuses = getAggre(aggregates?.statusid, 'statuscode', allStatuses)
      const dxStatus = getAggre(aggregates?.dxstatus, 'statuscode', allDxStatuses)
      const currentGroups = getAggre(aggregates?.providergrouptin, 'pcpgrptaxid', allGroups)
      const currentVisitTypes = getAggre(aggregates?.typeid, 'typeid', allVisitTypes)

      setUsers(currentUsers)
      setStatuses(currentStatuses)
      setDxStatuses(dxStatus)
      setGroups(currentGroups)

      setVisitTypes(currentVisitTypes)

      // setAggre(aggregates)
      setKpiCards(payload.counts.statuses)
      setListData(results)

      setPagination(prev => ({
        ...prev,
        total: payload.count,
        ...paginationDefaults,
      }))
    }
  }, [state.memberchartsv2])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.activevisittypes

    if (status === 2) {
      setVisitTypes(payload)
      setAllVisitTypes(payload)
    }
  }, [state.activevisittypes])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.pcpactivegroups

    if (status === 2) {
      setGroups(payload)
      setAllGroups(payload)
    }
  }, [state.pcpactivegroups])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.pcpactivenames

    if (status === 2 && payload && payload.length > 0) {
      setNames(payload)
      actions.setPcpactivenames({ status: 0, payload: null })
    } else if (status === 3) {
      setNames(null)
      actions.setPcpactivenames({ status: 0, payload: null })
    }
  }, [state.pcpactivenames])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      const charts = payload.filter(x => x.codetype === 'ChartStatus')
      const dxstatuses = payload.filter(x => x.codetype === 'QueryStatus')

      // const defaultDxStatuses = dxstatuses
      //   .filter(dxstatus => ['open', 'pending'].includes(dxstatus.codedescription.toLowerCase()))
      //   .map(dxstatus => dxstatus.statuscode)
      //   .join(',')

      setQueries(prev => ({
        ...prev,
        dxstatus__in: '',
      }))

      setStatuses(charts)
      setAllStatuses(charts)
      setDxStatuses(dxstatuses)
      setAllDxStatuses(dxstatuses)
    }
  }, [state.statuscodes])

  useEffect(() => {
    const { status, payload } = state.nonSuperUsers

    if (status === 2 && payload) {
      const { results } = payload
      setAllUsers(results)

      actions.setNonSuperUsers({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setNonSuperUsers({ status: 0, payload: null })
    }
  }, [state.nonSuperUsers])

  useEffect(() => {
    if (allUsers) {
      const user = allUsers.filter(x => x.email === auth.user?.email)

      const query = {
        ...queries,
        assigneduser__in: '',
        page: 1,
      }

      if (user.length > 0) {
        const { username } = user[0]
        query.assigneduser__in = username
      }

      setSelectedMember(query.assigneduser__in || [])
      setQueries(query)
    }
  }, [allUsers])

  // antd Table component's handleTableChange event function
  const onTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data

    setPagination({
      ...paginate,
      ...paginationDefaults,
    })

    // Prepare Query Strings Filters
    const query = {
      ...queries,
      page: paginate.current,
    }

    delete query.ordering

    if (sorter.columnKey && sorter.order) {
      query.ordering = `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`
    }

    if (paginate?.pageSize) {
      // query.page = 1
      query.per_page = paginate.pageSize
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const onResultFilter = value => {
    const query = {
      ...queries,
      search: value,
      page: 1,
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const getAggre = (data, field, reference) => {
    const list = []

    data &&
      data.filter(p => {
        const r = reference?.filter(g => g[field] === p)

        if (r && r[0]) list.push(r[0])

        return p
      })

    return list || null
  }

  const handleGroupChange = value => {
    setSelectedGroups(value)

    const pcpgrptaxids = value.filter(val => !!val).join(',')

    const query = {
      ...queries,
      providergrouptin__in: pcpgrptaxids,
      pcpnpi__in: '',
      page: 1,
    }

    setGroupFilter(query)
  }

  const handleGroupBlur = () => {
    if (groupFilter) {
      actions.getPcpactivenames({ pcpgrptaxid__in: groupFilter.pcpgrptaxids })

      setNames(null)
      setQueries(groupFilter)
    }
  }

  const handleGroupClear = () => {
    handleGroupChange([])
    handleGroupBlur()
  }

  const handleNameChange = value => {
    setSelectedProviders(value)

    const query = {
      ...queries,
      pcpnpi__in: value.filter(val => !!val).join(','),
      page: 1,
    }
    setNameFilter(query)
  }

  const handleNameBlur = () => {
    if (nameFilter) {
      setQueries(nameFilter)
    }
  }

  const handleNameClear = () => {
    handleNameChange([])
    handleNameBlur()
  }

  const handleVisitTypeChange = value => {
    setSelectedVisitTypes(value)

    const query = {
      ...queries,
      typeid__in: value.filter(val => !!val).join(','),
      page: 1,
    }
    setVisitTypeFilter(query)
  }

  const handleVisitTypeBlur = () => {
    if (visitTypeFilter) {
      setQueries(visitTypeFilter)
    }
  }

  const handleVisitTypeClear = () => {
    handleVisitTypeChange([])
    handleVisitTypeBlur()
  }

  // Status Change
  const handleStatusChange = value => {
    const v = value || null

    // update reload everytime we remove

    if (v.length <= selectedStatus.length) {
      setQueries({ ...queries, statusid__in: v.join(','), page: 1 })
    } else if (Object.keys(v).length === 0) {
      setQueries({ ...queries, statusid__in: '', page: 1 })
    }

    setSelectedStatus(v)
  }

  // Status Blur
  const handleStatusBlur = () => {
    const query = {
      ...queries,
      statusid__in: selectedStatus.join(','),
      page: 1,
    }

    setQueries(query)
  }

  // DXStatus Change
  const handleDxStatusChange = value => {
    const v = value || null

    setSelectedDxStatus(v)

    if (selectedDxStatus.length > v.length) {
      setQueries({ ...queries, dxstatus__in: v.join(','), page: 1 })
    } else if (Object.keys(v).length === 0) {
      setQueries({ ...queries, dxstatus__in: '', page: 1 })
    }
  }

  // Status Blur
  const handleDxStatusBlur = () => {
    const query = {
      ...queries,
      dxstatus__in: selectedDxStatus.join(','),
      page: 1,
    }

    setQueries(query)
  }

  const handleUserChange = value => {
    const v = value || null

    setSelectedMember(v)

    if (selectedMember.length > v.length) {
      setQueries({ ...queries, assigneduser__in: v.join(','), page: 1 })
    } else if (Object.keys(v).length === 0) {
      setQueries({ ...queries, assigneduser__in: '', page: 1 })
    }
  }

  const handleUserBlur = () => {
    const query = {
      ...queries,
      assigneduser__in: selectedMember.join(','),
      page: 1,
    }

    setQueries(query)
  }

  const handleDateRange = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      appointmentdate__gte: startDate,
      appointmentdate__lte: endDate,
      page: 1,
    }

    setQueries(query)
  }

  const handlePostReviewDate = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      postreviewcompleteddate__gte: startDate,
      postreviewcompleteddate__lte: endDate,
      page: 1,
    }

    setQueries(query)
  }

  const handleModifiedDateRange = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      modifieddate__gte: startDate,
      modifieddate__lte: endDate,
      page: 1,
    }

    setQueries(query)
  }

  const handleAuditDateRange = date => {
    const startDate = date ? moment(date[0]).format('YYYY-MM-DD') : ''
    const endDate = date ? moment(date[1]).format('YYYY-MM-DD') : ''
    const query = {
      ...queries,
      auditdate__gte: startDate,
      auditdate__lte: endDate,
      page: 1,
    }

    setQueries(query)
  }

  const handleAuditBy = value => {
    const query = {
      ...queries,
      audit_by: value,
      page: 1,
    }

    setQueries(query)
  }

  const clearFilters = () => {
    setSelectedMember([])
    setSelectedStatus([])
    setSelectedDxStatus([])
    setSelectedVisitTypes([])
    setSelectedGroups([])
    setSelectedProviders([])
    setNames(null)

    const query = {
      providergrouptin: '',
      dxstatus__in: '',
      appointmentdate__gte: null,
      appointmentdate__lte: null,
      postreviewcompleteddate__gte: null,
      postreviewcompleteddate__lte: null,
      modifieddate__gte: null,
      modifieddate__lte: null,
      auditdate__gte: null,
      auditdate__lte: null,
      audit_by: '',
      statusid__in: '',
      assigneduser__in: '',
      typeid__in: '',
      pcpnpi__in: '',
      page: 1,
      per_page: 10,
      with_created_chart: 1,
    }

    setQueries(query)
  }

  return children({
    state,
    columns,
    queries,
    listData,
    pagination,
    setT,
    onTableChange,
    onResultFilter,
    handleGroupChange,
    handleGroupBlur,
    handleGroupClear,
    handleNameChange,
    handleNameBlur,
    handleNameClear,
    handleStatusChange,
    handleStatusBlur,
    handleDxStatusChange,
    handleDxStatusBlur,
    handleUserChange,
    handleUserBlur,
    handleDateRange,
    handlePostReviewDate,
    handleModifiedDateRange,
    handleAuditDateRange,
    handleAuditBy,
    handleVisitTypeChange,
    handleVisitTypeBlur,
    handleVisitTypeClear,
    t,
    groups,
    names,
    kpiCards,
    statuses,
    dxStatuses,
    users,
    allUsers,
    selectedMember,
    visitTypes,
    clearFilters,
    allDxStatuses,
    selectedStatus,
    selectedDxStatus,
    selectedVisitTypes,
    selectedGroups,
    selectedProviders,
  })
}

export default Container
