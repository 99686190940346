import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message, Form } from 'antd'
import moment from 'moment'
import {
  HCC_STATUS_OPEN,
  ICD_STATUS_PCF_DX,
  ICD_NEW_STATUS_RECAPTURE,
  HCC_SECTION_SUSPECTED,
  ICD_NEW_STATUS_NEW,
} from '../../../../../../shared/constants/AppConst'

import { createMembercharticdcodesApi } from '../../../../../../api/memberchartsicdcode'

const initialValues = {
  typeid: '',
  statusid: '',
  followupdate: '',
  appointmentdate: '',
  providergrouptin: '',
  pcpnpi: '',
  sourceid: '',
  assigneduser: '',
  auditby: '',
  auditdate: '',
}

const Container = ({ children, chartid }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    updatedMemberchart: s.updatedMemberchart,
    createdMemberchart: s.createdMemberchart,
    pcpgroups: s.pcpgroups,
    pcpnames: s.pcpnames,
    memberchart: s.memberchart,
    nonSuperUsers: s.nonSuperUsers,
    memberhcccurrents: s.memberhcccurrents,
  }))

  const actions = useStoreActions(a => ({
    updateMemberchart: a.updateMemberchart,
    createMemberchart: a.createMemberchart,
    setUpdatedMemberchart: a.setUpdatedMemberchart,
    setCreatedMemberchart: a.setCreatedMemberchart,
    getMembercharts: a.getMembercharts,
    getPcpgroups: a.getPcpgroups,
    getPcpnames: a.getPcpnames,
    setPcpnames: a.setPcpnames,
    getMemberchart: a.getMemberchart,
    getNonSuperUsers: a.getNonSuperUsers,
    getMemberhcccurrents: a.getMemberhcccurrents,
    getMemberchartsactivityrecords: a.getMemberchartsactivityrecords,
    createMembercharticdcode: a.createMembercharticdcode,
  }))

  const [, setSelectedStatuses] = useState(null)

  // Form Hook
  const [form] = Form.useForm()

  const [formValues, setFormValues] = useState(initialValues)
  const [types, setTypes] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [sources, setSources] = useState(null)
  const [groups, setGroups] = useState(null)
  const [names, setNames] = useState(null)
  const [t, setT] = useState(null)

  const onFinish = values => {
    values.internalmemberid = id

    values.modifieddate = moment(values.modifieddate)
      .set({ hour: 0, minute: 0, second: 0 })
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')

    values.appointmentdate = moment(values.appointmentdate)
    // eslint-disable-next-line no-console
    console.log('auditdate', values.appointmentdate, '-')
    // eslint-disable-next-line no-console
    console.log('auditdate is blank', values.auditdate === '')
    values.auditdate = values.auditdate === '' ? null : values.auditdate

    if (chartid) {
      actions.updateMemberchart({ id: chartid, ...values })
    } else {
      values && actions.createMemberchart(values)
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  // On Load
  useEffect(() => {
    if (state.memberhcccurrents.status === 0) {
      actions.getMemberhcccurrents({ internalmemberid: id })
      setSelectedStatuses([HCC_STATUS_OPEN])
    }

    // Reset retrieve state on unmount
    actions.getPcpgroups({ per_page: 99999 })

    return () => {
      actions.setCreatedMemberchart({ status: 0, payload: null })
    }
  }, [])

  useEffect(() => {
    if (chartid) {
      actions.getMemberchart(chartid)
    } else {
      setTimeout(() => {
        setFormValues(initialValues)
      })
    }
  }, [chartid])

  // Side effect of state.memberchart
  useEffect(() => {
    const { status, payload } = state.memberchart

    if (status === 2) {
      // Set initial values of form

      actions.getPcpnames({ providergrouptin: payload.providergrouptin, per_page: 99999 })

      setFormValues({
        typeid: payload.typeid,
        statusid: payload.statusid,
        modifieddate: moment(payload.modifieddate),
        appointmentdate: moment(payload.appointmentdate, 'YYYY-MM-DD'),
        providergrouptin: payload.providergrouptin,
        pcpnpi: payload.pcpnpi,
        sourceid: payload.sourceid,
        assigneduser: payload.assigneduser,
        auditby: payload.auditby,
        auditdate: moment(payload.auditdate),
      })
    }
  }, [state.memberchart])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      setStatuses(payload.filter(x => x.codetype === 'ChartStatus'))
      setTypes(payload.filter(x => x.codetype === 'ChartType'))
      setSources(payload.filter(x => x.codetype === 'ChartSource'))
    }
  }, [state.statuscodes])

  // Side effect of createdNonSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdMemberchart

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedMemberchart({ status: 0, payload: null })
    } else if (status === 2) {
      form.resetFields()

      if (state.memberhcccurrents.payload) {
        state.memberhcccurrents.payload.forEach(hcc => {
          if (hcc.status === HCC_STATUS_OPEN) {
            const values = {}
            values.chartid = payload.chartid
            values.icd10code = hcc.icd10
            values.hcccode = hcc.hcc
            values.hcccodedescription = hcc.hccdescription
            values.codestatusid = ICD_STATUS_PCF_DX
            values.codenewid = ICD_NEW_STATUS_RECAPTURE
            values.querystatusid = '' // QUERY_STATUS_QUERY_PENDING

            if (hcc.section === HCC_SECTION_SUSPECTED) {
              values.codenewid = ICD_NEW_STATUS_NEW
            }

            createMembercharticdcodesApi(values)
          }
        })
      }

      message.success('Successfully added new record')
      actions.setCreatedMemberchart({ status: 0, payload: null })
      actions.getMembercharts({ internalmemberid: id })
    }
  }, [state.createdMemberchart])

  // Side effect of updatedMemberchart
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedMemberchart

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedMemberchart({ status: 0, payload: null })
    } else if (status === 2) {
      form.resetFields()
      message.success('Successfully updated the record')
      actions.setUpdatedMemberchart({ status: 0, payload: null })
      actions.getMembercharts({ internalmemberid: id })
      actions.getMemberchartsactivityrecords({ chartid })
    }
  }, [state.updatedMemberchart])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.pcpgroups

    if (status === 2) {
      setGroups(payload?.results)
    }
  }, [state.pcpgroups])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.pcpnames

    if (status === 2) {
      setNames(payload?.results)
      actions.setPcpnames({ status: 0, payload: null })
    } else if (status === 3) {
      setNames(null)
      actions.setPcpnames({ status: 0, payload: null })
    }
  }, [state.pcpnames])

  const handleGroupChange = value => {
    actions.getPcpnames({ providergrouptin: value, per_page: 99999 })
    setNames(null)
    setFormValues(prev => ({ ...prev, pcpnpi: null }))
  }

  const handleUserChange = value => {
    actions.getNonSuperUsers()

    // if (typeof value === 'undefined') {
    //   actions.getNonSuperUsers()
    // }
  }

  // User search with debounce
  const handleUserSearch = e => {
    if (t) clearTimeout(t)

    setT(
      setTimeout(() => {
        actions.getNonSuperUsers({ search: e })
      }, 500),
    )
  }

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    statuses,
    types,
    sources,
    groups,
    names,
    handleGroupChange,
    chartid,
    handleUserChange,
    handleUserSearch,
  })
}

export default Container
