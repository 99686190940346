/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewComponent from './container'
import { Select } from 'antd'

const View = ({ value, onChange, returnDuplicates }) => (
  <ViewComponent value={value} onChange={onChange} returnDuplicates={returnDuplicates}>
    {({ value, data, currentValue, handleSearch, handleChange }) => (
      <div>
        <Select
          showSearch
          value={currentValue}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          options={(data || []).map(d => ({
            value: d.id,
            label: d.icd10,
          }))}
          style={{ width: '100%' }}
          notFoundContent={null}
        />
      </div>
    )}
  </ViewComponent>
)

export default View

View.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  returnDuplicates: PropTypes.bool,
}
