import { thunk, action } from 'easy-peasy'
import { listQualitygapsApi } from '../../api/qualitygap'

export default {
  // List Qualitygaps
  qualitygaps: { status: 0, payload: null },
  setQualitygaps: action((state, payload = null) => {
    state.qualitygaps = payload
  }),
  getQualitygaps: thunk(async (actions, params = {}) => {
    actions.setQualitygaps({ status: 1, payload: null })

    listQualitygapsApi(params)
      .then(response => actions.setQualitygaps({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setQualitygaps({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),
}
