import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'
import { message, Form } from 'antd'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  password: '',
}

const basePath = '/access-management/superusers'

const Container = ({ children }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    createdSuperUser: s.createdSuperUser,
    updatedSuperUser: s.updatedSuperUser,
    superUser: s.superUser,
  }))

  const actions = useStoreActions(a => ({
    setSuperUser: a.setSuperUser,
    setCreatedSuperUser: a.setCreatedSuperUser,
    setUpdatedSuperUser: a.setUpdatedSuperUser,
    createSuperUser: a.createSuperUser,
    updateSuperUser: a.updateSuperUser,
    getSuperUser: a.getSuperUser,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)

  const navigate = useNavigate()
  const auth = useAuthUser()

  const onFinish = values => {
    if (!values.password) {
      delete values.password
    }

    if (id === 'new') {
      actions.createSuperUser(values)
    } else {
      actions.updateSuperUser({ id, ...values })
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    if (!has(auth.user, [p.SUPER_USER_RW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [])

  // On Load
  useEffect(() => {
    if (id !== 'new') {
      actions.getSuperUser(id)
    }

    // Reset retrieve state on unmount
    return () => {
      actions.setSuperUser({ status: 0, payload: null })
    }
  }, [])

  // Side effect of state.superUser
  useEffect(() => {
    const { status, payload } = state.superUser

    if (status === 2) {
      // Set initial values of form
      setFormValues({
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        username: payload.username,
        password: payload.password,
      })
    }
  }, [state.superUser])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  // Side effect of createdSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdSuperUser

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedSuperUser({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully added new record')
      navigate(basePath)
      actions.setCreatedSuperUser({ status: 0, payload: null })
    }
  }, [state.createdSuperUser])

  // Side effect of updatedSuperUser
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedSuperUser

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedSuperUser({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully updated the record')
      navigate(basePath)
      actions.setUpdatedSuperUser({ status: 0, payload: null })
    }
  }, [state.updatedSuperUser])

  useEffect(() => {
    setLoading(
      state.superUser.status === 1 ||
        state.createdSuperUser.status === 1 ||
        state.updatedSuperUser.status === 1,
    )
  }, [state.superUser, state.createdSuperUser, state.updatedSuperUser])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    id,
    loading,
    basePath,
  })
}

export default Container
