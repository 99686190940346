import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'
import moment from 'moment'

import tableColumns from './columns'

const Container = ({ children, id }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    nonSuperUsers: s.nonSuperUsers,
    memberqualityrecords: s.memberqualityrecords,
    updatedMemberqualityrecord: s.updatedMemberqualityrecord,
  }))
  const actions = useStoreActions(a => ({
    getNonSuperUsers: a.getNonSuperUsers,
    setNonSuperUsers: a.setNonSuperUsers,
    getMemberqualityrecords: a.getMemberqualityrecords,
    updateMemberqualityrecord: a.updateMemberqualityrecord,
    setUpdatedMemberqualityrecord: a.setUpdatedMemberqualityrecord,
  }))

  // Local states
  const [qualityGapId, setQualityGapId] = useState(null)
  const [users, setUsers] = useState([])
  const [listData, setListData] = useState([])
  const [columns, setColumns] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState({})
  const [viewDocument, setViewDocument] = useState(false)

  const clearFilters = () => {
    setFilteredInfo({})
  }

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
  }

  const handleUpdate = (record, field, value) => {
    const { id } = record
    let data = { id, [field]: value }

    if (field === 'compliant') {
      data = { id, [field]: value, requestdate: moment().format('YYYY-MM-DD') }
    }

    actions.updateMemberqualityrecord(data)
  }

  const handleFilters = payload => {
    const fields = ['measureid', 'measurenameshort']
    const filters = { measureid: [], measurenameshort: [] }

    payload.forEach(p => {
      fields.forEach(f => {
        if (filters[f].filter(x => x.value === p[f]).length === 0) {
          filters[f].push({ text: p[f], value: p[f] })
        }
      })
    })

    return filters
  }

  useEffect(() => {
    actions.getNonSuperUsers({ per_page: 9999 })
    actions.getMemberqualityrecords({ internalmemberid: id, per_page: 1000 })
  }, [])

  useEffect(() => {
    const { status, payload } = state.nonSuperUsers

    if (status === 2 && payload) {
      const { results } = payload
      setUsers(results)

      actions.setNonSuperUsers({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setNonSuperUsers({ status: 0, payload: null })
    }
  }, [state.nonSuperUsers])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberqualityrecords

    if (status === 2 && users) {
      // Create choices of filters in column headers
      const filters = handleFilters(payload)

      // Set created columns
      setColumns(
        tableColumns(filters, filteredInfo, users, handleUpdate, setQualityGapId, setViewDocument),
      )

      // Set Table Data
      setListData(payload)
    }
  }, [state.memberqualityrecords, filteredInfo, users])

  useEffect(() => {
    const { status } = state.updatedMemberqualityrecord

    if (status === 2) {
      setQualityGapId(null)
      setViewDocument(false)
      message.success('Successfully updated the record')
      actions.getMemberqualityrecords({ internalmemberid: id, per_page: 1000 })
      actions.setUpdatedMemberqualityrecord({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setUpdatedMemberqualityrecord({ status: 0, payload: null })
    }
  }, [state.updatedMemberqualityrecord])

  return children({
    state,
    columns,
    listData,
    clearFilters,
    handleChange,
    qualityGapId,
    viewDocument,
    setViewDocument,
  })
}

export default Container
