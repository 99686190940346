/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message, Form } from 'antd'

const initialValues = {
  icdcodeid: '',
  codestatusid: '',
  codenewid: '',
  querystatusid: '',
  failureid: '',
  failurecomments: '',
}

const Container = ({ children, chartid, icdcodeid }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    updatedMembercharticdcode: s.updatedMembercharticdcode,
    memberchartsicdcode: s.memberchartsicdcode,
    createdMembercharticdcode: s.createdMembercharticdcode,
    icdhccrecords: s.icdhccrecords,
  }))

  const actions = useStoreActions(a => ({
    createMembercharticdcode: a.createMembercharticdcode,
    setMembercharticdcode: a.setMembercharticdcode,
    getIcdhccrecords: a.getIcdhccrecords,
    setIcdhccrecords: a.setIcdhccrecords,
    setUpdatedMembercharticdcode: a.setUpdatedMembercharticdcode,
    updateMembercharticdcode: a.updateMembercharticdcode,
    getMemberchartsicdcodes: a.getMemberchartsicdcodes,
    setMemberchartsicdcode: a.setMemberchartsicdcode, // retrieve setter
    getMemberchartsicdcode: a.getMemberchartsicdcode, // retrieve
  }))

  // Form Hook
  const [form] = Form.useForm()

  // Set Debounce Time
  const [t, setT] = useState(null)
  const [formValues, setFormValues] = useState(initialValues)
  const [icd10s, setIcd10s] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [news, setNews] = useState(null)
  const [queries, setQueries] = useState(null)
  const [failures, setFailures] = useState(null)
  const [currentData, setCurrentData] = useState(null)

  // const [hccs, setHccs] = useState(null)

  const onFinish = values => {
    values.chartid = chartid

    if (currentData) {
      const { icd10, hcc, hccdescription } = currentData
      values.icd10code = icd10
      values.hcccode = hcc
      values.hcccodedescription = hccdescription
    }

    if (icdcodeid) {
      actions.updateMembercharticdcode({ id: icdcodeid, ...values })
    } else {
      values && actions.createMembercharticdcode(values)
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    if (icdcodeid) {
      actions.getMemberchartsicdcode(icdcodeid)
    } else {
      setTimeout(() => {
        setCurrentData({
          icd10_desc: '',
          hcc: '',
          hccdescription: '',
        })

        setFormValues({
          icd10code: '',
          codestatusid: '',
          codenewid: '',
          querystatusid: '',
          failureid: '',
          failurecomments: '',
        })
      })
    }

    return () => {}
  }, [icdcodeid])

  // Side effect of state.memberchartsicdcode
  useEffect(() => {
    const { status, payload } = state.memberchartsicdcode

    if (status === 2) {
      // Set initial values of form

      setCurrentData({
        icd10_desc: payload.icd10description,
        hcc: payload.hcccode,
        hccdescription: payload.hcccodedescription,
      })

      setFormValues({
        icd10code: payload.icd10code,
        codestatusid: payload.codestatusid,
        codenewid: payload.codenewid,
        querystatusid: payload.querystatusid,
        failureid: payload.failureid,
        failurecomments: payload.failurecomments,
      })
    }
  }, [state.memberchartsicdcode])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      setStatuses(payload.filter(x => x.codetype === 'ICDStatus'))
      setNews(payload.filter(x => x.codetype === 'ICDNewStatus'))
      setQueries(payload.filter(x => x.codetype === 'QueryStatus'))
      setFailures(payload.filter(x => x.codetype === 'ChartIcdFail'))
    }
  }, [state.statuscodes])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.icdhccrecords

    if (status === 2 && payload && payload.results && payload.results.length > 0) {
      setIcd10s(payload.results)
    } else if (status === 3) {
      setIcd10s(null)
    }
  }, [state.icdhccrecords])

  // Side effect of createdMembercharticdcode
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdMembercharticdcode

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setMembercharticdcode({ status: 0, payload: null })
    } else if (status === 2) {
      form.resetFields()
      setCurrentData(null)
      message.success('Successfully added new record')
      actions.setMembercharticdcode({ status: 0, payload: null })
      actions.getMemberchartsicdcodes({ chartid })
    }
  }, [state.createdMembercharticdcode])

  // Side effect of updatedMembercharticdcode
  // useEffect(() => {
  //   // code here will execute
  //   const { status, payload } = state.updatedMembercharticdcode

  //   if (status === 3) {
  //     const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
  //     message.error(errorMsg.join(', '))
  //     actions.setUpdatedMembercharticdcode({ status: 0, payload: null })
  //   } else if (status === 2) {
  //     form.resetFields()
  //     setCurrentData(null)
  //     message.success('Successfully updated the record')
  //     actions.setUpdatedMembercharticdcode({ status: 0, payload: null })
  //     actions.getMemberchartsicdcodes({ chartid })
  //   }
  // }, [state.updatedMembercharticdcode])

  const handleICD10Change = value => {
    if (icd10s && icd10s.length > 0) {
      setCurrentData(icd10s.filter(x => x.icd10 === value)[0])
    } else {
      setCurrentData(null)
    }
  }

  // ICD10 search with debounce
  const handleICD10Search = e => {
    if (t) clearTimeout(t)

    setT(
      setTimeout(() => {
        actions.getIcdhccrecords({ search: e })
      }, 500),
    )
  }

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    currentData,
    form,
    handleICD10Change,
    handleICD10Search,
    icd10s,
    statuses,
    news,
    queries,
    failures,
  })
}

export default Container
