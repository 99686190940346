import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

export const getUser = () => axios.get(`${API_URL}/api/v1/me`)

export const loginUsingUsernameAndAppKey = (username, appKey) =>
  axios.post(`${API_URL}/api/v1/oauth/user_key/token`, {
    username,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    app_key: appKey,
  })

export const loginUsingUsernameAndPassword = (username, password, isAppKey) => {
  if (!isAppKey) {
    return axios.post(`${API_URL}/api/oauth/token/`, {
      username,
      password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
    })
  } else {
    return loginUsingUsernameAndAppKey(username, password)
  }
}

export const isLoggedIn = () => {
  const oauth = JSON.parse(localStorage.getItem('oauth'))
  return !!oauth
}

export const revokeToken = () => {
  const loggedIn = isLoggedIn()

  if (loggedIn) {
    const oauth = JSON.parse(localStorage.getItem('oauth'))
    return axios.post(`${API_URL}/api/oauth/revoke_token/`, {
      token: oauth.access_token,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    })
  }
}
