/* eslint-disable no-unused-vars */
import React from 'react'
import { Dropdown, Input, Layout, Menu } from 'antd'
import './index.style.less'
import { FiMoreVertical } from 'react-icons/fi'
import AppLogo from '../components/AppLogo'
import { useIntl } from 'react-intl'
import AppLanguageSwitcher from '../../AppLanguageSwitcher'
import AppHeaderMessages from '../../AppHeaderMessages'
import AppNotifications from '../../AppNotifications'

const AppHeader = () => {
  const { Header } = Layout
  const { Search } = Input
  const { messages } = useIntl()

  const menuMobile = (
    <Menu>
      <AppHeaderMessages />
      <AppNotifications />
      <AppLanguageSwitcher />
    </Menu>
  )

  return (
    <Header className="app-header-mini">
      <AppLogo />

      {/* <Search className="header-search-mini" placeholder={messages['common.searchHere']} />
      <div className="app-header-mini-sectionDesktop">
        <AppLanguageSwitcher />
        <AppHeaderMessages />
        <AppNotifications />
      </div> */}
      <div className="app-header-mini-section-mobile">
        <Dropdown overlay={menuMobile} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  )
}

export default AppHeader

AppHeader.propTypes = {}
