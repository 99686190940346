import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listPermissionGroupsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/permission_groups`, { params })
export const createPermissionGroupApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/permission_groups`, data)
export const getPermissionGroupApi = id => Api.get(`${API_URL}/api/v1/permission_groups/${id}`)
export const deletePermissionGroupApi = id =>
  Api.delete(`${API_URL}/api/v1/permission_groups/${id}`)
export const updatePermissionGroupApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/permission_groups/${id}`, data)
