import { thunk, action } from 'easy-peasy'
import { listMemberlistsApi, getMemberlistApi } from '../../api/memberlist'

export default {
  // List Memberlists
  memberlists: { status: 0, payload: null },
  setMemberlists: action((state, payload = null) => {
    state.memberlists = payload
  }),
  getMemberlists: thunk(async (actions, params = {}) => {
    actions.setMemberlists({ status: 1, payload: null })

    listMemberlistsApi(params)
      .then(response => actions.setMemberlists({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberlists({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberdetail
  memberlist: { status: 0, payload: null },
  setMemberlist: action((state, payload = null) => {
    state.memberlist = payload
  }),
  getMemberlist: thunk(async (actions, id) => {
    actions.setMemberlist({ status: 1, payload: null })

    getMemberlistApi(id)
      .then(response => actions.setMemberlist({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberlist({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
