import { Space, Popconfirm, Button } from 'antd'
import { EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import {
  QUALITY_GAP_STATUS_OPEN,
  QUALITY_GAP_STATUS_CLOSE,
  QUALITY_GAP_STATUS_FOR_REVIEW,
} from '../../../../shared/constants/AppConst'

export default handleUpdate => {
  const statusHandler = status => {
    let labeledStatus = 'Open'

    if (status === 1) {
      labeledStatus = 'Closed'
    } else if (status === 2) {
      labeledStatus = 'For Review'
    }

    return labeledStatus
  }

  return [
    {
      title: 'Measure Code',
      dataIndex: '',
      key: 'measureid',
      sorter: (a, b) => a.measureid - b.measureid,
      render: (text, record) => record?.measureid,
    },
    {
      title: 'Measure',
      dataIndex: 'measurenameshort',
      key: 'measurenameshort',
      sorter: (a, b) => a.measurenameshort - b.measurenameshort,
      render: (text, record) => record?.measurenameshort,
    },
    {
      title: 'Status',
      dataIndex: 'compliant',
      key: 'compliant',
      sorter: (a, b) => a.compliant - b.compliant,
      render: (text, record) => statusHandler(Number(text)),
    },
    {
      title: 'Documents',
      dataIndex: 'file_url',
      key: 'file_url',
      sorter: (a, b) => a.file_url.localeCompare(b.file_url),
      render: (text, record) =>
        record.file_url && (
          <a href={record.file_url} target="_blank" rel="noreferrer">
            {record.filename && record.filename.split('/').pop(-1)}
          </a>
        ),
    },
    {
      title: 'PHA date',
      dataIndex: 'phadate',
      key: 'phadate',
      render: (text, record) => `${moment(record?.phadate).format('MM/DD/YYYY')}`,
    },
    {
      title: 'Request date',
      dataIndex: 'requestdate',
      key: 'requestdate',
      render: (text, record) => `${moment(record?.requestdate).format('MM/DD/YYYY')}`,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          {record?.compliant === QUALITY_GAP_STATUS_FOR_REVIEW ? (
            <>
              <Popconfirm
                title="Are you sure you want to proceed with this action"
                onConfirm={() =>
                  handleUpdate({
                    id: record.id,
                    compliant: QUALITY_GAP_STATUS_CLOSE,
                  })
                }
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button
                  type="primary"
                  icon={<CheckCircleOutlined style={{ fontSize: '24px' }} />}
                />
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to proceed with this action"
                onConfirm={() =>
                  handleUpdate({
                    id: record.id,
                    compliant: QUALITY_GAP_STATUS_OPEN,
                    file_url: null,
                    filelocation: null,
                    filename: null,
                    filetypecode: null,
                    filetypedescription: null,
                  })
                }
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button type="danger" icon={<CloseCircleOutlined style={{ fontSize: '24px' }} />} />
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type="primary" icon={<EditOutlined />} disabled />
            </>
          )}
        </Space>
      ),
    },
  ]
}
