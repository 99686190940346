import { thunk, action } from 'easy-peasy'
import { listPcpactivenamesApi, getPcpactivenamesApi } from '../../api/pcpactivenames'

export default {
  // List Pcpactivename
  pcpactivenames: { status: 0, payload: null },
  setPcpactivenames: action((state, payload = null) => {
    state.pcpactivenames = payload
  }),
  getPcpactivenames: thunk(async (actions, params = {}) => {
    actions.setPcpactivenames({ status: 1, payload: null })

    listPcpactivenamesApi(params)
      .then(response => actions.setPcpactivenames({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpactivenames({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve pcpactivename
  pcpactivename: { status: 0, payload: null },
  setPcpactivename: action((state, payload = null) => {
    state.pcpactivename = payload
  }),
  getPcpactivename: thunk(async (actions, id) => {
    actions.setPcpactivename({ status: 1, payload: null })

    getPcpactivenamesApi(id)
      .then(response => actions.setPcpactivename({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpactivename({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
