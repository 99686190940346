import React from 'react'
import SuperUsersList from './SuperUsers/SuperUsersList'
import SuperUsersForm from './SuperUsers/SuperUsersForm'
import NonSuperUsersList from './NonSuperUsers/NonSuperUsersList'
import NonSuperUsersForm from './NonSuperUsers/NonSuperUsersForm'
import RolesList from './Roles/RolesList'
import RolesForm from './Roles/RolesForm'
import PermissionsList from './Permissions/PermissionsList'
import PermissionsForm from './Permissions/PermissionsForm'
import PermissionGroupsList from './PermissionGroups/PermissionGroupsList'
import PermissionGroupsForm from './PermissionGroups/PermissionGroupsForm'

export default [
  {
    path: '/access-management/superusers',
    element: <SuperUsersList />,
  },
  {
    path: '/access-management/superusers/:id',
    element: <SuperUsersForm />,
  },
  {
    path: '/access-management/nonsuperusers',
    element: <NonSuperUsersList />,
  },
  {
    path: '/access-management/nonsuperusers/:id',
    element: <NonSuperUsersForm />,
  },
  {
    path: '/access-management/roles',
    element: <RolesList />,
  },
  {
    path: '/access-management/roles/:id',
    element: <RolesForm />,
  },
  {
    path: '/access-management/permissions',
    element: <PermissionsList />,
  },
  {
    path: '/access-management/permissions/:id',
    element: <PermissionsForm />,
  },
  {
    path: '/access-management/permission_groups',
    element: <PermissionGroupsList />,
  },
  {
    path: '/access-management/permission_groups/:id',
    element: <PermissionGroupsForm />,
  },
]
