/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Spin, Table, Row, Col, Typography, Alert, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DocumentForm from '../DocumentForm'

const { Title } = Typography

const View = ({ chartid }) => (
  <ViewComponent chartid={chartid}>
    {({
      state,
      columns,
      listData,
      chartid,
      handleChange,
      addDocument,
      setViewForm,
      viewForm,
      fileid,
    }) => (
      <Spin spinning={state.memberchartsfiles.status === 1}>
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={12} style={{ marginBottom: '0px' }}>
                <Title level={2}>Documents</Title>
              </Col>
              <Col span={12} className="text-right" style={{ marginBottom: '0px' }}>
                <a
                  style={{
                    flex: 'none',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={() => addDocument()}
                >
                  <PlusOutlined /> Add item
                </a>
              </Col>
              <Col span={24}>
                {state.memberchartsfiles.status === 3 ? (
                  <Alert
                    message={
                      state.memberchartsfiles.payload.details || 'Oops, something went wrong...'
                    }
                    type="error"
                  />
                ) : (
                  <Table
                    columns={columns}
                    rowKey={record => record.fileid}
                    dataSource={listData}
                    pagination={false}
                    scroll={{
                      x: 600,
                      y: 450,
                    }}
                    loading={state.memberchartsfiles.status === 1}
                    onChange={handleChange}
                  />
                )}
              </Col>
              <Col span={24}>
                <Modal
                  title=""
                  width={900}
                  visible={viewForm}
                  footer={null}
                  maskClosable={true}
                  destroyOnClose={true}
                  onCancel={() => setViewForm(false)}
                >
                  <Row gutter={16} style={{ paddingTop: 25 }}>
                    <Col span={24}>
                      <DocumentForm chartid={chartid} fileid={fileid} />
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View

View.propTypes = {
  chartid: PropTypes.number,
}
