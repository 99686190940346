import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { revokeToken, loginUsingUsernameAndPassword, getUser, isLoggedIn } from './oauth2-api'
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../../redux/common/types'
import { useDispatch } from 'react-redux'

const OAuth2Context = createContext()
const OAuth2ActionsContext = createContext()

export const useOAuth2 = () => useContext(OAuth2Context)

export const useOAuth2Actions = () => useContext(OAuth2ActionsContext)

const OAuth2Provider = ({ children }) => {
  const [oAuth2Data, setOAuth2Data] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn()) {
      getUser()
        .then(response => {
          const { data: user } = response

          setOAuth2Data({
            user,
            isAuthenticated: true,
            isLoading: false,
          })
        })
        .catch(() =>
          setOAuth2Data({
            user: undefined,
            isAuthenticated: false,
            isLoading: false,
          }),
        )
    } else {
      setOAuth2Data({
        user: undefined,
        isAuthenticated: false,
        isLoading: false,
      })
    }
  }, [])

  const signIn = async ({ email, password, isAppKey = false }) => {
    dispatch({ type: FETCH_START })

    try {
      const response = await loginUsingUsernameAndPassword(email, password, isAppKey)

      localStorage.setItem('oauth', JSON.stringify(response.data))

      const { data: user } = await getUser()

      dispatch({ type: FETCH_SUCCESS })

      setOAuth2Data({
        user,
        isLoading: false,
        isAuthenticated: true,
      })
    } catch (error) {
      setOAuth2Data({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      })

      dispatch({ type: FETCH_ERROR, payload: error.message })
    }
  }

  const logout = async () => {
    setOAuth2Data({ ...oAuth2Data, isLoading: true })

    try {
      await revokeToken()

      setOAuth2Data({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      })
    } catch (error) {
      setOAuth2Data({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      })
    }
  }

  return (
    <OAuth2Context.Provider value={oAuth2Data}>
      <OAuth2ActionsContext.Provider value={{ logout, signIn }}>
        {children}
      </OAuth2ActionsContext.Provider>
    </OAuth2Context.Provider>
  )
}

export default OAuth2Provider

OAuth2Provider.propTypes = {
  children: PropTypes.node.isRequired,
}
