import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Spin, Select, Row, Col, Button } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'
import {
  /* MINIMUM_CPT_COUNT, */ CPT_CODES,
  CPT_POS,
} from '../../../../../../shared/constants/AppConst'

// import { createCP10Api } from '../../../../../../api/membercpts'
import styles from './CPTCodes.module.css'

const CPTCodes = ({ chartId }) => {
  const state = useStoreState(s => ({
    updatedCptcode: s.updatedCptcode,
    cptcodes: s.cptcodes,
    createdCptcode: s.createdCptcode,
  }))
  const actions = useStoreActions(a => ({
    updateCptcode: a.updateCptcode,
    getCptcodes: a.getCptcodes,
    createCptcode: a.createCptcode,
  }))

  const wrapperRef = useRef(null)

  const [cptcodes, setCptcodes] = useState([])
  const [posValue, setPosValue] = useState({})

  const pos = CPT_POS.map(cpt => {
    return {
      value: cpt.value,
      label: `${cpt.value} - ${cpt.label}`,
    }
  })

  useEffect(() => {
    actions.getCptcodes({ per_page: 21, chartid: chartId })
  }, [])

  useEffect(() => {
    actions.getCptcodes({ per_page: 21, chartid: chartId })
  }, [chartId])

  useEffect(() => {
    const { status, payload } = state.createdCptcode

    if (status === 2 && String(payload.label).toLowerCase() === 'pos') {
      setPosValue(payload)
    }
  }, [state.createdCptcode])

  useEffect(() => {
    const { status, payload } = state.cptcodes

    if (status === 2) {
      const { results /** count */ } = payload

      setPosValue(results.find(res => String(res.label).toLowerCase() === 'pos'))

      setCptcodes(results.filter(res => String(res.label).toLowerCase() !== 'pos'))
    }
  }, [state.cptcodes])

  const handleChange = async ({ value, id, label }) => {
    if (id === null) {
      await actions.createCptcode({ value, label, chartid: chartId })
    } else {
      await actions.updateCptcode({ id, value, label, chartid: chartId })
    }

    return new Promise(() => {
      setTimeout(async () => {
        await actions.getCptcodes({ per_page: 21, chartid: chartId })
      }, 300)
    })
  }

  const handlePosUpdate = async (id, label, value) => {
    const data = { label, value, chartid: chartId }

    if (!id) {
      await actions.createCptcode(data)
    } else {
      await actions.updateCptcode({ id, ...data })
    }

    setPosValue({ value, label, id, chartid: chartId })
  }

  const handleAddCPT = () => {
    setCptcodes(prev => {
      const newCptcodes = [...prev, { id: null, chartid: chartId, value: null, label: null }]
      const adjustedCpts = newCptcodes.length <= 20 ? newCptcodes : prev

      if (adjustedCpts.length > 1 && !cptcodes[cptcodes.length - 1].value) {
        alert(`Please select in CPT${cptcodes.length}`)
        return prev
      } else {
        return adjustedCpts
      }
    })
  }

  return (
    <>
      <Row>
        <Col span={20}>
          <h1>CPT/CPTII CODE ENTRY</h1>
        </Col>
        <Col span={4}>
          <Select
            showSearch
            placeholder="POS"
            optionFilterProp="children"
            defaultValue={state.cptcodes.status === 2 ? null : posValue?.value}
            value={posValue?.value}
            onChange={v => handlePosUpdate(posValue?.id, 'pos', v)}
            style={{ width: '100%' }}
            options={pos}
            allowClear
          />
        </Col>
      </Row>

      <Spin spinning={state.updatedCptcode.status === 1 || state.createdCptcode.status === 1}>
        <div ref={wrapperRef} id="cptcodes-wrapper" className={styles.wrapper}>
          {cptcodes.map((row, index) => (
            <div key={index} className={styles.rows}>
              <div className={styles.rowLabel}>{row.label || `CPT${index + 1}`}</div>
              <div className={styles.rowField}>
                <Select
                  size="small"
                  showSearch
                  defaultValue={row.value}
                  onChange={async (value, option) => {
                    // Reference for clearing data
                    // For allowClear to know that you have a selected value
                    if (value) cptcodes[index].value = value

                    await handleChange(
                      {
                        value: value ?? null,
                        label: row.label || `CPT${index + 1}`,
                        id: row.id,
                        index: row.index,
                      },
                      option,
                    )
                  }}
                  className={styles.select}
                  options={CPT_CODES.map(code => ({
                    label: `${code.value} -${code.label}`,
                    value: code.value,
                  }))}
                  allowClear
                />
              </div>
            </div>
          ))}
          {cptcodes.length < 20 && (
            <div>
              <Button
                size="small"
                type="primary"
                onClick={handleAddCPT}
                disabled={state.updatedCptcode.status === 1 || state.createdCptcode.status === 1}
              >
                + Add CPT Code
              </Button>
            </div>
          )}
        </div>
      </Spin>
    </>
  )
}

CPTCodes.propTypes = {
  chartId: PropTypes.number.isRequired,
}

export default CPTCodes
