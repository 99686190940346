import { thunk, action } from 'easy-peasy'
import {
  listMemberenrollmentspansmedicalsApi,
  getMemberenrollmentspansmedicalApi,
} from '../../api/memberenrollmentspansmedical'

export default {
  // List Memberenrollmentspansmedicals
  memberenrollmentspansmedicals: { status: 0, payload: null },
  setMemberenrollmentspansmedicals: action((state, payload = null) => {
    state.memberenrollmentspansmedicals = payload
  }),
  getMemberenrollmentspansmedicals: thunk(async (actions, params = {}) => {
    actions.setMemberenrollmentspansmedicals({ status: 1, payload: null })

    listMemberenrollmentspansmedicalsApi(params)
      .then(response =>
        actions.setMemberenrollmentspansmedicals({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setMemberenrollmentspansmedicals({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberenrollmentspansmedical
  memberenrollmentspansmedical: { status: 0, payload: null },
  setMemberenrollmentspansmedical: action((state, payload = null) => {
    state.memberenrollmentspansmedical = payload
  }),
  getMemberenrollmentspansmedical: thunk(async (actions, id) => {
    actions.setMemberenrollmentspansmedical({ status: 1, payload: null })

    getMemberenrollmentspansmedicalApi(id)
      .then(response =>
        actions.setMemberenrollmentspansmedical({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setMemberenrollmentspansmedical({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
