import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Divider,
  Alert,
  Card,
  Popconfirm,
} from 'antd'
import { CHART_STATUS_QUERY_PENDING } from '../../../../shared/constants/AppConst'
import * as moment from 'moment'

const { Option } = Select
const { Title } = Typography
const { RangePicker } = DatePicker

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      listData,
      pagination,
      setT,
      onTableChange,
      onResultFilter,
      handleGroupChange,
      handleNameChange,
      handleStatusChange,
      handleDxStatusChange,
      handleUserChange,
      handleUserBlur,
      handleDateRange,
      handleModifiedDateRange,
      handleVisitTypeChange,
      t,
      groups,
      names,
      kpiCards,
      statuses,
      dxStatuses,
      users,
      selectedMember,
      clearFilters,
      visitTypes,
      rowSelection,
      allUsers,
      onAssignUser,
      handleAssignedUserChange,
      assignedUser,
    }) => (
      <>
        <AppPageMetadata title="Provider Groups" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={2}>Coder Review Dashboard</Title>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button onClick={clearFilters}>Clear filters</Button>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <Title level={5}>Assigned User</Title>
                {!users && <Select style={{ width: '100%' }} disabled />}
                {users && selectedMember && (
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    defaultValue={(queries && queries?.assigneduser__in) || selectedMember || []}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All Assigned User"
                    onChange={handleUserChange}
                    onBlur={handleUserBlur}
                  >
                    {users &&
                      users.map((u, index) => (
                        <Option key={index} value={u.username}>
                          {u.username}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={3}>
                <Title level={5}>Status</Title>
                {!statuses && <Select style={{ width: '100%' }} disabled />}
                {statuses && (
                  <Select
                    showSearch
                    defaultValue={
                      (queries &&
                        statuses.filter(x => x.statuscode === queries?.statusid).length > 0 &&
                        queries?.statusid) ||
                      CHART_STATUS_QUERY_PENDING
                    }
                    value={queries && queries?.statusid}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All Status"
                    onChange={handleStatusChange}
                    allowClear
                  >
                    {statuses &&
                      statuses.map((g, index) => (
                        <Option key={index} value={g.statuscode}>
                          {g.codedescription}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={3}>
                <Title level={5}>DX Status</Title>
                {!dxStatuses && <Select style={{ width: '100%' }} disabled />}
                {dxStatuses && (
                  <Select
                    showSearch
                    defaultValue={
                      (queries &&
                        dxStatuses.filter(x => x.statuscode === queries?.dxstatus__in).length > 0 &&
                        queries?.dxstatus__in) ||
                      null
                    }
                    value={(queries && queries?.dxstatus__in) || null}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All DX Status"
                    onChange={handleDxStatusChange}
                    allowClear
                  >
                    {dxStatuses &&
                      dxStatuses.map((g, index) => (
                        <Option key={index} value={g.statuscode}>
                          {g.codedescription}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={4}>
                <Title level={5}>Visit Type</Title>
                <Select
                  showSearch
                  allowClear
                  defaultValue={(queries && queries?.typeid) || null}
                  value={(queries && queries?.typeid) || null}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: '100%' }}
                  onChange={handleVisitTypeChange}
                >
                  {visitTypes &&
                    visitTypes.map((v, index) => (
                      <Option key={v.typeid} value={v.typeid}>
                        {v.typename}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={4}>
                <Title level={5}>Rendering Group</Title>
                <Select
                  showSearch
                  allowClear
                  defaultValue={(queries && queries?.providergrouptin) || null}
                  value={(queries && queries?.providergrouptin) || null}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: '100%' }}
                  onChange={handleGroupChange}
                >
                  {groups &&
                    groups.map((g, index) => (
                      <Option key={index} value={g.pcpgrptaxid}>
                        {g.pcpgroupname}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={4}>
                <Title level={5}>Rendering Provider</Title>
                {!names && <Select style={{ width: '100%' }} disabled />}
                {names && (
                  <Select
                    showSearch
                    defaultValue={
                      (queries &&
                        names.filter(x => x.pcp_npi === queries?.pcpnpi).length > 0 &&
                        queries?.pcpnpi) ||
                      null
                    }
                    value={queries && queries?.pcpnpi}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All PCP"
                    onChange={handleNameChange}
                    allowClear
                  >
                    {names.map((n, index) => (
                      <Option key={index} value={n.pcp_npi}>
                        {n.pcpfullname}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col span={5}>
                <Title level={5}>Appointment Date</Title>
                <RangePicker
                  value={
                    queries && [
                      queries.appointmentdate__gte ? moment(queries.appointmentdate__gte) : null,
                      queries.appointmentdate__lte ? moment(queries.appointmentdate__lte) : null,
                    ]
                  }
                  onChange={handleDateRange}
                />
              </Col>

              <Col span={5}>
                <Title level={5}>Modified Date</Title>
                <RangePicker
                  value={
                    queries && [
                      queries.modifieddate__gte ? moment(queries.modifieddate__gte) : null,
                      queries.modifieddate__lte ? moment(queries.modifieddate__lte) : null,
                    ]
                  }
                  onChange={handleModifiedDateRange}
                />
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              {kpiCards &&
                Object.keys(kpiCards).map(
                  (k, i) =>
                    statuses.filter(x => x.statuscode === k).length > 0 && (
                      <Col
                        span={i === 0 && Object.keys(kpiCards).length > 4 ? 4 : 5}
                        key={k}
                        className="card-container"
                      >
                        <Card>
                          <Row style={{ marginTop: 20 }}>
                            <Col span={24} className="text-center">
                              <Title level={2}>{kpiCards[k]}</Title>
                              <span>
                                {statuses.filter(x => x.statuscode === k)[0]?.codedescription}
                              </span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ),
                )}
            </Row>
            <Divider />
            {queries.pcpnpi ||
            queries.providergrouptin ||
            queries.assigneduser__in ||
            queries.assigneduser__in === '' ||
            queries.appointmentdate__gt ||
            queries.appointmentdate__lt ||
            queries.modifieddate__gt ||
            queries.modifieddate__lt ||
            queries.dxstatus__in ? (
              <>
                <Row>
                  <Col span={12}>
                    {state.memberchartsv2.status === 2 && (
                      <Title level={4}>{state.memberchartsv2.payload.count} item(s) found</Title>
                    )}
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    Select Assigned to: &nbsp;
                    <Select
                      showSearch
                      allowClear
                      value={assignedUser}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="All Assigned User"
                      onChange={handleAssignedUserChange}
                    >
                      {allUsers &&
                        allUsers.map((u, index) => (
                          <Option key={index} value={u.username}>
                            {u.username}
                          </Option>
                        ))}
                    </Select>
                    &nbsp;&nbsp;&nbsp;
                    <Popconfirm
                      title="Are you reassign all records?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={onAssignUser}
                    >
                      <Button>Confirm</Button>&nbsp; &nbsp;
                    </Popconfirm>
                    <Input
                      style={{ maxWidth: 300 }}
                      size="large"
                      placeholder="Search From Results"
                      onChange={e => {
                        if (t) clearTimeout(t)

                        setT(
                          setTimeout(() => {
                            onResultFilter(e.target.value)
                          }, 500),
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {state.memberchartsv2.status === 3 ? (
                      <Alert
                        message={
                          state.memberchartsv2.payload.details || 'Oops, something went wrong...'
                        }
                        type="error"
                      />
                    ) : (
                      <Table
                        columns={columns}
                        rowKey={record => record.chartid}
                        dataSource={listData}
                        pagination={pagination}
                        loading={
                          state.updatedMultipleMemberCharts.status === 1 ||
                          state.memberchartsv2.status === 1
                        }
                        onChange={onTableChange}
                        rowSelection={rowSelection}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </AppRowContainer>
      </>
    )}
  </ViewComponent>
)

export default View
