import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listAppointmentsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/appointments`, { params })
export const createAppointmentApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/crm/appointments`, data)
export const getAppointmentApi = id => Api.get(`${API_URL}/api/v1/crm/appointments/${id}`)
export const deleteAppointmentApi = id => Api.delete(`${API_URL}/api/v1/crm/appointments/${id}`)
export const updateAppointmentApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/appointments/${id}`, data)
