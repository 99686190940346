import React from 'react'
import { p } from '../../shared/constants/AppConst'
import { BsCheck2All } from 'react-icons/bs'

const demo1Rights = [p.DEMO1_RO, p.DEMO1_RW]
const demo2Rights = [p.DEMO2_RO, p.DEMO2_RW]

export default {
  id: '/demo',
  title: 'Demo',
  messageId: 'sidebar.demo',
  type: 'group',
  rights: [...demo1Rights, ...demo2Rights],
  children: [
    {
      id: '/demo/demo1s',
      title: 'Demo Page 1',
      messageId: 'sidebar.demo.demo1s_list',
      type: 'item',
      icon: <BsCheck2All />,
      path: '/demo/demo1s',
      rights: demo1Rights,
    },
    {
      id: '/demo/demo2s',
      title: 'Demo Page 2',
      messageId: 'sidebar.demo.demo2s_list',
      type: 'item',
      icon: <BsCheck2All />,
      path: '/demo/demo2s',
      rights: demo2Rights,
    },
  ],
}
