import React from 'react'

const Demo1sList = React.lazy(() => import('./Demo1s/Demo1sList'))
const Demo1sForm = React.lazy(() => import('./Demo1s/Demo1sForm'))

const Demo2sList = React.lazy(() => import('./Demo2s/Demo2sList'))
const Demo2sForm = React.lazy(() => import('./Demo2s/Demo2sForm'))

export default [
  {
    path: '/demo/demo1s',
    element: <Demo1sList />,
  },
  {
    path: '/demo/demo1s/:id',
    element: <Demo1sForm />,
  },
  {
    path: '/demo/demo2s',
    element: <Demo2sList />,
  },
  {
    path: '/demo/demo2s/:id',
    element: <Demo2sForm />,
  },
]
