/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Statistic, Spin, Space, Table, Row, Col, Typography, Card, Alert, Modal } from 'antd'
import ICD10Form from '../ICD10Form'

const { Title } = Typography

const View = ({ chartid }) => (
  <ViewComponent chartid={chartid}>
    {({
      state,
      columns,
      listData,
      chartid,
      setViewForm,
      setIcdcodeid,
      totalIcd10,
      totalUniqueHcc,
      viewForm,
      icdcodeid,
      baseurl,
    }) => (
      <Spin
        spinning={
          state.memberchartsicdcodes.status === 1 ||
          state.createdMembercharticdcode.status === 1 ||
          state.deletedMembercharticdcode.status === 1 ||
          state.updatedMembercharticdcode.status === 1
        }
      >
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={20}>
                <Space>
                  <Title level={2}>ICD10 Codes</Title>
                  <div></div>
                  <Card style={{ width: 200 }}>
                    <Statistic
                      title="Total ICD10"
                      value={totalIcd10}
                      precision={0}
                      valueStyle={{ color: '#3f8600' }}
                    />
                  </Card>
                  <Card style={{ width: 200 }}>
                    <Statistic
                      title="Total Unique HCC"
                      value={totalUniqueHcc}
                      precision={0}
                      valueStyle={{ color: '#3f8600' }}
                    />
                  </Card>
                </Space>
              </Col>
              <Col span={4} className="text-right" style={{ marginBottom: '0px' }}>
                <a
                  href={'' + baseurl + '?' + 'chartid=' + chartid + '&tab=hcc_current'}
                  target={'_blank'}
                  rel={'noreferrer'}
                  style={{
                    flex: 'none',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                >
                  VIEW HCC CURRENT
                </a>
              </Col>
              <Col span={24}>
                {state.memberchartsicdcodes.status === 3 ? (
                  <Alert
                    message={
                      state.memberchartsicdcodes.payload.details || 'Oops, something went wrong...'
                    }
                    type="error"
                  />
                ) : (
                  <Table
                    columns={columns}
                    rowKey={record => record.icdcodeid}
                    dataSource={listData}
                    pagination={false}
                    loading={state.memberchartsicdcodes.status === 1}
                  />
                )}
              </Col>
              <Col span={24}>
                {viewForm && (
                  <Modal
                    title=""
                    width={900}
                    visible={viewForm}
                    footer={null}
                    maskClosable={true}
                    destroyOnClose={true}
                    onCancel={() => {
                      setViewForm(false)
                      setIcdcodeid(null)
                    }}
                  >
                    <Row gutter={16} style={{ paddingTop: 25 }}>
                      <Col span={24}>
                        <ICD10Form chartid={chartid} icdcodeid={icdcodeid} />
                      </Col>
                    </Row>
                  </Modal>
                )}
              </Col>
            </Row>
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View

View.propTypes = {
  chartid: PropTypes.number,
  baseurl: PropTypes.string,
}
