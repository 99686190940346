import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default (actions, user, basePath) => {
  return [
    {
      title: 'Member Name',
      dataIndex: '',
      key: 'memberfullname',
      sorter: (a, b) => a.memberfullname - b.memberfullname,
      render: (text, record) => (
        <Link to={`/landing-page/memberdetails/view/${record.internalmemberid}/`}>
          {record.memberfullname}
        </Link>
      ),
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      sorter: (a, b) => a.sex - b.sex,
      key: 'sex',
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      sorter: (a, b) =>
        moment(a.dob).format('MM/DD/YYYY').localeCompare(moment(b.dob).format('MM/DD/YYYY')),
      render: (text, record) => moment(record.dob).format('MM/DD/YYYY'),
    },
    {
      title: 'Group Name',
      dataIndex: 'pcpgroupname',
      key: 'pcpgroupname',
      sorter: (a, b) => a.pcpgroupname.localeCompare(b.pcpgroupname),
      render: (text, record) => record?.pcpgroupname,
    },
    {
      title: 'PCP',
      dataIndex: 'pcpfullname',
      key: 'pcpfullname',
      sorter: (a, b) => a.pcpfullname.localeCompare(b.pcpfullname),
      render: (text, record) => record?.pcpfullname,
    },
    {
      title: 'BUR 2021',
      dataIndex: 'bur_2021',
      key: 'bur_2021',
    },
    {
      title: 'BUR 2022',
      dataIndex: 'bur_2022',
      key: 'bur_2022',
    },
    {
      title: 'Uncovered BUR',
      dataIndex: 'bur_2022',
      key: 'bur_2022',
    },
    {
      title: 'Open Gaps',
      dataIndex: 'open_gaps',
      key: 'open_gaps',
      sorter: (a, b) => a.open_gaps.localeCompare(b.open_gaps),
      render: (text, record) => record?.open_gaps,
    },
    {
      title: 'BUR Change',
      dataIndex: 'bur_change',
      key: 'bur_change',
      sorter: (a, b) => a.bur_change.localeCompare(b.bur_change),
      render: (text, record) => record?.bur_change,
    },
  ]
}
