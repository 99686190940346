import { thunk, action } from 'easy-peasy'
import { listMemberpcphistoriesApi, getMemberpcphistoryApi } from '../../api/memberpcphistory'

export default {
  // List Memberpcphistories
  memberpcphistories: { status: 0, payload: null },
  setMemberpcphistories: action((state, payload = null) => {
    state.memberpcphistories = payload
  }),
  getMemberpcphistories: thunk(async (actions, params = {}) => {
    actions.setMemberpcphistories({ status: 1, payload: null })

    listMemberpcphistoriesApi(params)
      .then(response => actions.setMemberpcphistories({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberpcphistories({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberdetail
  memberpcphistory: { status: 0, payload: null },
  setMemberpcphistory: action((state, payload = null) => {
    state.memberpcphistory = payload
  }),
  getMemberpcphistory: thunk(async (actions, id) => {
    actions.setMemberpcphistory({ status: 1, payload: null })

    getMemberpcphistoryApi(id)
      .then(response => actions.setMemberpcphistory({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberpcphistory({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
