import { createStore } from 'easy-peasy'
import { routerMiddleware } from 'connected-react-router'
import { reducers, history } from './reducers'
import thunk from 'redux-thunk'

const routeMiddleware = routerMiddleware(history)

const model = {
  ...reducers,
}

const store = () =>
  createStore(model, {
    middleware: [thunk, routeMiddleware],
  })

export default store
