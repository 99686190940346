import { thunk, action } from 'easy-peasy'
import {
  listMemberchartsactivityrecordApi,
  getMemberchartactivityrecordApi,
  createMemberchartactivityrecordApi,
  deleteMemberchartactivityrecordApi,
} from '../../api/memberchartsactivityrecord'

export default {
  // List Memberchartsactivityrecords
  memberchartsactivityrecords: { status: 0, payload: null },
  setMemberchartsactivityrecords: action((state, payload = null) => {
    state.memberchartsactivityrecords = payload
  }),
  getMemberchartsactivityrecords: thunk(async (actions, params = {}) => {
    actions.setMemberchartsactivityrecords({ status: 1, payload: null })

    listMemberchartsactivityrecordApi(params)
      .then(response =>
        actions.setMemberchartsactivityrecords({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setMemberchartsactivityrecords({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberchartsactivityrecord
  memberchartactivityrecord: { status: 0, payload: null },
  setMemberchartactivityrecord: action((state, payload = null) => {
    state.memberchartactivityrecord = payload
  }),
  getMemberchartactivityrecord: thunk(async (actions, id) => {
    actions.setMemberchartactivityrecord({ status: 1, payload: null })

    getMemberchartactivityrecordApi(id)
      .then(response => actions.setMemberchartactivityrecord({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchartactivityrecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Memberchartsactivityrecord
  createdMemberchartactivityrecord: { status: 0, payload: null },
  setCreatedMemberchartactivityrecord: action((state, payload = null) => {
    state.createdMemberchartactivityrecord = payload
  }),
  createMemberchartactivityrecord: thunk(async (actions, data = {}) => {
    actions.setCreatedMemberchartactivityrecord({ status: 1, payload: null })

    createMemberchartactivityrecordApi(data)
      .then(response =>
        actions.setCreatedMemberchartactivityrecord({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setCreatedMemberchartactivityrecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Memberchartsactivityrecord
  deletedMemberchartactivityrecord: { status: 0, payload: null },
  setDeletedMemberchartactivityrecord: action((state, payload = null) => {
    state.deletedMemberchartactivityrecord = payload
  }),
  deleteMemberchartactivityrecord: thunk(async (actions, id) => {
    actions.setDeletedMemberchartactivityrecord({ status: 1, payload: null })

    deleteMemberchartactivityrecordApi(id)
      .then(response =>
        actions.setDeletedMemberchartactivityrecord({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setDeletedMemberchartactivityrecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
