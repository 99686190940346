import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default (query, actions, user, basePath) => {
  return [
    {
      title: 'Member Name',
      dataIndex: '',
      key: 'member_name',
      sorter: (a, b) => a.last_name - b.last_name,
      render: (text, record) => (
        <Link
          to={`/landing-page/memberdetails/view/${record.internalmemberid}/?search=${
            query?.search || ''
          }&lob=${query?.lob || ''}&memberstatus=${query?.memberstatus || ''}&memberid=${
            record.memberid
          }`}
        >
          {record.memberlastname}, {record.memberfirstname}
        </Link>
      ),
    },
    {
      title: 'Member Id',
      dataIndex: 'memberid',
      key: 'memberid',
      sorter: (a, b) => a.memberid - b.memberid,
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      sorter: (a, b) => a.dob - b.dob,
      render: (text, record) => moment(record.dob).format('MM/DD/YYYY'),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: (a, b) => a.gender - b.gender,
    },

    // {
    //   title: 'PCP Name',
    //   dataIndex: 'pcp',
    //   key: 'pcp',
    //   sorter: (a, b) => a?.pcp?.pcpfirstname.localeCompare(b?.pcp?.pcpfirstname),
    //   render: (text, record) =>
    //     `${record?.pcp?.pcpfirstname || ''} ${record?.pcp?.pcplastname || ''}`,
    // },
    // {
    //   title: 'PCP Group',
    //   dataIndex: 'pcpgroup',
    //   key: 'pcpgroup',
    //   sorter: (a, b) => a?.pcp?.providergroupname.localeCompare(b?.providergroupname),
    //   render: (text, record) => record?.pcp?.providergroupname,
    // },
  ]
}
