import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { sortBy } from 'lodash'

import tableColumns from './columns'
import hccTableColumns from '../../HCCCurrent/HCCCurrentList/columns'

const Container = ({ children, id }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    hccgaps: s.hccgaps,
    memberhcccurrents: s.memberhcccurrents,
  }))

  const actions = useStoreActions(a => ({
    getHccgaps: a.getHccgaps,
    getMemberhcccurrents: a.getMemberhcccurrents,
    setMemberhcccurrents: a.setMemberhcccurrents,
  }))

  // Local states
  const [listData, setListData] = useState([])
  const [hccData, setHccData] = useState(null)
  const [hccPagination, setHccPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 500,
    showSizeChanger: false,
  })
  const hccColumns = hccTableColumns(null, hccPagination, hccData)
  const [columns, setColumns] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState({})
  const [sortedInfo, setSortedInfo] = useState({})
  const [viewHCC, setViewHCC] = useState(false)

  const clearFilters = () => {
    setFilteredInfo({})
    setSortedInfo({})
  }

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  }

  const handleFilters = payload => {
    const fields = ['hcc', 'hccdescription', 'y2020', 'y2021', 'y2022', 'y2023']
    const filters = { hcc: [], hccdescription: [], y2020: [], y2021: [], y2022: [], y2023: [] }

    payload.forEach(p => {
      fields.forEach(f => {
        if (filters[f].filter(x => x.value === p[f]).length === 0) {
          filters[f].push({ text: p[f], value: p[f] })
        }
      })
    })

    return filters
  }

  const handleHccModal = (hcc, year, value) => {
    if (hcc) actions.getMemberhcccurrents({ hcc, year, value, internalmemberid: id })
    setViewHCC(!viewHCC)
  }

  useEffect(() => {
    actions.getHccgaps({ internalmemberid: id })
  }, [])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.hccgaps

    if (status === 2) {
      const sortedData = sortBy(payload, ['hcc'], ['asc'])

      // Set Table Data
      const data = []

      sortedData &&
        sortedData.forEach((v, i) => {
          v.key = i

          // v.hcc = v.hcc ? v.hcc : null
          // v.y2020 = v.y2020 ? v.y2020 : null
          // v.y2021 = v.y2021 ? v.y2021 : null
          // v.y2022 = v.y2022 ? v.y2022 : null

          data.push(v)
        })

      // Create choices of filters in column headers
      const filters = handleFilters(data)

      // Set created columns
      setColumns(tableColumns(filters, filteredInfo, sortedInfo, handleHccModal))

      setListData(data)
    }
  }, [state.hccgaps, filteredInfo, sortedInfo])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberhcccurrents

    if (status === 2) {
      const data = []

      payload &&
        payload.forEach((v, i) => {
          v.key = i
          data.push(v)
        })

      setHccData(payload)

      setHccPagination(prev => ({
        ...prev,
        total: payload.length,
      }))

      actions.setMemberhcccurrents({ status: 0, payload: null })
    } else if (status === 3) {
      setHccData(null)
      actions.setMemberhcccurrents({ status: 0, payload: null })
    }
  }, [state.memberhcccurrents])

  return children({
    state,
    columns,
    listData,
    clearFilters,
    handleChange,
    hccColumns,
    hccData,
    setHccData,
    hccPagination,
    viewHCC,
    setViewHCC,
  })
}

export default Container
