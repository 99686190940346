export const GET_CONTACT_LIST = 'GET_CONTACT_LIST'
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST'
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL'
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS'
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL'
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER'
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL'
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT'
