import React, { createContext, useContext, useState } from 'react'
import defaultConfig from './defaultConfig'
import PropTypes from 'prop-types'

const LayoutContext = createContext()
const LayoutActionsContext = createContext()

export const useLayoutContext = () => useContext(LayoutContext)

export const useLayoutActionsContext = () => useContext(LayoutActionsContext)

const LayoutContextProvider = ({ children }) => {
  const _layoutType = localStorage.getItem('layoutType')
  const _navStyle = localStorage.getItem('navStyle')
  const _direction = localStorage.getItem('direction')
  const _footerType = localStorage.getItem('footerType')
  const _footer = localStorage.getItem('footer')

  const [layoutType, updateLayoutType] = useState(_layoutType || defaultConfig.layoutType)
  const [navStyle, updateNavStyle] = useState(_navStyle || defaultConfig.navStyle)
  const [direction, updateDirection] = useState(_direction || defaultConfig.direction)
  const [footerType, setFooterType] = useState(_footerType || defaultConfig.footerType)
  const [footer, setFooter] = useState(_footer || defaultConfig.footer)

  return (
    <LayoutContext.Provider
      value={{
        navStyle,
        direction,
        footerType,
        footer,
        layoutType,
        rtlLocale: defaultConfig.rtlLocale,
      }}
    >
      <LayoutActionsContext.Provider
        value={{
          setFooter,
          setFooterType,
          updateDirection,
          updateNavStyle,
          updateLayoutType,
        }}
      >
        {children}
      </LayoutActionsContext.Provider>
    </LayoutContext.Provider>
  )
}

export default LayoutContextProvider

LayoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
