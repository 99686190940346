import { thunk, action } from 'easy-peasy'
import { listIcdhccrecordsApi } from '../../api/icdhccrecord'

export default {
  // List ICD10
  icdhccrecords: { status: 0, payload: null },
  setIcdhccrecords: action((state, payload = null) => {
    state.icdhccrecords = payload
  }),
  getIcdhccrecords: thunk(async (actions, params = {}) => {
    actions.setIcdhccrecords({ status: 1, payload: null })

    listIcdhccrecordsApi(params)
      .then(response => actions.setIcdhccrecords({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setIcdhccrecords({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),
}
