import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Table, Typography, Row, Col, Select, Input, DatePicker, Divider, Alert } from 'antd'

const { Option } = Select
const { Title } = Typography
const { RangePicker } = DatePicker

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      listData,
      setT,
      onTableChange,
      onResultFilter,
      handleUserChange,
      handleDateRange,
      t,
      users,
    }) => (
      <>
        <AppPageMetadata title="Rendering Groups" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={2}>Abstractor</Title>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <Title level={5}>Assigned User</Title>
                {!users && <Select style={{ width: '100%' }} disabled />}
                {users && (
                  <Select
                    showSearch
                    allowClear
                    defaultValue={(queries && queries?.assignedviewer) || null}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All Assigned User"
                    onChange={handleUserChange}
                  >
                    {users &&
                      users.map((u, index) => (
                        <Option key={index} value={u.username}>
                          {u.username}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={5}>
                <Title level={5}>Request Date</Title>
                <RangePicker onChange={handleDateRange} />
              </Col>
            </Row>
            <Divider />
            {queries.assignedviewer || queries.requestdate__gt || queries.requestdate__lt ? (
              <>
                <Row>
                  <Col span={12}>
                    {state.memberqualityrecords.status === 2 && (
                      <Title level={4}>
                        {state.memberqualityrecords.payload.length} item(s) found
                      </Title>
                    )}
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Input
                      style={{ maxWidth: 300 }}
                      size="large"
                      placeholder="Search From Results"
                      onChange={e => {
                        if (t) clearTimeout(t)

                        setT(
                          setTimeout(() => {
                            onResultFilter(e.target.value)
                          }, 500),
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {state.memberqualityrecords.status === 3 ? (
                      <Alert
                        message={
                          state.memberqualityrecords.payload.details ||
                          'Oops, something went wrong...'
                        }
                        type="error"
                      />
                    ) : (
                      <Table
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={listData}
                        loading={
                          state.memberqualityrecords.status === 1 ||
                          state.updatedMemberqualityrecord.status === 1
                        }
                        onChange={onTableChange}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </AppRowContainer>
      </>
    )}
  </ViewComponent>
)

export default View
