export const GET_ECOMMERCE_LIST = 'get_ecommerce_list'
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type'
export const SET_FILTER_DATA = 'set_filter_data'
export const SET_PRODUCT_DATA = 'set_product_data'
export const GET_RECENT_ORDER = 'get_recent_order'
export const GET_CUSTOMERS = 'get_customers'
export const ADD_CART_ITEM = 'add_cart_item'
export const REMOVE_CART_ITEM = 'remove_cart_item'
export const UPDATE_CART_ITEM = 'update_cart_item'
export const SET_CART_ITEMS = 'set_cart_items'
