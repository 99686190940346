import { thunk, action } from 'easy-peasy'
import {
  listProvidereducationsApi,
  updateProvidereducationsApi,
  createProvidereducationApi,
  deleteProvidereducationsApi,
} from '../../api/providereducation'

export default {
  // List Providereducations
  providereducations: { status: 0, payload: null },
  setProvidereducations: action((state, payload = null) => {
    state.providereducations = payload
  }),
  getProvidereducations: thunk(async (actions, params = {}) => {
    actions.setProvidereducations({ status: 1, payload: null })

    listProvidereducationsApi(params)
      .then(response => actions.setProvidereducations({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setProvidereducations({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),

  // List Summary Providereducations
  summaryprovidereducations: { status: 0, payload: null },
  setSummaryProvidereducations: action((state, payload = null) => {
    state.summaryprovidereducations = payload
  }),
  getSummaryProvidereducations: thunk(async (actions, params = {}) => {
    actions.setSummaryProvidereducations({ status: 1, payload: null })
    params.summary = 1

    listProvidereducationsApi(params)
      .then(response => actions.setSummaryProvidereducations({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setSummaryProvidereducations({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),

  // Is Open Provider Educations
  isOpenProvidereducationsModal: false,
  setIsOpenProvidereducationsModal: action((state, payload = null) => {
    state.isOpenProvidereducationsModal = payload
  }),

  // Update Providereducations
  updatedPE: { status: 0, payload: null },
  setUpdatedPE: action((state, payload = null) => {
    state.updatedPE = payload
  }),
  updatePE: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedPE({ status: 1, payload: null })

    updateProvidereducationsApi(id, data)
      .then(response => actions.setUpdatedPE({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedPE({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Providereducations
  createdPE: { status: 0, payload: null },
  setCreatedPE: action((state, payload = null) => {
    state.createdPE = payload
  }),
  createPE: thunk(async (actions, data = {}) => {
    actions.setCreatedPE({ status: 1, payload: null })

    createProvidereducationApi(data)
      .then(response => actions.setCreatedPE({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedPE({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Providereducations
  deletedPE: { status: 0, payload: null },
  setDeletedPE: action((state, payload = null) => {
    state.deletedPE = payload
  }),
  deletePE: thunk(async (actions, { id }) => {
    actions.setDeletedPE({ status: 1, payload: null })

    deleteProvidereducationsApi(id)
      .then(response => actions.setDeletedPE({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedPE({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
