import { reducer } from 'easy-peasy'
import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'

// Redux Reducers
import settings from './settings'
import common from './common'
import mailApp from './mailApp'
import todoApp from './todoApp'
import contactApp from './contactApp'
import ecommerce from './ecommerce'
import scrumboardApp from './scrumboardApp'
import chatApp from './chatApp'
import wall from './wall'
import gallery from './gallery'
import dashboard from './dashboard'
import editors from './editors'
import userList from './userList'

// Easy Peasy Reducers
import superuser from './_ep/superuser'
import nonsuperuser from './_ep/nonsuperuser'
import role from './_ep/role'
import permission from './_ep/permission'
import permissionGroup from './_ep/permission_group'
import demo1 from './_ep/demo1'
import demo2 from './_ep/demo2'
import memberenrollmentspansmedical from './_ep/memberenrollmentspansmedical'
import memberenrollmentlatestrecord from './_ep/memberenrollmentlatestrecord'
import memberqualtiy from './_ep/memberqualtiy'
import memberpcphistory from './_ep/memberpcphistory'
import memberlist from './_ep/memberlist'
import memberhcccurrent from './_ep/memberhcccurrent'
import memberdetail from './_ep/memberdetail'
import memberchart from './_ep/memberchart'
import memberchartsv2 from './_ep/memberchartsv2'
import memberchartsfile from './_ep/memberchartsfile'
import memberchartsicdcode from './_ep/memberchartsicdcode'
import memberchartsactivityrecord from './_ep/memberchartsactivityrecord'
import educationtopics from './_ep/educationtopics'
import providereducation from './_ep/providereducation'
import file from './_ep/file'
import hccgap from './_ep/hccgap'
import icdhccrecord from './_ep/icdhccrecord'
import statuscode from './_ep/statuscode'
import qualitygap from './_ep/qualitygap'
import chart from './_ep/chart'
import appointment from './_ep/appointment'
import pcpgroup from './_ep/pcpgroup'
import pcprecord from './_ep/pcprecord'
import pcpname from './_ep/pcpname'
import pcpactivegroup from './_ep/pcpactivegroup'
import pcpactivename from './_ep/pcpactivename'
import membercpts from './_ep/membercpts'
import activevisittype from './_ep/activevisittype'
import memberchartauditlog from './_ep/memberchartauditlog'

export const history = createBrowserHistory()
export const reducers = {
  router: reducer(connectRouter(history)),
  settings: reducer(settings),
  common: reducer(common),
  mailApp: reducer(mailApp),
  todoApp: reducer(todoApp),
  contactApp: reducer(contactApp),
  ecommerce: reducer(ecommerce),
  scrumboardApp: reducer(scrumboardApp),
  chatApp: reducer(chatApp),
  wall: reducer(wall),
  gallery: reducer(gallery),
  dashboard: reducer(dashboard),
  editors: reducer(editors),
  userList: reducer(userList),
  ...superuser,
  ...nonsuperuser,
  ...role,
  ...permission,
  ...permissionGroup,
  ...demo1,
  ...demo2,
  ...memberenrollmentspansmedical,
  ...memberenrollmentlatestrecord,
  ...memberqualtiy,
  ...memberpcphistory,
  ...memberlist,
  ...memberhcccurrent,
  ...memberdetail,
  ...memberchart,
  ...memberchartsv2,
  ...memberchartsfile,
  ...memberchartsicdcode,
  ...memberchartsactivityrecord,
  ...educationtopics,
  ...providereducation,
  ...file,
  ...hccgap,
  ...icdhccrecord,
  ...statuscode,
  ...qualitygap,
  ...chart,
  ...appointment,
  ...pcprecord,
  ...pcpgroup,
  ...pcpname,
  ...pcpactivegroup,
  ...pcpactivename,
  ...membercpts,
  ...activevisittype,
  ...memberchartauditlog,
}
