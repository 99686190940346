import { Space, Popconfirm, Button, Select, Input } from 'antd'
import RemoteSelect from './RemoteSelect'

const { Option } = Select
const { TextArea } = Input

export default (
  statuses,
  news,
  queries,
  failures,
  icd10s,
  handleUpdate,
  handleSave,
  handleClear,
  payload,
  setListData,
  actions,
  handleCreate,
) => {
  return [
    {
      title: 'ICD10',
      dataIndex: 'icd10code',
      key: 'icd10code',
      render: (text, record, index) => (
        <>
          <RemoteSelect
            returnDuplicates={!record.icdcodeid}
            value={record.icd10code}
            onChange={v => {
              if (v) {
                if (record.icdcodeid) {
                  handleUpdate(record.icdcodeid, 'icd10code', v)
                } else {
                  const records = v.map(icd => {
                    return {
                      icd10code: icd.icd10,
                      icd10description: icd.icd10_desc,
                      hcccode: icd.hcc,
                      hcccodedescription: icd.hccdescription,
                    }
                  })
                  handleCreate(records)
                }
              }
            }}
          ></RemoteSelect>
        </>
      ),
    },
    {
      title: 'ICD10 Descr',
      dataIndex: 'icd10description',
      key: 'icd10description',

      render: (text, record) => <div style={{ width: '200px' }}>{text}</div>,
    },
    {
      title: 'HCC',
      dataIndex: 'hcccode',
      key: 'hcccode',
    },
    {
      title: 'HCC Description',
      dataIndex: 'hcccodedescription',
      key: 'hcccodedescription',
      render: (text, record) => <div style={{ width: '200px' }}>{text}</div>,
    },
    {
      title: 'Dx Source',
      dataIndex: 'codestatusdescription',
      key: 'codestatusdescription',
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.codestatusdescription}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.icdcodeid) {
                  handleUpdate(record.icdcodeid, 'codestatusid', v)
                } else {
                  record.codestatusid = v
                }
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            <Option></Option>
            {statuses &&
              statuses
                .filter(g => g.statuscode !== '')
                .map((g, index) => (
                  <Option key={index} value={g.statuscode}>
                    {g.codedescription}
                  </Option>
                ))}
          </Select>
        </>
      ),
    },
    {
      title: 'New',
      dataIndex: 'codenewdescription',
      key: 'codenewdescription',
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.codenewdescription}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.icdcodeid) {
                  handleUpdate(record.icdcodeid, 'codenewid', v)
                } else {
                  record.codenewid = v
                }
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {news &&
              news.map((g, index) => (
                <Option key={index} value={g.statuscode}>
                  {g.codedescription}
                </Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Dx Status',
      dataIndex: 'querystatusdescription',
      key: 'querystatusdescription',
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.querystatusdescription}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.icdcodeid) {
                  handleUpdate(record.icdcodeid, 'querystatusid', v)
                } else {
                  record.querystatusid = v
                }
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {queries &&
              queries.map((g, index) => (
                <Option key={index} value={g.statuscode}>
                  {g.codedescription}
                </Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Failure Reason',
      dataIndex: 'failuredescription',
      key: 'failuredescription',
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.failuredescription}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                if (record.icdcodeid) {
                  handleUpdate(record.icdcodeid, 'failureid', v)
                } else {
                  record.failureid = v
                }
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {failures &&
              failures.map((g, index) => (
                <Option key={index} value={g.statuscode}>
                  {g.codedescription}
                </Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Comments/Chart Prep Suspect',
      dataIndex: 'failurecomments',
      key: 'failurecomments',
      render: (text, record) => (
        <>
          <TextArea
            defaultValue={record?.failurecomments}
            onBlur={e => {
              if (record.failurecomments !== e.target.value) {
                if (record.icdcodeid) {
                  handleUpdate(record.icdcodeid, 'failurecomments', e.target.value)
                } else {
                  record.failurecomments = e.target.value
                }
              }
            }}
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder=""
          />
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          {record.icdcodeid !== 0 && (
            <Popconfirm
              title="Are you sure to delete this record?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                actions.deleteMembercharticdcode(record.icdcodeid)
              }}
            >
              <Button size="small" type="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]
}
