import { Row, Col } from 'antd'
import QualityGapList from './QualityGapList'

const QualityGap = props => (
  <Row gutter={16}>
    <Col span={24}>
      <QualityGapList {...props} />
    </Col>
  </Row>
)

export default QualityGap
