import React, { createContext, useContext, useState } from 'react'
import defaultConfig from './defaultConfig'
import PropTypes from 'prop-types'

const SidebarContext = createContext()
const SidebarActionsContext = createContext()

export const useSidebarContext = () => useContext(SidebarContext)

export const useSidebarActionsContext = () => useContext(SidebarActionsContext)

const SidebarContextProvider = ({ children }) => {
  const _sidebarToggleImage = localStorage.getItem('sidebarToggleImage')
  const _sidebarImage = localStorage.getItem('sidebarImage')
  const _sidebarColorSet = localStorage.getItem('sidebarColorSet')
    ? JSON.parse(localStorage.getItem('sidebarColorSet'))
    : null
  const _menuStyle = localStorage.getItem('menuStyle')

  const [menuStyle, updateMenuStyle] = useState(_menuStyle || defaultConfig.sidebar.menuStyle)
  const [sidebarColorSet, updateSidebarColorSet] = useState(
    _sidebarColorSet || defaultConfig.sidebar.colorSet,
  )
  const [isSidebarBgImage, setSidebarBgImage] = useState(
    _sidebarToggleImage || defaultConfig.sidebar.isSidebarBgImage,
  )
  const [sidebarBgImage, updateSidebarBgImage] = useState(
    _sidebarImage || defaultConfig.sidebar.sidebarBgImage,
  )
  const [sidebarMenuSelectedColor, updateSidebarMenuSelectedColor] = useState(
    defaultConfig.sidebar.sidebarMenuSelectedColor,
  )

  return (
    <SidebarContext.Provider
      value={{
        menuStyle,
        sidebarColorSet,
        isSidebarBgImage,
        sidebarBgImage,
        sidebarMenuSelectedColor,
      }}
    >
      <SidebarActionsContext.Provider
        value={{
          updateMenuStyle,
          updateSidebarColorSet,
          setSidebarBgImage,
          updateSidebarBgImage,
          updateSidebarMenuSelectedColor,
        }}
      >
        {children}
      </SidebarActionsContext.Provider>
    </SidebarContext.Provider>
  )
}

export default SidebarContextProvider

SidebarContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
