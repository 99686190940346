import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberenrollmentspansmedicalsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/memberenrollmentspansmedicals/`, { params })
export const createMemberenrollmentspansmedicalApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/memberenrollmentspansmedicals`, data)
export const getMemberenrollmentspansmedicalApi = id =>
  Api.get(`${API_URL}/api/v1/crm/memberenrollmentspansmedicals/${id}`)
