import React from 'react'
import { Layout } from 'antd'
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider'
import './AppFooter.style.less'

const { Footer } = Layout

const AppFooter = () => {
  const { footer } = useLayoutContext()

  if (footer) {
    return (
      <Footer className="app-main-footer">
        <p>Copy right Prominence CRM 2022</p>
      </Footer>
    )
  }

  return null
}

export default AppFooter
