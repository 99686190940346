import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listSuperUsersApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/superusers`, { params })
export const createSuperUserApi = (data = {}) => Api.post(`${API_URL}/api/v1/superusers`, data)
export const getSuperUserApi = id => Api.get(`${API_URL}/api/v1/superusers/${id}`)
export const deleteSuperUserApi = id => Api.delete(`${API_URL}/api/v1/superusers/${id}`)
export const updateSuperUserApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/superusers/${id}`, data)
