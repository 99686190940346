import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { sizePerPage, p } from '../../../shared/constants/AppConst'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../shared/helpers/permissions'

import tableColumns from './columns'
import qs from 'query-string'

const basePath = '/landing-page/abstractordashboard'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    summaryprovidereducations: s.summaryprovidereducations,
    nonSuperUsers: s.nonSuperUsers,
    providereducations: s.providereducations,
    pcpactivenames: s.pcpactivenames,
    pcpactivegroups: s.pcpactivegroups,
    isOpenProvidereducationsModal: s.isOpenProvidereducationsModal,
    educationtopics: s.educationtopics,
  }))
  const actions = useStoreActions(a => ({
    setSummaryProvidereducations: a.setSummaryProvidereducations,
    setPcpactivenames: a.setPcpactivenames,
    setNonSuperUsers: a.setNonSuperUsers,
    setIsOpenProvidereducationsModal: a.setIsOpenProvidereducationsModal,
    setEducationtopics: a.setEducationtopics,
    getNonSuperUsers: a.getNonSuperUsers,
    getSummaryProvidereducations: a.getSummaryProvidereducations,
    getProvidereducations: a.getProvidereducations,
    getPcpactivenames: a.getPcpactivenames,
    getPcpactivegroups: a.getPcpactivegroups,
    getEducationtopics: a.getEducationtopics,
  }))

  const [t, setT] = useState(null)
  const [users, setUsers] = useState(null)
  const [names, setNames] = useState(null)

  // const [groups, setGroups] = useState(null)
  const [selectedProviderEducation, setSelectedProviderEducation] = useState(null)
  const [allGroups, setAllGroups] = useState(null)

  // const [aggre, setAggre] = useState(null)
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    showSizeChanger: false,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
  })

  const [educationtopics, setEducationtopics] = useState(null)

  const onClickTableModal = (e, { topic, hcc }) => {
    e.preventDefault()
    setSelectedProviderEducation({ topic, hcc })

    actions.getProvidereducations({
      hcc,
      topic,
    })
  }

  // Columns
  const columns = tableColumns(onClickTableModal)

  useEffect(() => {
    // actions.getPcpactivegroups()
    actions.getNonSuperUsers({ with_created_chart: 1, per_page: 9999 })
    actions.getEducationtopics()
  }, [])

  useEffect(() => {
    if (queries?.providergrouptin) {
      const pcpgrptaxid = queries.providergrouptin || null

      pcpgrptaxid && actions.getPcpactivenames({ pcpgrptaxid })
    }
  }, [queries])

  useEffect(() => {
    const { status, payload } = state.nonSuperUsers

    if (status === 2 && payload) {
      const { results } = payload
      setUsers(results)

      actions.setNonSuperUsers({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setNonSuperUsers({ status: 0, payload: null })
    }
  }, [state.nonSuperUsers])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.pcpactivenames

    if (status === 2 && payload && payload.length > 0) {
      setNames(payload)
      actions.setPcpactivenames({ status: 0, payload: null })
    } else if (status === 3) {
      setNames(null)
      actions.setPcpactivenames({ status: 0, payload: null })
    }
  }, [state.pcpactivenames])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.pcpactivegroups

    if (status === 2) {
      // setGroups(payload)
      setAllGroups(payload)
    }
  }, [state.pcpactivegroups])

  useEffect(() => {
    const { status, payload } = state.educationtopics

    if (status === 2 && payload) {
      setEducationtopics(payload)
      actions.setEducationtopics({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setEducationtopics({ status: 0, payload: null })
    }
  }, [state.educationtopics])

  useEffect(() => {
    if (!has(auth.user, [p.PED_VIEW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(`${location.search}`)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    actions.getSummaryProvidereducations({ ...queries, per_page: 10000 })

    // if (queries?.providergrouptin || queries?.pcpnpi || queries?.educationtopics) {
    //   actions.getSummaryProvidereducations(queries)
    // }
  }, [queries])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.summaryprovidereducations

    if (status === 2) {
      const { results } = payload
      setListData(results)
    }
  }, [state.summaryprovidereducations])

  const onResultFilter = value => {
    const query = {
      ...queries,
      search: value,
      page: 1,
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const handleTopicChange = value => {
    const v = value || null

    setQueries({ ...queries, topic: v, page: 1 })
  }

  const handleUserChange = value => {
    const v = value || null

    setQueries({ ...queries, educator: v, page: 1 })
  }

  const handleRegionChange = value => {
    const v = value || null

    setQueries({ ...queries, region: v, page: 1 })
  }

  const handleProviderChange = value => {
    const v = value || null

    setQueries({ ...queries, pcpnpi: v, page: 1 })
  }

  const handleGroupChange = value => {
    const v = value || null
    v && actions.getPcpactivenames({ pcpgrptaxid: value })
    setNames(null)

    const query = {
      ...queries,
      providergrouptin: value,
      pcpnpi: '',
      page: 1,
    }

    setQueries(query)
  }

  const handleGroupSearch = search => {
    if (t) clearTimeout(t)

    setT(
      setTimeout(() => {
        actions.getPcpactivegroups({ search })
      }, 500),
    )
  }

  return children({
    state,
    columns,
    queries,
    listData,
    pagination,
    setT,
    setSelectedProviderEducation,
    selectedProviderEducation,
    onResultFilter,
    handleTopicChange,
    handleUserChange,
    handleRegionChange,
    handleProviderChange,
    handleGroupSearch,
    handleGroupChange,
    t,
    users,
    names,

    // groups,
    educationtopics,
    allGroups,
    actions,
  })
}

export default Container
