import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'
import qs from 'query-string'

const basePath = '/landing-page/memberdetails'

const Container = ({ children }) => {
  const query = qs.parse(location.search)
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    memberenrollmentlatestrecord: s.memberenrollmentlatestrecord,
    pcprecords: s.pcprecords,
    pcpgroups: s.pcpgroups,
    withpcps: s.withpcps,
    memberlists: s.memberlists,
  }))

  const actions = useStoreActions(a => ({
    getStatuscodes: a.getStatuscodes,
    setMemberenrollmentlatestrecord: a.setMemberenrollmentlatestrecord,
    getMemberenrollmentlatestrecord: a.getMemberenrollmentlatestrecord,
    getPcprecords: a.getPcprecords,
    getPcpgroups: a.getPcpgroups,
    getMemberlists: a.getMemberlists,
  }))

  const [memberDetails, setMemberDetails] = useState({})
  const [details, setDetails] = useState({})
  const [pcpDetails, setPcpDetails] = useState(null)
  const [activeTab, setActiveTab] = useState('member_details')
  const navigate = useNavigate()
  const auth = useAuthUser()

  useEffect(() => {
    if (query.chartid) {
      setActiveTab('charts')
    }

    if (query.tab) {
      setActiveTab(query.tab)
    }
  }, [query.chartid])

  useEffect(() => {
    if (
      !has(auth.user, [
        p.MEMBER_DETAILS_VIEW_SEARCH_PAGE,
        p.MEMBER_DETAILS_VIEW_DETAILS_PAGE,
        p.MEMBER_DETAILS,
      ])
    ) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  useEffect(() => {
    const { status, payload } = state.memberenrollmentlatestrecord

    if (status === 2) {
      setDetails(payload)
      actions.getPcprecords({ pcpnpi: payload.lastpcpid })
      const params = { pcpnpi: payload.lastpcpid, per_page: 9999 }

      if (state.withpcps) {
        params.withpcps = state.withpcps
      } else {
        params.withpcps = ''
      }

      actions.getPcpgroups(params)
    }
  }, [state.memberenrollmentlatestrecord, state.withpcps])

  useEffect(() => {
    const { status, payload } = state.pcprecords

    if (status === 2 && payload && payload.results) {
      const latest = payload.results[0]

      setPcpDetails({
        pcpname: details?.lastpcpname,
        providergroupname: typeof latest !== 'undefined' ? latest?.providergroupname : '',
      })
    }
  }, [state.pcpgroups])

  useEffect(() => {
    const { status, payload } = state.memberlists

    if (status === 2 && typeof payload.results[0] !== 'undefined') {
      setMemberDetails(payload.results[0])
    }
  }, [state.memberlists])

  // On Load
  useEffect(() => {
    // actions.getMemberpcphistories({ internalmemberid: id, ordering: '-startdate' })
    // actions.getPcpgroups({ ordering: '-startdate' })
    actions.getStatuscodes()
    actions.getMemberenrollmentlatestrecord(id)
    actions.getMemberlists({ memberid: query?.memberid })

    return () => {
      actions.setMemberenrollmentlatestrecord({ status: 0, payload: null })
    }
  }, [])

  return children({
    state,
    setActiveTab,
    query,
    id,
    details,
    basePath,
    memberDetails,
    activeTab,
    pcpDetails,
    setPcpDetails,
  })
}

export default Container
