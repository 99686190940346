import { thunk, action } from 'easy-peasy'
import {
  listMemberqualityrecordsApi,
  getMemberqualityrecordApi,
  updateMemberqualityrecordApi,
} from '../../api/memberquality'

export default {
  // List Memberqualityrecords
  memberqualityrecords: { status: 0, payload: null },
  setMemberqualityrecords: action((state, payload = null) => {
    state.memberqualityrecords = payload
  }),
  getMemberqualityrecords: thunk(async (actions, params = {}) => {
    actions.setMemberqualityrecords({ status: 1, payload: null })

    listMemberqualityrecordsApi(params)
      .then(response => actions.setMemberqualityrecords({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberqualityrecords({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberdetail
  memberqualityrecord: { status: 0, payload: null },
  setMemberqualityrecord: action((state, payload = null) => {
    state.memberqualityrecord = payload
  }),
  getMemberqualityrecord: thunk(async (actions, id) => {
    actions.setMemberqualityrecord({ status: 1, payload: null })

    getMemberqualityrecordApi(id)
      .then(response => actions.setMemberqualityrecord({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberqualityrecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Memberdetail
  updatedMemberqualityrecord: { status: 0, payload: null },
  setUpdatedMemberqualityrecord: action((state, payload = null) => {
    state.updatedMemberqualityrecord = payload
  }),
  updateMemberqualityrecord: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedMemberqualityrecord({ status: 1, payload: null })

    updateMemberqualityrecordApi(id, data)
      .then(response =>
        actions.setUpdatedMemberqualityrecord({ status: 2, payload: response.data }),
      )
      .catch(e =>
        actions.setUpdatedMemberqualityrecord({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
