import React from 'react'
import './shared/styles/crema.less'

import { StoreProvider } from 'easy-peasy'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from '@crema'
import Interceptors from '@crema/utility/Interceptor'
import configureStore from './redux/store'

import '@crema/services/index'

import OAuth2Provider from '@crema/services/auth/oauth2/OAuth2Provider'

const store = configureStore()

Interceptors.setupInterceptors(store)

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <StoreProvider store={store}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <OAuth2Provider>
                <AuthRoutes>
                  {process.env.REACT_APP_TEST_ENVIRONMENT && (
                    <div
                      style={{
                        backgroundColor: 'rgb(240, 70, 77)',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      THIS IS A TEST ENVIRONMENT AND SHOULD ONLY BE USED TO TEST NEW FEATURES BEFORE
                      THEY ARE PUSHED TO PRODUCTION ENVIRONMENT
                    </div>
                  )}
                  <AppLayout />
                </AuthRoutes>
              </OAuth2Provider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </StoreProvider>
    </Provider>
  </AppContextProvider>
)

export default App
