import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberchartsicdcodesApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/memberchartsicdcodes/`, { params })

export const updateMembercharticdcodesApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/crm/memberchartsicdcodes/${id}/`, data)

export const createMembercharticdcodesApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/crm/memberchartsicdcodes`, data)

export const getMemberchartsicdcodeApi = id =>
  Api.get(`${API_URL}/api/v1/crm/memberchartsicdcodes/${id}/`)

export const deleteMembercharticdcodesApi = id =>
  Api.delete(`${API_URL}/api/v1/crm/memberchartsicdcodes/${id}/`)
