import { thunk, action } from 'easy-peasy'
import { listCP10Api, updateCP10Api, createCP10Api } from '../../api/membercpts'

export default {
  // List CPT Code
  cptcodes: { status: 0, payload: null },
  setCptcodes: action((state, payload = null) => {
    state.cptcodes = payload
  }),
  getCptcodes: thunk(async (actions, params = {}) => {
    actions.setCptcodes({ status: 1, payload: null })

    listCP10Api(params)
      .then(response => actions.setCptcodes({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCptcodes({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),

  // Update CPT Code
  updatedCptcode: { status: 0, payload: null },
  setUpdatedCptcode: action((state, payload = null) => {
    state.updatedCptcode = payload
  }),
  updateCptcode: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedCptcode({ status: 1, payload: null })

    updateCP10Api(id, data)
      .then(response => actions.setUpdatedCptcode({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedCptcode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create CPT Code
  createdCptcode: { status: 0, payload: null },
  setCreatedCptcode: action((state, payload = null) => {
    state.createdCptcode = payload
  }),
  createCptcode: thunk(async (actions, data = {}) => {
    actions.setCreatedCptcode({ status: 1, payload: null })

    createCP10Api(data)
      .then(response => actions.setCreatedCptcode({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedCptcode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
