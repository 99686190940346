import { thunk, action } from 'easy-peasy'
import {
  listMemberchartsApi,
  createMemberchartApi,
  getMemberchartApi,
  updateMemberchartApi,
  deleteMemberchartApi,
} from '../../api/memberchart'

export default {
  // List Membercharts
  membercharts: { status: 0, payload: null },
  setMembercharts: action((state, payload = null) => {
    state.membercharts = payload
  }),
  getMembercharts: thunk(async (actions, params = {}) => {
    actions.setMembercharts({ status: 1, payload: null })

    listMemberchartsApi(params)
      .then(response => actions.setMembercharts({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMembercharts({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberchart
  memberchart: { status: 0, payload: null },
  setMemberchart: action((state, payload = null) => {
    state.memberchart = payload
  }),
  getMemberchart: thunk(async (actions, id) => {
    actions.setMemberchart({ status: 1, payload: null })

    getMemberchartApi(id)
      .then(response => actions.setMemberchart({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberchart({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Memberchart
  createdMemberchart: { status: 0, payload: null },
  setCreatedMemberchart: action((state, payload = null) => {
    state.createdMemberchart = payload
  }),
  createMemberchart: thunk(async (actions, data = {}) => {
    actions.setCreatedMemberchart({ status: 1, payload: null })

    createMemberchartApi(data)
      .then(response => actions.setCreatedMemberchart({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedMemberchart({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Memberchart
  updatedMemberchart: { status: 0, payload: null },
  setUpdatedMemberchart: action((state, payload = null) => {
    state.updatedMemberchart = payload
  }),
  updateMemberchart: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedMemberchart({ status: 1, payload: null })

    updateMemberchartApi(id, data)
      .then(response => actions.setUpdatedMemberchart({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedMemberchart({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Memberchart
  deletedMemberchart: { status: 0, payload: null },
  setDeletedMemberchart: action((state, payload = null) => {
    state.deletedMemberchart = payload
  }),
  deleteMemberchart: thunk(async (actions, id) => {
    actions.setDeletedMemberchart({ status: 1, payload: null })

    deleteMemberchartApi(id)
      .then(response => actions.setDeletedMemberchart({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedMemberchart({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
