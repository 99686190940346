import React from 'react'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'
import { Spin, Table, Typography, Row, Col, Alert } from 'antd'

const { Title } = Typography

const View = props => (
  <ViewComponent {...props}>
    {({ state, columns, listData }) => (
      <Spin
        spinning={
          state.providereducations.status === 1 ||
          state.createdPE.status === 1 ||
          state.updatedPE.status === 1
        }
      >
        <AppCard>
          <AppRowContainer>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={4}>
                  <Title level={2}>Provider</Title>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {state.providereducations.status === 3 ? (
                    <Alert
                      message={
                        state.providereducations.payload.details || 'Oops, something went wrong...'
                      }
                      type="error"
                    />
                  ) : (
                    <Table
                      columns={columns}
                      rowKey={record => record.id}
                      dataSource={listData}
                      pagination={false}
                      scroll={{
                        x: 1000,
                        y: 400,
                      }}
                      loading={
                        state.providereducations.status === 1 ||
                        state.createdPE.status === 1 ||
                        state.updatedPE.status === 1
                      }
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </AppRowContainer>
        </AppCard>
      </Spin>
    )}
  </ViewComponent>
)

export default View
