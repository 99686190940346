import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Divider,
  Alert,
  Card,
} from 'antd'
import moment from 'moment'

const { Option } = Select
const { Title } = Typography
const { RangePicker } = DatePicker

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      listData,
      pagination,
      setT,
      onTableChange,
      onResultFilter,
      handleGroupChange,
      handleGroupBlur,
      handleGroupClear,
      handleNameChange,
      handleNameBlur,
      handleNameClear,
      handleStatusChange,
      handleStatusBlur,
      handleDxStatusChange,
      handleDxStatusBlur,
      handleUserChange,
      handleUserBlur,
      handleDateRange,
      handlePostReviewDate,
      handleModifiedDateRange,
      handleAuditDateRange,
      handleAuditBy,
      handleVisitTypeChange,
      handleVisitTypeBlur,
      handleVisitTypeClear,
      t,
      groups,
      names,
      kpiCards,
      statuses,
      dxStatuses,
      users,
      allUsers,
      selectedMember,
      visitTypes,
      clearFilters,
      selectedStatus,
      selectedDxStatus,
      selectedVisitTypes,
      selectedGroups,
      selectedProviders,
    }) => (
      <>
        <AppPageMetadata title="Provider Groups" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={2}>Coder Dashboard</Title>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button onClick={clearFilters}>Clear filters</Button>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <Title level={5}>Assigned User</Title>
                {!users && <Select style={{ width: '100%' }} disabled />}
                {users && selectedMember && (
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    defaultValue={(queries && queries?.assigneduser__in) || selectedMember || []}
                    value={selectedMember}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All Assigned User"
                    onChange={handleUserChange}
                    onBlur={handleUserBlur}
                  >
                    {users &&
                      users.map((u, index) => (
                        <Option key={index} value={u.username}>
                          {u.username}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={3}>
                <Title level={5}>Status</Title>
                {!statuses && <Select style={{ width: '100%' }} disabled />}
                {statuses && (
                  <Select
                    showSearch
                    mode="multiple"
                    defaultValue={
                      (queries &&
                        statuses.filter(x => x.statuscode === queries?.statusid__in).length > 0 &&
                        queries?.statusid__in) ||
                      selectedStatus
                    }
                    value={selectedStatus}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All Status"
                    onChange={handleStatusChange}
                    onBlur={handleStatusBlur}
                    allowClear
                  >
                    {statuses &&
                      statuses.map((g, index) => (
                        <Option key={index} value={g.statuscode}>
                          {g.codedescription}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={3}>
                <Title level={5}>DX Status</Title>
                {!dxStatuses && <Select style={{ width: '100%' }} disabled />}
                {dxStatuses && (
                  <Select
                    showSearch
                    mode="multiple"
                    defaultValue={
                      queries?.dxstatus__in && queries?.dxstatus__in.split(',').length > 0
                        ? queries?.dxstatus__in.split(',')
                        : selectedDxStatus
                    }
                    value={selectedDxStatus}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All DX Status"
                    onChange={handleDxStatusChange}
                    onBlur={handleDxStatusBlur}
                    allowClear
                  >
                    {dxStatuses &&
                      dxStatuses.map((g, index) => (
                        <Option key={index} value={g.statuscode}>
                          {g.codedescription}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
              <Col span={4}>
                <Title level={5}>Visit Type</Title>
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  defaultValue={
                    queries?.typeid__in && queries?.typeid__in.split(',').length > 0
                      ? queries?.typeid__in.split(',')
                      : selectedVisitTypes
                  }
                  value={selectedVisitTypes}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: '100%' }}
                  onChange={handleVisitTypeChange}
                  onBlur={handleVisitTypeBlur}
                  onClear={handleVisitTypeClear}
                >
                  {visitTypes &&
                    visitTypes.map((v, index) => (
                      <Option key={v.typeid} value={v.typeid}>
                        {v.typename}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={4}>
                <Title level={5}>Rendering Group</Title>
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  defaultValue={
                    queries?.providergrouptin__in &&
                    queries?.providergrouptin__in.split(',').length > 0
                      ? queries?.providergrouptin__in.split(',')
                      : selectedGroups
                  }
                  value={selectedGroups}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: '100%' }}
                  onChange={handleGroupChange}
                  onBlur={handleGroupBlur}
                  onClear={handleGroupClear}
                >
                  {groups &&
                    groups.map((g, index) => (
                      <Option key={index} value={g.pcpgrptaxid}>
                        {g.pcpgroupname}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={4}>
                <Title level={5}>Rendering Provider</Title>
                {!names && <Select style={{ width: '100%' }} disabled />}
                {names && (
                  <Select
                    showSearch
                    mode="multiple"
                    defaultValue={
                      queries?.pcpnpi__in && queries?.pcpnpi__in.split(',').length > 0
                        ? queries?.pcpnpi__in.split(',')
                        : selectedProviders
                    }
                    value={selectedProviders}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="All PCP"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    onClear={handleNameClear}
                    allowClear
                  >
                    {names.map((n, index) => (
                      <Option key={index} value={n.pcp_npi}>
                        {n.pcpfullname}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col span={5}>
                <Title level={5}>Appointment Date</Title>
                <RangePicker
                  value={
                    queries && [
                      queries.appointmentdate__gte ? moment(queries.appointmentdate__gte) : null,
                      queries.appointmentdate__lte ? moment(queries.appointmentdate__lte) : null,
                    ]
                  }
                  onChange={handleDateRange}
                />
              </Col>
              <Col span={5}>
                <Title level={5}>Post Review Completed Date</Title>
                <RangePicker
                  value={
                    queries && [
                      queries.postreviewcompleteddate__gte
                        ? moment(queries.postreviewcompleteddate__gte)
                        : null,
                      queries.postreviewcompleteddate__lte
                        ? moment(queries.postreviewcompleteddate__lte)
                        : null,
                    ]
                  }
                  onChange={handlePostReviewDate}
                />
              </Col>
              <Col span={5}>
                <Title level={5}>Modified Date</Title>
                <RangePicker
                  value={
                    queries && [
                      queries.modifieddate__gte ? moment(queries.modifieddate__gte) : null,
                      queries.modifieddate__lte ? moment(queries.modifieddate__lte) : null,
                    ]
                  }
                  onChange={handleModifiedDateRange}
                />
              </Col>
              <Col span={5}>
                <Title level={5}>Audit Date</Title>
                <RangePicker
                  value={
                    queries && [
                      queries.auditdate__gte ? moment(queries.auditdate__gte) : null,
                      queries.auditdate__lte ? moment(queries.auditdate__lte) : null,
                    ]
                  }
                  onChange={handleAuditDateRange}
                />
              </Col>
              <Col span={3}>
                <Title level={5}>Audit By</Title>
                {!allUsers && <Select style={{ width: '100%' }} disabled />}
                {allUsers && (
                  <Select
                    showSearch
                    value={queries.audit_by}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={value => handleAuditBy(value)}
                    style={{ width: '100%' }}
                    placeholder="Select Audit By"
                  >
                    {allUsers &&
                      allUsers.map((au, index) => (
                        <Option key={index} value={au.username}>
                          {au.username}
                        </Option>
                      ))}
                  </Select>
                )}
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              {kpiCards &&
                Object.keys(kpiCards).map(
                  (k, i) =>
                    statuses.filter(x => x.statuscode === k).length > 0 && (
                      <Col
                        span={i === 0 && Object.keys(kpiCards).length > 4 ? 4 : 5}
                        key={k}
                        className="card-container"
                      >
                        <Card>
                          <Row style={{ marginTop: 20 }}>
                            <Col span={24} className="text-center">
                              <Title level={2}>{kpiCards[k]}</Title>
                              <span>
                                {statuses.filter(x => x.statuscode === k)[0]?.codedescription}
                              </span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ),
                )}
            </Row>
            <Divider />
            {queries.pcpnpi ||
            queries.providergrouptin ||
            queries.assigneduser__in ||
            queries.assigneduser__in === '' ||
            queries.appointmentdate__gt ||
            queries.appointmentdate__lt ||
            queries.postreviewcompleteddate__gt ||
            queries.postreviewcompleteddate__lt ||
            queries.modifieddate__gt ||
            queries.modifieddate__lt ||
            queries.auditdate__gt ||
            queries.auditdate__lt ||
            queries.audit_by ||
            queries.dxstatus__in ? (
              <>
                <Row>
                  <Col span={12}>
                    {state.memberchartsv2.status === 2 && (
                      <Title level={4}>{state.memberchartsv2.payload.count} item(s) found</Title>
                    )}
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Input
                      style={{ maxWidth: 300 }}
                      size="large"
                      placeholder="Search From Results"
                      onChange={e => {
                        if (t) clearTimeout(t)

                        setT(
                          setTimeout(() => {
                            onResultFilter(e.target.value)
                          }, 500),
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {state.memberchartsv2.status === 3 ? (
                      <Alert
                        message={
                          state.memberchartsv2.payload.details || 'Oops, something went wrong...'
                        }
                        type="error"
                      />
                    ) : (
                      <Table
                        columns={columns}
                        rowKey={record => record.chartid}
                        dataSource={listData}
                        pagination={pagination}
                        loading={state.memberchartsv2.status === 1}
                        onChange={onTableChange}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </AppRowContainer>
      </>
    )}
  </ViewComponent>
)

export default View
