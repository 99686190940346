import moment from 'moment'
import { Popconfirm, Button } from 'antd'

export default actions => {
  return [
    {
      title: 'Status',
      dataIndex: 'activitystatusname',
      key: 'activitystatusname',
    },
    {
      title: 'Status Date',
      dataIndex: 'modifieddate',
      key: 'modifieddate',
      render: (text, record) => `${moment(record?.createddate).format('MM/DD/YYYY')}`,
    },
    {
      title: 'Created By',
      dataIndex: 'createdby',
      key: 'createdby',
    },
    {
      title: 'Notes',
      dataIndex: 'activitynotes',
      key: 'activitynotes',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure to delete this record?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            actions.deleteMemberchartactivityrecord(record.activityid)
          }}
        >
          <Button size="small" type="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ]
}
