/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'
import { listPcpnamesApi } from 'api/pcpnames'

import tableColumns from './columns'

const Container = ({ children, id }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    educationtopics: s.educationtopics,
    pcpgroups: s.pcpgroups,
    pcpnames: s.pcpnames,
    providereducations: s.providereducations,
    updatedPE: s.updatedPE,
    createdPE: s.createdPE,
    deletedPE: s.deletedPE,
  }))
  const actions = useStoreActions(a => ({
    setWithPcps: a.setWithPcps,
    setEducationtopics: a.setEducationtopics,
    getEducationtopics: a.getEducationtopics,
    setProvidereducations: a.setProvidereducations,
    getPcpgroups: a.getPcpgroups,
    getProvidereducations: a.getProvidereducations,
    setPcpnames: a.setPcpnames,
    getPcpnames: a.getPcpnames,
    updatePE: a.updatePE,
    setUpdatedPE: a.setUpdatedPE,
    createPE: a.createPE,
    setCreatedPE: a.setCreatedPE,
    setDeletedPE: a.setDeletedPE,
    deletePE: a.deletePE,
  }))

  // Local states
  // Columns
  const [groups, setGroups] = useState(null)
  const [newGroupsEntry, setNewGroupsEntry] = useState(null)

  // const [columns, setColumns] = useState(null)
  const [listData, setListData] = useState([])
  const [isFirstGroupFetch, setIsFirstGroupFetch] = useState(false)
  const [educationtopics, setEducationtopics] = useState(null)

  const handleUpdate = (id, name, v) => {
    const data = { [name]: v }
    actions.updatePE({ id, ...data })
  }

  const handleSave = data => {
    const { topic_id, details, providergrouptin, pcpnpi, coverage, comments } = data

    if (!topic_id || !details || !providergrouptin || !pcpnpi || !coverage || !comments) {
      message.error('Please fill out the fields')
    } else {
      delete data.providerorpcp
      data.internalmemberid = id

      actions.createPE(data)
    }
  }

  const handleChangeGroupChange = async (record, params) => {
    setNewGroupsEntry(null)
    setIsFirstGroupFetch(true)

    if (groups && groups.length) {
      params = {
        ...params,
        per_page: 9999,
      }

      const pcpNames = await listPcpnamesApi(params)
      const groupsAdjusted = groups

      groupsAdjusted.forEach((g, index) => {
        if (g.value === record.providergrouptin && record.id !== 0) {
          groupsAdjusted[index].pcps = pcpNames?.data?.results
        } else if (g.value === record.providergrouptin && record.id === 0) {
          setNewGroupsEntry({
            pcps: pcpNames.data.results,
          })
        }
      })

      setGroups(groupsAdjusted)
    }
  }

  const handleDeletePE = id => {
    actions.deletePE({ id })
  }

  const columns = tableColumns(
    state,
    newGroupsEntry,
    groups,
    educationtopics,
    handleUpdate,
    handleSave,
    handleChangeGroupChange,
    handleDeletePE,
  )

  // Side effect of Names
  useEffect(() => {
    actions.getPcpgroups({ per_page: 9999 })
    setIsFirstGroupFetch(true)

    return () => {
      actions.setWithPcps(null)
    }
  }, [])

  // Side effect of Delete
  useEffect(() => {
    const { status, payload } = state.deletedPE

    if (status === 2) {
      // Show show success message
      message.success('Succefully deleted')

      actions.getProvidereducations({ internalmemberid: id })

      // Reset State
      actions.setDeletedPE({ status: 0, payload: null })
    } else if (status === 3) {
      // Show error message
      message.error(payload.details || 'Oops, something went wrong')

      // Reset State
      actions.setDeletedPE({ status: 0, payload: null })
    }
  }, [state.deletedPE])

  useEffect(() => {
    if (groups && groups.length === 0 && isFirstGroupFetch === true) {
      actions.getPcpgroups({ per_page: 9999 })
    }
  }, [groups])

  useEffect(() => {
    const { status, payload } = state.pcpgroups

    if (status === 2) {
      setGroups(
        payload?.results.map((row, index) => ({
          key: index,
          value: row.providergrouptin,
          label: row.providergroupname,
          pcps: row.pcps || [],
        })),
      )
    }
  }, [state.pcpgroups])

  useEffect(() => {
    actions.getEducationtopics()
    actions.getProvidereducations({ internalmemberid: id })
  }, [])

  useEffect(() => {
    const { status, payload } = state.educationtopics

    if (status === 2 && payload) {
      setEducationtopics(payload)
      actions.setEducationtopics({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setEducationtopics({ status: 0, payload: null })
    }
  }, [state.educationtopics])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.providereducations

    if (status === 2) {
      const { results } = payload

      actions.setWithPcps(results.map(row => row.providergrouptin).join(','))

      results.push({
        id: 0,
        topic_id: '',
        details: '',
        providerorpcp: '',
        coverage: '',
        comments: '',
      })

      setListData(results)

      actions.setProvidereducations({ status: 0, payload: null })
    } else if (status === 3) {
      setListData(null)
      actions.setProvidereducations({ status: 0, payload: null })
    }
  }, [state.providereducations])

  // Side effect of createdPE
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdPE

    if (status === 2) {
      setListData(listData.filter(p => p.id !== 0))
      message.success('Successfully added new record')
      actions.setCreatedPE({ status: 0, payload: null })
      actions.getProvidereducations({ internalmemberid: id })
    } else if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedPE({ status: 0, payload: null })
    }
  }, [state.createdPE])

  return children({
    state,
    columns,
    listData,
  })
}

export default Container
