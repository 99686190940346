import { thunk, action } from 'easy-peasy'
import { listStatusCodesApi } from '../../api/statuscode'

export default {
  // List Statuscodes
  statuscodes: { status: 0, payload: null },
  setStatuscodes: action((state, payload = null) => {
    state.statuscodes = payload
  }),
  getStatuscodes: thunk(async (actions, params = {}) => {
    actions.setStatuscodes({ status: 1, payload: null })

    listStatusCodesApi(params)
      .then(response => actions.setStatuscodes({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setStatuscodes({
          status: 2,
          payload: { results: [] },
        }),
      )
  }),
}
