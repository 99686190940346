import { thunk, action } from 'easy-peasy'
import {
  listSuperUsersApi,
  getSuperUserApi,
  createSuperUserApi,
  updateSuperUserApi,
  deleteSuperUserApi,
} from '../../api/superuser'

export default {
  // List SuperUsers
  superUsers: { status: 0, payload: null },
  setSuperUsers: action((state, payload = null) => {
    state.superUsers = payload
  }),
  getSuperUsers: thunk(async (actions, params = {}) => {
    actions.setSuperUsers({ status: 1, payload: null })

    listSuperUsersApi(params)
      .then(response => actions.setSuperUsers({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setSuperUsers({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create SuperUser
  createdSuperUser: { status: 0, payload: null },
  setCreatedSuperUser: action((state, payload = null) => {
    state.createdSuperUser = payload
  }),
  createSuperUser: thunk(async (actions, data = {}) => {
    actions.setCreatedSuperUser({ status: 1, payload: null })

    createSuperUserApi(data)
      .then(response => actions.setCreatedSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve SuperUser
  superUser: { status: 0, payload: null },
  setSuperUser: action((state, payload = null) => {
    state.superUser = payload
  }),
  getSuperUser: thunk(async (actions, id) => {
    actions.setSuperUser({ status: 1, payload: null })

    getSuperUserApi(id)
      .then(response => actions.setSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete SuperUser
  deletedSuperUser: { status: 0, payload: null },
  setDeletedSuperUser: action((state, payload = null) => {
    state.deletedSuperUser = payload
  }),
  deleteSuperUser: thunk(async (actions, id) => {
    actions.setDeletedSuperUser({ status: 1, payload: null })

    deleteSuperUserApi(id)
      .then(response => actions.setDeletedSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update SuperUser
  updatedSuperUser: { status: 0, payload: null },
  setUpdatedSuperUser: action((state, payload = null) => {
    state.updatedSuperUser = payload
  }),
  updateSuperUser: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedSuperUser({ status: 1, payload: null })

    updateSuperUserApi(id, data)
      .then(response => actions.setUpdatedSuperUser({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedSuperUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
