export const has = (user, permission) => {
  let permitted = false

  if (user && user.is_superuser) {
    permitted = true
  }

  if (!user.is_superuser) {
    if (Array.isArray(permission)) {
      for (const p of permission.values()) {
        if (user.permissions.map(p => p.code).indexOf(p) > -1) {
          permitted = true
        }
      }
    } else {
      permitted = permission && user.permissions.map(p => p.code).indexOf(permission) > -1
    }
  }

  return permitted
}
