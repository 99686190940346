import PropTypes from 'prop-types'
import { Typography, Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { REGIONS } from 'shared/constants/AppConst'

const { Title } = Typography
const { Option } = Select

const ProviderEducationFilters = ({
  queries,
  users,
  names,
  groups,
  handleTopicChange,
  handleUserChange,
  handleRegionChange,
  handleGroupSearch,
  handleGroupChange,
  handleProviderChange,
  educationtopics,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
      <div>
        <Title level={5}>Rendering Group</Title>
        <Select
          style={{ width: 200 }}
          showSearch
          value={(queries && queries?.providergrouptin) || null}
          placeholder="Render Group"
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={handleGroupSearch}
          onChange={handleGroupChange}
          notFoundContent={null}
          defaultValue={(queries && queries?.providergrouptin) || null}
          allowClear
        >
          {groups.status === 1 && (
            <Option disabled>
              <LoadingOutlined /> Searching...
            </Option>
          )}

          {groups.status === 2 &&
            groups.payload.map((g, index) => (
              <Option key={index} value={g.pcpgrptaxid}>
                {g.pcpgroupname}
              </Option>
            ))}

          {groups.status === 2 && groups.payload.length === 0 && (
            <Option disabled>No Result</Option>
          )}
        </Select>
      </div>
      <div>
        <Title level={5}>Region</Title>
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: 200 }}
          onChange={handleRegionChange}
          placeholder="Region"
        >
          {REGIONS.map(region => (
            <Option key={region.value} value={region.value}>
              {region.label}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <Title level={5}>Provider</Title>

        {!names && <Select style={{ width: 200 }} disabled />}
        {names && (
          <Select
            showSearch
            defaultValue={
              (queries &&
                names.filter(x => x.pcp_npi === queries?.pcpnpi).length > 0 &&
                queries?.pcpnpi) ||
              null
            }
            value={queries && queries?.pcpnpi}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 200 }}
            placeholder="All PCP"
            onChange={handleProviderChange}
            allowClear
          >
            {names.map((n, index) => (
              <Option key={index} value={n.pcp_npi}>
                {n.pcpfullname}
              </Option>
            ))}
          </Select>
        )}
      </div>
      <div>
        <Title level={5}>Topic</Title>

        {!educationtopics && <Select style={{ width: 200 }} disabled />}
        {educationtopics && (
          <Select
            showSearch
            allowClear
            defaultValue={(queries && queries?.educationtopics) || null}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 200 }}
            placeholder="Provider"
            onChange={handleTopicChange}
          >
            {educationtopics &&
              educationtopics.map((et, index) => (
                <Option key={index} value={et.id}>
                  {et.name}
                </Option>
              ))}
          </Select>
        )}
      </div>
      <div>
        <Title level={5}>Coder</Title>
        {!users && <Select style={{ width: 200 }} disabled />}
        {users && (
          <Select
            showSearch
            allowClear
            defaultValue={(queries && queries?.educator) || null}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 200 }}
            placeholder="Coder"
            onChange={handleUserChange}
          >
            {users &&
              users.map((u, index) => (
                <Option key={index} value={u.username}>
                  {u.username}
                </Option>
              ))}
          </Select>
        )}
      </div>
    </div>
  )
}

ProviderEducationFilters.defaultProps = {
  users: null,
  names: null,
  groups: null,
  educationtopics: null,
}

ProviderEducationFilters.propTypes = {
  queries: PropTypes.shape({
    providergrouptin: PropTypes.string,
    pcpnpi: PropTypes.string,
    educationtopics: PropTypes.string,
    educator: PropTypes.string,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  names: PropTypes.arrayOf(PropTypes.shape({})),
  handleTopicChange: PropTypes.func.isRequired,
  handleUserChange: PropTypes.func.isRequired,
  handleRegionChange: PropTypes.func.isRequired,
  handleProviderChange: PropTypes.func.isRequired,
  handleGroupSearch: PropTypes.func.isRequired,
  handleGroupChange: PropTypes.func.isRequired,
  groups: PropTypes.shape({
    status: PropTypes.number,
    payload: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
  }),
  educationtopics: PropTypes.arrayOf(PropTypes.shape({})),
}

export default ProviderEducationFilters
