import { listActiveVisitTypesApi } from 'api/activevisittypes'
import { thunk, action } from 'easy-peasy'

export default {
  activevisittypes: { status: 0, payload: null },
  setActiveVisitTypes: action((state, payload = null) => {
    state.activevisittypes = payload
  }),
  getActiveVisitTypes: thunk(async (actions, params = {}) => {
    actions.setActiveVisitTypes({ status: 1, payload: null })

    listActiveVisitTypesApi(params)
      .then(response => {
        return actions.setActiveVisitTypes({ status: 2, payload: response.data })
      })
      .catch(e =>
        actions.setActiveVisitTypes({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
