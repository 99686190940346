import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listPermissionsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/permissions`, { params })
export const createPermissionApi = (data = {}) => Api.post(`${API_URL}/api/v1/permissions`, data)
export const getPermissionApi = id => Api.get(`${API_URL}/api/v1/permissions/${id}`)
export const deletePermissionApi = id => Api.delete(`${API_URL}/api/v1/permissions/${id}`)
export const updatePermissionApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/permissions/${id}`, data)
