const commonValidations = {
  name: [{ required: true, message: 'Name is required.' }],
  permissions: [{ required: true, message: 'Permissions is/are required.' }],
}

const createValidations = {
  ...commonValidations,
}

export const _rules = (id, name) => {
  // Return Create Validations if New
  if (id === 'new') {
    return createValidations[name] || []
  }

  return commonValidations[name] || []
}
