import { useState } from 'react'
import { listIcdhccrecordsApi } from '../../../../../../../api/icdhccrecord'

const Container = ({ children, value, onChange, returnDuplicates }) => {
  const [data, setData] = useState([])
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = newValue => {
    setCurrentValue(newValue)

    const [record] = data.filter(d => d.id === newValue)
    const icd10 = record.icd10
    const duplicateRecords = data.filter(d => d.icd10 === icd10)

    if (onChange) {
      if (returnDuplicates) {
        onChange(duplicateRecords)
      } else {
        onChange([record])
      }
    }
  }

  const handleSearch = newValue => {
    if (newValue && newValue.length > 2) {
      const params = {
        page: 1,
        per_page: 50,
        search: newValue,
        ordering: 'icd10',
      }

      listIcdhccrecordsApi(params)
        .then(response => {
          if (response && response.data.results) {
            setData(response.data.results)
          }
        })
        .catch(e => e)
    } else {
      setData([])
    }
  }

  return children({
    data,
    value,
    currentValue,
    handleSearch,
    handleChange,
  })
}

export default Container
