import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'
import qs from 'query-string'

import tableColumns from './columns'

const Container = ({ children, setCharId }) => {
  const query = qs.parse(location.search)
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    updatedMemberchart: s.updatedMemberchart,
    membercharts: s.membercharts,
    memberchartsicdcodes: s.memberchartsicdcodes,
    memberchartsactivityrecords: s.memberchartsactivityrecords,
    deletedMemberchart: s.deletedMemberchart,
    createdMemberchart: s.createdMemberchart,
  }))
  const actions = useStoreActions(a => ({
    setMembercharts: a.setMembercharts,
    setDeletedMemberchart: a.setDeletedMemberchart,
    getMembercharts: a.getMembercharts,
    deleteMemberchart: a.deleteMemberchart,
    getNonSuperUsers: a.getNonSuperUsers,
  }))

  // Local states
  const [listData, setListData] = useState([])

  // Columns
  const [columns, setColumns] = useState(null)
  const [viewForm, setViewForm] = useState(false)
  const [chartid, setChartid] = useState(null)

  const addMember = () => {
    setViewForm(!viewForm)
    setChartid(null)
  }

  // const editChart = chartId => {
  //   setViewForm(true)
  //   setChartid(chartId)
  // }

  useEffect(() => {
    actions.getMembercharts({ internalmemberid: id })
    actions.getNonSuperUsers()
  }, [])

  useEffect(() => {
    if (query.chartid) {
      setCharId(Number(query.chartid))
    }
  }, [query.chartid])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.membercharts

    if (status === 2) {
      // Set created columns
      setColumns(tableColumns(setCharId, actions))

      setListData(payload)

      actions.setMembercharts({ status: 0, payload: null })
    } else if (status === 3) {
      setListData(null)
      actions.setMembercharts({ status: 0, payload: null })
    }
  }, [state.membercharts])

  useEffect(() => {
    const { status, payload } = state.deletedMemberchart

    if (status === 2) {
      actions.getMembercharts({ internalmemberid: id })
      actions.setDeletedMemberchart({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setDeletedMemberchart({ status: 0, payload: null })
      message.error(`Oops, something went wrong!: ${JSON.stringify(payload)}`)
    }
  }, [state.deletedMemberchart])

  // Side effect of updatedMembercharticdcode
  useEffect(() => {
    // code here will execute
    const { status } = state.updatedMemberchart

    if (status === 2) {
      setViewForm(false)
      setChartid(null)
    }
  }, [state.updatedMemberchart])

  // Side effect of updatedMembercharticdcode
  useEffect(() => {
    // code here will execute
    const { status } = state.createdMemberchart

    if (status === 2) {
      setViewForm(false)
      setChartid(null)
    }
  }, [state.createdMemberchart])

  return children({
    state,
    columns,
    listData,
    addMember,
    setViewForm,
    viewForm,
    chartid,
  })
}

export default Container
