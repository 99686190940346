/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import ViewComponent from './container'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Spin, Table, Row, Col, Typography, Alert, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ChartHistoryForm from '../ChartHistoryForm'

const { Title } = Typography

const View = ({ setCharId }) => (
  <ViewComponent setCharId={setCharId}>
    {({ state, columns, listData, addMember, setViewForm, viewForm, chartid }) => (
      <Spin
        spinning={
          state.membercharts.status === 1 ||
          state.memberchartsicdcodes.status === 1 ||
          state.memberchartsactivityrecords.status === 1
        }
      >
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                {viewForm && (
                  <Modal
                    title=""
                    width={900}
                    visible={viewForm}
                    footer={null}
                    maskClosable={true}
                    destroyOnClose={true}
                    onCancel={() => setViewForm(false)}
                  >
                    <Row gutter={16} style={{ paddingTop: 25 }}>
                      <Col span={24}>
                        <ChartHistoryForm chartid={chartid} />
                      </Col>
                    </Row>
                  </Modal>
                )}
              </Col>
              <Col span={12} style={{ marginBottom: '0px' }}>
                <Title level={2}>Chart History</Title>
              </Col>
              <Col span={12} className="text-right" style={{ marginBottom: '0px' }}>
                <a
                  style={{
                    flex: 'none',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={() => addMember()}
                >
                  <PlusOutlined /> Add item
                </a>
              </Col>
              <Col span={24}>
                {state.membercharts.status === 3 ? (
                  <Alert
                    message={state.membercharts.payload.details || 'Oops, something went wrong...'}
                    type="error"
                  />
                ) : (
                  <Table
                    columns={columns}
                    rowKey={record => record.chartid}
                    dataSource={listData}
                    pagination={false}
                    scroll={{
                      x: 500,
                      y: 300,
                    }}
                    loading={state.membercharts.status === 1}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View

View.propTypes = {
  setCharId: PropTypes.func,
}
