export const listQualitygapsApi = (params = {}) => {
  return new Promise(resolve => {
    const data = {
      count: 20,
      per_page: 10,
      total_pages: 2,
      results: [],
    }
    resolve({ data })
  })
}
