import { thunk, action } from 'easy-peasy'
import {
  listAppointmentsApi,
  getAppointmentApi,
  createAppointmentApi,
  updateAppointmentApi,
  deleteAppointmentApi,
} from '../../api/appointment'

export default {
  // List Appointments
  appointments: { status: 0, payload: null },
  setAppointments: action((state, payload = null) => {
    state.appointments = payload
  }),
  getAppointments: thunk(async (actions, params = {}) => {
    actions.setAppointments({ status: 1, payload: null })

    listAppointmentsApi(params)
      .then(response => actions.setAppointments({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setAppointments({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Appointment
  createdAppointment: { status: 0, payload: null },
  setCreatedAppointment: action((state, payload = null) => {
    state.createdAppointment = payload
  }),
  createAppointment: thunk(async (actions, data = {}) => {
    actions.setCreatedAppointment({ status: 1, payload: null })

    createAppointmentApi(data)
      .then(response => actions.setCreatedAppointment({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedAppointment({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Appointment
  appointment: { status: 0, payload: null },
  setAppointment: action((state, payload = null) => {
    state.appointment = payload
  }),
  getAppointment: thunk(async (actions, id) => {
    actions.setAppointment({ status: 1, payload: null })

    getAppointmentApi(id)
      .then(response => actions.setAppointment({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setAppointment({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Appointment
  deletedAppointment: { status: 0, payload: null },
  setDeletedAppointment: action((state, payload = null) => {
    state.deletedAppointment = payload
  }),
  deleteAppointment: thunk(async (actions, id) => {
    actions.setDeletedAppointment({ status: 1, payload: null })

    deleteAppointmentApi(id)
      .then(response => actions.setDeletedAppointment({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedAppointment({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Appointment
  updatedAppointment: { status: 0, payload: null },
  setUpdatedAppointment: action((state, payload = null) => {
    state.updatedAppointment = payload
  }),
  updateAppointment: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedAppointment({ status: 1, payload: null })

    updateAppointmentApi(id, data)
      .then(response => actions.setUpdatedAppointment({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedAppointment({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
