import { thunk, action } from 'easy-peasy'
import { listPcpactivegroupsApi, getPcpactivegroupsApi } from '../../api/pcpactivegroups'

export default {
  // List Pcpactivegroups
  pcpactivegroups: { status: 0, payload: null },
  setPcpactivegroups: action((state, payload = null) => {
    state.pcpactivegroups = payload
  }),
  getPcpactivegroups: thunk(async (actions, params = {}) => {
    actions.setPcpactivegroups({ status: 1, payload: null })

    listPcpactivegroupsApi(params)
      .then(response => actions.setPcpactivegroups({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpactivegroups({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve pcpactivegroup
  pcpactivegroup: { status: 0, payload: null },
  setPcpactivegroup: action((state, payload = null) => {
    state.pcpactivegroup = payload
  }),
  getPcpactivegroup: thunk(async (actions, id) => {
    actions.setPcpactivegroup({ status: 1, payload: null })

    getPcpactivegroupsApi(id)
      .then(response => actions.setPcpactivegroup({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPcpactivegroup({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
