import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message, Form } from 'antd'
import moment from 'moment'

const initialValues = {
  typeid: '',
  appointmentdate: '',
  providergrouptin: '',
  statusid: '',
  pcpnpi: '',
  pcpname: '',
  sourceid: '',
  assigneduser: '',
  auditby: '',
  auditdate: '',
}

const Container = ({ children, chartid }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    updatedMemberchart: s.updatedMemberchart,
    memberchart: s.memberchart,
    pcpgroups: s.pcpgroups,
    pcpnames: s.pcpnames,
    nonSuperUsers: s.nonSuperUsers,
  }))

  const actions = useStoreActions(a => ({
    updateMemberchart: a.updateMemberchart,
    setUpdatedMemberchart: a.setUpdatedMemberchart,
    getMemberchart: a.getMemberchart,
    setMemberchart: a.setMemberchart,
    getMembercharts: a.getMembercharts,
    getPcpgroups: a.getPcpgroups,
    getPcpnames: a.getPcpnames,
    setPcpnames: a.setPcpnames,
    getNonSuperUsers: a.getNonSuperUsers,
    getMemberchartsactivityrecords: a.getMemberchartsactivityrecords,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [formValues, setFormValues] = useState(initialValues)
  const [details, setDetails] = useState(null)
  const [types, setTypes] = useState(null)
  const [sources, setSources] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [groups, setGroups] = useState(null)
  const [names, setNames] = useState(null)
  const [t, setT] = useState(null)
  const [isIsFirstGroupFetch, setIsFirstGroupFetch] = useState(false)

  const onFinish = values => {
    values.internalmemberid = id

    values.modifieddate = moment(values.modifieddate)
      .set({ hour: 0, minute: 0, second: 0 })
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')

    actions.updateMemberchart({ id: chartid, ...values })
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value ? field.value : null }))
    })
  }

  // On Load
  useEffect(() => {
    actions.getMemberchart(chartid)

    actions.getPcpgroups({ per_page: 9999 })
    actions.getNonSuperUsers()
    setIsFirstGroupFetch(true)
  }, [chartid])

  useEffect(() => {
    if (groups && groups.length === 0 && isIsFirstGroupFetch === true) {
      actions.getPcpgroups({ per_page: 9999 })
    }
  }, [groups])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  useEffect(() => {
    const { status, payload } = state.statuscodes

    if (status === 2 && payload) {
      setStatuses(payload.filter(x => x.codetype === 'ChartStatus'))
      setTypes(payload.filter(x => x.codetype === 'ChartType'))
      setSources(payload.filter(x => x.codetype === 'ChartSource'))
    }
  }, [state.statuscodes])

  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedMemberchart

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedMemberchart({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully updated the record')
      actions.setUpdatedMemberchart({ status: 0, payload: null })
      const modifieddate = moment(payload?.modifieddate).format('YYYY-MM-DD')
      const postreviewcompleteddate = payload.postreviewcompleteddate
        ? moment(payload.postreviewcompleteddate).format('YYYY-MM-DD')
        : ''
      setDetails({ modifieddate, postreviewcompleteddate })
      actions.getMembercharts({ internalmemberid: id })
      actions.getMemberchartsactivityrecords({ chartid })
    }
  }, [state.updatedMemberchart])

  useEffect(() => {
    const { status, payload } = state.memberchart

    if (status === 2) {
      const {
        typeid,
        statusid,
        statusname,
        createddate,
        modifieddate,
        postreviewcompleteddate,
        appointmentdate,
        providergrouptin,
        pcpnpi,
        pcpname,
        sourceid,
        assigneduser,
        auditby,
        auditdate,
      } = payload

      // eslint-disable-next-line no-console
      console.log('payload', payload)

      setDetails({
        statusname,
        createddate: moment(createddate).format('YYYY-MM-DD'),
        modifieddate: moment(modifieddate).format('YYYY-MM-DD'),
        postreviewcompleteddate: postreviewcompleteddate
          ? moment(postreviewcompleteddate).format('YYYY-MM-DD')
          : '',
      })

      setFormValues({
        typeid,
        statusid,
        appointmentdate: moment(appointmentdate, 'YYYY-MM-DD'),
        providergrouptin,
        pcpnpi,
        pcpname,
        sourceid,
        assigneduser,
        auditby,
        auditdate: auditdate ? moment(auditdate) : null,
      })

      actions.getPcpnames({ providergrouptin, per_page: 9999 })
      actions.setMemberchart({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setMemberchart({ status: 0, payload: null })
    }
  }, [state.memberchart])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.pcpgroups

    if (status === 2) {
      setGroups(payload?.results)
    }
  }, [state.pcpgroups])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.pcpnames

    if (status === 2) {
      const namesResults = payload?.results
      const defaultPcpName = payload.results.find(result => formValues.pcpnpi === result.pcpnpi)

      // Include the default PCPNPI and PCPNAME on first group fetch
      if (!defaultPcpName && isIsFirstGroupFetch === true) {
        namesResults.push({
          pcpnpi: formValues.pcpnpi,
          pcpname: formValues.pcpname,
        })
      }

      setNames(namesResults)

      actions.setPcpnames({ status: 0, payload: null })
    } else if (status === 3) {
      setNames(null)
      actions.setPcpnames({ status: 0, payload: null })
    }
  }, [state.pcpnames])

  const handleGroupChange = value => {
    setIsFirstGroupFetch(false)
    actions.getPcpnames({ providergrouptin: value, per_page: 9999 })
    setNames(null)
    setFormValues(prev => ({ ...prev, pcpnpi: null }))
  }

  const handleUserChange = value => {
    actions.getNonSuperUsers()

    // if (typeof value === 'undefined') {
    //   actions.getNonSuperUsers()
    // }
  }

  // User search with debounce
  const handleUserSearch = e => {
    if (t) clearTimeout(t)

    setT(
      setTimeout(() => {
        actions.getNonSuperUsers({ search: e })
      }, 500),
    )
  }

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    formValues,
    details,
    statuses,
    types,
    sources,
    groups,
    names,
    handleGroupChange,
    handleUserChange,
    handleUserSearch,
  })
}

export default Container
