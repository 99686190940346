import { thunk, action } from 'easy-peasy'
import { listMemberhcccurrentsApi, getMemberhcccurrentApi } from '../../api/memberhcccurrent'

export default {
  // List Memberhcccurrents
  memberhcccurrents: { status: 0, payload: null },
  setMemberhcccurrents: action((state, payload = null) => {
    state.memberhcccurrents = payload
  }),
  getMemberhcccurrents: thunk(async (actions, params = {}) => {
    actions.setMemberhcccurrents({ status: 1, payload: null })

    listMemberhcccurrentsApi(params)
      .then(response => actions.setMemberhcccurrents({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberhcccurrents({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Memberdetail
  memberhcccurrent: { status: 0, payload: null },
  setMemberhcccurrent: action((state, payload = null) => {
    state.memberhcccurrent = payload
  }),
  getMemberhcccurrent: thunk(async (actions, id) => {
    actions.setMemberhcccurrent({ status: 1, payload: null })

    getMemberhcccurrentApi(id)
      .then(response => actions.setMemberhcccurrent({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setMemberhcccurrent({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
