import { thunk, action } from 'easy-peasy'
import {
  listRolesApi,
  getRoleApi,
  createRoleApi,
  updateRoleApi,
  deleteRoleApi,
} from '../../api/role'

export default {
  // List Roles
  roles: { status: 0, payload: null },
  setRoles: action((state, payload = null) => {
    state.roles = payload
  }),
  getRoles: thunk(async (actions, params = {}) => {
    actions.setRoles({ status: 1, payload: null })

    listRolesApi(params)
      .then(response => actions.setRoles({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setRoles({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Role
  createdRole: { status: 0, payload: null },
  setCreatedRole: action((state, payload = null) => {
    state.createdRole = payload
  }),
  createRole: thunk(async (actions, data = {}) => {
    actions.setCreatedRole({ status: 1, payload: null })

    createRoleApi(data)
      .then(response => actions.setCreatedRole({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedRole({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Role
  role: { status: 0, payload: null },
  setRole: action((state, payload = null) => {
    state.role = payload
  }),
  getRole: thunk(async (actions, id) => {
    actions.setRole({ status: 1, payload: null })

    getRoleApi(id)
      .then(response => actions.setRole({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setRole({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Role
  deletedRole: { status: 0, payload: null },
  setDeletedRole: action((state, payload = null) => {
    state.deletedRole = payload
  }),
  deleteRole: thunk(async (actions, id) => {
    actions.setDeletedRole({ status: 1, payload: null })

    deleteRoleApi(id)
      .then(response => actions.setDeletedRole({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedRole({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Role
  updatedRole: { status: 0, payload: null },
  setUpdatedRole: action((state, payload = null) => {
    state.updatedRole = payload
  }),
  updateRole: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedRole({ status: 1, payload: null })

    updateRoleApi(id, data)
      .then(response => actions.setUpdatedRole({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedRole({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
