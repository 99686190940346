import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import tableColumns from './columns'
import { CPT_POS } from '../../../../../../shared/constants/AppConst'
const EMPTY = '""'

const Container = ({ children, chartid }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    statuscodes: s.statuscodes,
    memberchartauditlogs: s.memberchartauditlogs,
    updatedMemberchart: s.updatedMemberchart,
    updatedMembercharticdcode: s.updatedMembercharticdcode,
    deletedMembercharticdcode: s.deletedMembercharticdcode,
    createdMembercharticdcode: s.createdMembercharticdcode,
    updatedCptcode: s.updatedCptcode,
    cptcodes: s.cptcodes,
    createdCptcode: s.createdCptcode,
    memberchartsfiles: s.memberchartsfiles,
    deletedmemberchartfile: s.deletedmemberchartfile,
    updatedmemberchartfile: s.updatedmemberchartfile,
    createdMemberchartsfile: s.createdMemberchartsfile,
  }))
  const actions = useStoreActions(a => ({
    getMemberChartAuditLogs: a.getMemberChartAuditLogs,
  }))

  // Local states
  const [listData, setListData] = useState([])

  // Columns
  const [columns, setColumns] = useState(null)
  const [statuses, setStatuses] = useState({})

  const reload = () => {
    actions.getMemberChartAuditLogs({
      chartid: chartid,
      per_page: 9999,
      ordering: '-id',
    })
  }

  const getStatusName = code => {
    if (!code) {
      return EMPTY
    }

    if (statuses[code]) {
      return statuses[code]
    }

    return code
  }

  const transformICD10Message = record => {
    if (record.actiontype === 'create') {
      return record.tovalue + ' - created'
    } else if (record.actiontype === 'delete') {
      return record.reference + ` - removed `
    } else if (record.actiontype === 'edit') {
      return (
        record.reference +
        ` - change ${record.field} from ${record.fromvalue} to ${record.tovalue} `
      )
    }
  }

  const transformDocumentMessage = record => {
    const parts = record.reference.split('/')
    const reference = parts[parts.length - 1]

    if (record.actiontype === 'create') {
      return reference + ' - created'
    } else if (record.actiontype === 'delete') {
      return reference + ` - removed `
    } else if (record.actiontype === 'edit') {
      return reference + ` - change ${record.field} from ${record.fromvalue} to ${record.tovalue} `
    }
  }

  const transformCPTMessage = record => {
    if (record.field === 'pos') {
      return `changed ${record.field} from ${record.fromvalue} to ${record.tovalue} `
    }

    if (record.fromvalue === EMPTY && record.tovalue !== EMPTY) {
      return `created ${record.field} - ${record.tovalue} `
    }

    if (record.fromvalue !== EMPTY && record.tovalue === EMPTY) {
      return `removed ${record.field} - ${record.fromvalue} `
    }

    return `changed ${record.field} from ${record.fromvalue} to ${record.tovalue} `
  }

  const transformChartMessage = record => {
    if (record.actiontype === 'create') {
      return 'chart - created'
    } else if (record.actiontype === 'delete') {
      return `chart - removed `
    } else if (record.actiontype === 'edit') {
      return 'chart' + ` - change ${record.field} from ${record.fromvalue} to ${record.tovalue} `
    }
  }

  const transform = record => {
    const recoded = {
      ...record,
      fromvalue: getStatusName(record.fromvalue),
      tovalue: getStatusName(record.tovalue),
    }

    if (record.module === 'CHART') {
      return transformChartMessage(recoded)
    } else if (record.module === 'ICD10') {
      return transformICD10Message(recoded)
    } else if (record.module === 'CPT') {
      return transformCPTMessage(recoded)
    } else if (record.module === 'DOCUMENT') {
      return transformDocumentMessage(recoded)
    } else {
      return (
        <span>
          {record.actiontype} Field: {record.field} from: {recoded.fromvalue} to: {recoded.tovalue}
        </span>
      )
    }
  }

  useEffect(() => {
    if (state.statuscodes && state.statuscodes.status === 2) {
      const statusHash = {}

      state.statuscodes.payload.forEach(element => {
        statusHash[element.statuscode] = element.codedescription
      })

      // add the cpt

      CPT_POS.forEach(cpt => {
        statusHash[cpt.value] = cpt.label
      })

      setStatuses(statusHash)
    }
  }, [state.statuscodes])

  useEffect(() => {
    reload()
  }, [])

  useEffect(() => {
    reload()
  }, [chartid])

  useEffect(() => {
    reload()
  }, [
    state.updatedMembercharticdcode,
    state.deletedMembercharticdcode,
    state.createdMembercharticdcode,
    state.updatedCptcode,
    state.cptcodes,
    state.createdCptcode,
    state.updatedMemberchart,
    state.memberchartsfiles,
    state.deletedmemberchartfile,
    state.updatedmemberchartfile,
    state.createdMemberchartsfile,
  ])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberchartauditlogs

    if (status === 2) {
      setColumns(tableColumns(transform))
      setListData(payload.results)
    }
  }, [state.memberchartauditlogs])

  return children({
    state,
    columns,
    listData,
    chartid,
  })
}

export default Container
