import React from 'react'
import { p } from '../../shared/constants/AppConst'
import { BiUser, BiLayer, BiGroup } from 'react-icons/bi'
import { AiOutlineUsergroupAdd, AiOutlineGroup } from 'react-icons/ai'

const superusersRights = [p.SUPER_USER_RO, p.SUPER_USER_RW]
const nonSuperusersRights = [p.USER_RO, p.USER_RW]
const rolesRights = [p.ROLES_RO, p.ROLES_RW]
const permissionsRights = [p.PERMISSIONS_RO, p.PERMISSIONS_RW]
const permissionGroupsRights = [p.PERMISSION_GROUPS_RO, p.PERMISSION_GROUPS_RW]

export default {
  id: 'access-management',
  title: 'Access Management',
  messageId: 'sidebar.access_management',
  type: 'group',
  rights: [...superusersRights, ...nonSuperusersRights, ...rolesRights, ...permissionsRights],
  children: [
    {
      id: '/access-management/superusers',
      title: 'Super Users',
      messageId: 'sidebar.access_management.superusers_list',
      type: 'item',
      icon: <BiUser />,
      path: '/access-management/superusers',
      rights: superusersRights,
    },
    {
      id: '/access-management/nonsuperusers',
      title: 'Non Super Users',
      messageId: 'sidebar.access_management.users_list',
      type: 'item',
      icon: <BiGroup />,
      path: '/access-management/nonsuperusers',
      rights: nonSuperusersRights,
    },
    {
      id: '/access-management/roles',
      title: 'Roles',
      messageId: 'sidebar.access_management.roles_list',
      type: 'item',
      icon: <AiOutlineUsergroupAdd />,
      path: '/access-management/roles',
      rights: rolesRights,
    },
    {
      id: '/access-management/permission_groups',
      title: 'Permission Groups',
      messageId: 'sidebar.access_management.permission_groups_list',
      type: 'item',
      icon: <AiOutlineGroup />,
      path: '/access-management/permission_groups',
      rights: permissionGroupsRights,
    },
    {
      id: '/access-management/permissions',
      title: 'Permissions',
      messageId: 'sidebar.access_management.permissions_list',
      type: 'item',
      icon: <BiLayer />,
      path: '/access-management/permissions',
      rights: permissionGroupsRights,
    },
  ],
}
