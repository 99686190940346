import React from 'react'
import MemberDetailsList from './MemberDetails/MemberDetailsList'
import MemberDetailsView from './MemberDetails/MemberDetailsView'
import GroupDashboardList from './GroupDashboard/MemberDetailsList'
import FieldCoderDashboardList from './FieldCoderDashboard/MemberDetailsList'
import ProviderEducationDashboard from './ProviderEducationDashboard'
import CoderReviewDashboard from './CoderReviewDashboard/MemberDetailsList'
import AbstractorDashboardList from './AbstractorDashboard/MemberDetailsList'

const Welcome = React.lazy(() => import('./Welcome'))
const Analytics = React.lazy(() => import('./Analytics'))
const Report = React.lazy(() => import('./Report'))

export default [
  {
    path: '/landing-page/welcome',
    element: <Welcome />,
  },
  {
    path: '/landing-page/analytics',
    element: <Analytics />,
  },
  {
    path: '/landing-page/report',
    element: <Report />,
  },
  {
    path: '/landing-page/memberdetails',
    element: <MemberDetailsList />,
  },
  {
    path: '/landing-page/memberdetails/view/:id',
    element: <MemberDetailsView />,
  },
  {
    path: '/landing-page/groupdashboard',
    element: <GroupDashboardList />,
  },
  {
    path: '/landing-page/fieldcoderdashboard',
    element: <FieldCoderDashboardList />,
  },
  {
    path: '/landing-page/coderreviewdashboard',
    element: <CoderReviewDashboard />,
  },
  {
    path: '/landing-page/abstractordashboard',
    element: <AbstractorDashboardList />,
  },
  {
    path: '/landing-page/providereducationdashboard',
    element: <ProviderEducationDashboard />,
  },
]
