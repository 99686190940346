import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listCP10Api = (params = {}) => Api.get(`${API_URL}/api/v1/crm/membercpts/`, { params })

export const updateCP10Api = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/crm/membercpts/${id}/`, data)

export const createCP10Api = (data = {}) => Api.post(`${API_URL}/api/v1/crm/membercpts/`, data)
