import PropTypes from 'prop-types'
import moment from 'moment'
import { Row, Col, Card } from 'antd'

export const MemberDetailsView = ({ details, pcpDetails, memberDetails }) => (
  <Row>
    <Col span={24}>
      <Card type="inner" title={`${details?.memberlastname}, ${details?.memberfirstname}`}>
        <Row gutter={32}>
          <Col span={8}>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Member Name:
              </Col>
              <Col span={12}>{`${details?.memberlastname}, ${details?.memberfirstname}`}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Member{"'"}s ID:
              </Col>
              <Col span={12}>{details?.memberid}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Date of Birth:
              </Col>
              <Col span={12}>{moment(details?.dob).format('MM/DD/YYYY')}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Gender:
              </Col>
              <Col span={12}>{details?.gender}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Address:
              </Col>
              <Col span={12}>
                <span>
                  {memberDetails.phyaddrline1} <br />
                </span>
                {memberDetails.phyaddrline2 && (
                  <span>
                    {memberDetails.phyaddrline2} <br />
                  </span>
                )}
                <span>
                  {memberDetails.phyaddrcity}, {memberDetails.phyaddrstate} <br />
                </span>
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Rendering Provider:
              </Col>
              <Col span={12}>{details?.lastpcpname}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Rendering Group:
              </Col>
              <Col span={12}>{pcpDetails?.providergroupname}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                LOB:
              </Col>
              <Col span={12}>{details?.lob}</Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Open Quality Gaps:
              </Col>
              <Col span={12}>{memberDetails?.open_gaps}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Closed Quality Gaps:
              </Col>
              <Col span={12}>{memberDetails?.closed_gaps}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Adjusted BUR 2021:
              </Col>
              <Col span={12}>{memberDetails?.adj_bur_2021}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                Adjusted BUR 2022:
              </Col>
              <Col span={12}>{memberDetails?.adj_bur_2022}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                BUR Change:
              </Col>
              <Col span={12}>{memberDetails?.bur_change}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                RAF:
              </Col>
              <Col span={12}>{memberDetails?.raf_a}</Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                New/Existing:
              </Col>
              <Col span={12}>{memberDetails?.newvsexisting}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                PCP Visit:
              </Col>
              <Col span={12}>{memberDetails?.pcp_visit_date}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                PHA Visit:
              </Col>
              <Col span={12}>{memberDetails?.last_pha_date}</Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold' }} span={12}>
                AWV Visit:
              </Col>
              <Col span={12}>{memberDetails?.awv_date}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
)

MemberDetailsView.propTypes = {
  details: PropTypes.any.isRequired,
  pcpDetails: PropTypes.any.isRequired,
  memberDetails: PropTypes.any.isRequired,
}

export default MemberDetailsView
