import saMessages from '../locales/es_ES.json'
import esES from 'antd/lib/locale/es_ES'

const saLang = {
  messages: {
    ...saMessages,
  },
  antLocale: esES,
  locale: 'es',
}

export default saLang
