import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberchartsactivityrecordApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/memberchartsactivityrecords/`, { params })
export const createMemberchartactivityrecordApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/crm/memberchartsactivityrecords`, data)
export const getMemberchartactivityrecordApi = id =>
  Api.get(`${API_URL}/api/v1/crm/memberchartsactivityrecords/${id}`)
export const deleteMemberchartactivityrecordApi = id =>
  Api.delete(`${API_URL}/api/v1/crm/memberchartsactivityrecords/${id}/`)
