/* eslint-disable react/require-default-props */
import React from 'react'
import './index.style.less'
import PropTypes from 'prop-types'
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider'

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext()
  return (
    <div className="app-logo">
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img src="/assets/images/logo-php-new.jpg" alt="Prominence" />
      ) : (
        <img src="/assets/images/logo-php-new.jpg" alt="Prominence" />
      )}
    </div>
  )
}

export default AppLogo

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
}
