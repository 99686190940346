import { thunk, action } from 'easy-peasy'
import {
  listDemo2sApi,
  getDemo2Api,
  createDemo2Api,
  updateDemo2Api,
  deleteDemo2Api,
} from '../../api/demo2'

export default {
  // List Demo2s
  demo2s: { status: 0, payload: null },
  setDemo2s: action((state, payload = null) => {
    state.demo2s = payload
  }),
  getDemo2s: thunk(async (actions, params = {}) => {
    actions.setDemo2s({ status: 1, payload: null })

    listDemo2sApi(params)
      .then(response => actions.setDemo2s({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDemo2s({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Demo2
  createdDemo2: { status: 0, payload: null },
  setCreatedDemo2: action((state, payload = null) => {
    state.createdDemo2 = payload
  }),
  createDemo2: thunk(async (actions, data = {}) => {
    actions.setCreatedDemo2({ status: 1, payload: null })

    createDemo2Api(data)
      .then(response => actions.setCreatedDemo2({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedDemo2({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Demo2
  demo2: { status: 0, payload: null },
  setDemo2: action((state, payload = null) => {
    state.demo2 = payload
  }),
  getDemo2: thunk(async (actions, id) => {
    actions.setDemo2({ status: 1, payload: null })

    getDemo2Api(id)
      .then(response => actions.setDemo2({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDemo2({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Demo2
  deletedDemo2: { status: 0, payload: null },
  setDeletedDemo2: action((state, payload = null) => {
    state.deletedDemo2 = payload
  }),
  deleteDemo2: thunk(async (actions, id) => {
    actions.setDeletedDemo2({ status: 1, payload: null })

    deleteDemo2Api(id)
      .then(response => actions.setDeletedDemo2({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedDemo2({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Demo2
  updatedDemo2: { status: 0, payload: null },
  setUpdatedDemo2: action((state, payload = null) => {
    state.updatedDemo2 = payload
  }),
  updateDemo2: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedDemo2({ status: 1, payload: null })

    updateDemo2Api(id, data)
      .then(response => actions.setUpdatedDemo2({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedDemo2({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
