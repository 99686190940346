import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'

import tableColumns from './columns'

const Container = ({ children, chartid }) => {
  // Global state and actions
  const state = useStoreState(s => ({
    memberchartsfiles: s.memberchartsfiles,
    deletedmemberchartfile: s.deletedmemberchartfile,
    updatedmemberchartfile: s.updatedmemberchartfile,
    createdMemberchartsfile: s.createdMemberchartsfile,
  }))
  const actions = useStoreActions(a => ({
    getMemberchartsfiles: a.getMemberchartsfiles,
    setMemberchartsfiles: a.setMemberchartsfiles,
    setDeletedMembercharfile: a.setDeletedMembercharfile,
    deleteMemberchartfile: a.deleteMemberchartfile,
  }))

  // Local states
  const [listData, setListData] = useState([])

  // Columns
  const [columns, setColumns] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState({})
  const [sortedInfo, setSortedInfo] = useState({})
  const [viewForm, setViewForm] = useState(false)
  const [fileid, setFileid] = useState(null)

  const addDocument = (id = null) => {
    setFileid(id)
    setViewForm(!viewForm)
  }

  const deleteDocument = (id = null) => {
    actions.deleteMemberchartfile(id)
  }

  const clearFilters = () => {
    setFilteredInfo({})
    setSortedInfo({})
  }

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  }

  const handleFilters = payload => {
    const fields = ['hcccode']
    const filters = { hcccode: [] }

    payload.forEach(p => {
      fields.forEach(f => {
        if (filters[f].filter(x => x.value === p[f]).length === 0) {
          filters[f].push({ text: p[f], value: p[f] })
        }
      })
    })

    return filters
  }

  useEffect(() => {
    chartid && actions.getMemberchartsfiles({ chartid })
  }, [chartid])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberchartsfiles

    if (status === 2) {
      // Create choices of filters in column headers
      const filters = handleFilters(payload)

      // Set created columns
      setColumns(tableColumns(filters, filteredInfo, sortedInfo, addDocument, deleteDocument))

      setListData(payload)

      actions.setMemberchartsfiles({ status: 0, payload: null })
    } else if (status === 3) {
      setListData(null)
      actions.setMemberchartsfiles({ status: 0, payload: null })
    }
  }, [state.memberchartsfiles, filteredInfo, sortedInfo])

  useEffect(() => {
    const { status, payload } = state.deletedmemberchartfile

    if (status === 2) {
      actions.getMemberchartsfiles({ chartid })
      actions.setDeletedMembercharfile({ status: 0, payload: null })
    } else if (status === 3) {
      actions.setDeletedMembercharfile({ status: 0, payload: null })
      message.error(`Oops, something went wrong!: ${JSON.stringify(payload)}`)
    }
  }, [state.deletedmemberchartfile])

  useEffect(() => {
    const { status } = state.updatedmemberchartfile

    if (status === 2) {
      setViewForm(!viewForm)
    }
  }, [state.updatedmemberchartfile])

  useEffect(() => {
    const { status } = state.createdMemberchartsfile

    if (status === 2) {
      setViewForm(!viewForm)
    }
  }, [state.createdMemberchartsfile])

  return children({
    state,
    columns,
    listData,
    chartid,
    clearFilters,
    handleChange,
    addDocument,
    setViewForm,
    viewForm,
    fileid,
  })
}

export default Container
