import { Row, Col } from 'antd'
import HCCCurrentList from './HCCCurrentList'

const HCCCurrent = props => (
  <Row gutter={16}>
    <Col span={24}>
      <HCCCurrentList {...props} />
    </Col>
  </Row>
)

export default HCCCurrent
