import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { sizePerPage, p, MEMBER_STATUS_ACTIVE } from '../../../../shared/constants/AppConst'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'

import tableColumns from './columns'
import qs from 'query-string'

const basePath = '/landing-page/memberdetails'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    memberenrollmentlatestrecords: s.memberenrollmentlatestrecords,
  }))
  const actions = useStoreActions(a => ({
    getMemberenrollmentlatestrecords: a.getMemberenrollmentlatestrecords,
  }))

  // Local states
  const [searchInput, setSearchInput] = useState('')
  const [selectedLOBs, setSelectedLOBs] = useState(null)
  const [selectedStatuses, setSelectedStatuses] = useState([MEMBER_STATUS_ACTIVE])
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    memberstatus: MEMBER_STATUS_ACTIVE,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
  })

  // Columns
  const columns = tableColumns(qs.parse(location.search), actions, auth.user, basePath)

  useEffect(() => {
    if (
      !has(auth.user, [
        p.MEMBER_DETAILS_VIEW_SEARCH_PAGE,
        p.MEMBER_DETAILS_VIEW_DETAILS_PAGE,
        p.MEMBER_DETAILS,
      ])
    ) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(`${location.search}&${location.lob}&${location.memberstatus}`)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    if (queries?.search) {
      setSearchInput(queries?.search)
      actions.getMemberenrollmentlatestrecords(queries)
    }
  }, [queries])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberenrollmentlatestrecords

    if (status === 2) {
      setListData(payload)

      setPagination(prev => ({
        ...prev,
        total: payload.length,
      }))
    }
  }, [state.memberenrollmentlatestrecords])

  // antd Table component's handleTableChange event function
  const onTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data
    setPagination(paginate)
  }

  // On Search
  const onSearch = value => {
    // Prepare Query Strings Filters
    const query = {
      ...queries,
      search: value,
      page: 1,
    }

    if (selectedLOBs) query.lob = selectedLOBs.join(',')
    if (selectedStatuses) query.memberstatus = selectedStatuses.join(',')

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const onResultFilter = value => {
    const results = state.memberenrollmentlatestrecords.payload.filter(
      row => JSON.stringify(row).toLowerCase().indexOf(value) !== -1,
    )

    setPagination(prev => ({
      ...prev,
      total: results.length,
    }))

    setListData(results)
  }

  const handleLOBChange = value => {
    setSelectedLOBs(value)
  }

  const handleStatusChange = value => {
    setSelectedStatuses(value)
  }

  return children({
    state,
    columns,
    queries,
    searchInput,
    setSearchInput,
    listData,
    pagination,
    onSearch,
    onTableChange,
    onResultFilter,
    basePath,
    handleLOBChange,
    handleStatusChange,
  })
}

export default Container
