import * as moment from 'moment'

export default transform => {
  return [
    {
      title: 'Module edited',
      dataIndex: 'module',
      key: 'module',
    },
    {
      title: 'Changelog',
      dataIndex: 'changelog',
      key: 'changelog',
      render: (text, record) => transform(record),
    },
    {
      title: 'Date',
      dataIndex: 'createddate',
      key: 'createddate',
      render: (text, record) => (
        <span>{moment(record.createddate, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
      ),
    },
    {
      title: 'Logged By',
      dataIndex: 'createdby',
      key: 'createdby',
    },
  ]
}
