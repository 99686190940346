import { thunk, action } from 'easy-peasy'
import {
  listDemo1sApi,
  getDemo1Api,
  createDemo1Api,
  updateDemo1Api,
  deleteDemo1Api,
} from '../../api/demo1'

export default {
  // List Demo1s
  demo1s: { status: 0, payload: null },
  setDemo1s: action((state, payload = null) => {
    state.demo1s = payload
  }),
  getDemo1s: thunk(async (actions, params = {}) => {
    actions.setDemo1s({ status: 1, payload: null })

    listDemo1sApi(params)
      .then(response => actions.setDemo1s({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDemo1s({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create Demo1
  createdDemo1: { status: 0, payload: null },
  setCreatedDemo1: action((state, payload = null) => {
    state.createdDemo1 = payload
  }),
  createDemo1: thunk(async (actions, data = {}) => {
    actions.setCreatedDemo1({ status: 1, payload: null })

    createDemo1Api(data)
      .then(response => actions.setCreatedDemo1({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedDemo1({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve Demo1
  demo1: { status: 0, payload: null },
  setDemo1: action((state, payload = null) => {
    state.demo1 = payload
  }),
  getDemo1: thunk(async (actions, id) => {
    actions.setDemo1({ status: 1, payload: null })

    getDemo1Api(id)
      .then(response => actions.setDemo1({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDemo1({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete Demo1
  deletedDemo1: { status: 0, payload: null },
  setDeletedDemo1: action((state, payload = null) => {
    state.deletedDemo1 = payload
  }),
  deleteDemo1: thunk(async (actions, id) => {
    actions.setDeletedDemo1({ status: 1, payload: null })

    deleteDemo1Api(id)
      .then(response => actions.setDeletedDemo1({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedDemo1({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update Demo1
  updatedDemo1: { status: 0, payload: null },
  setUpdatedDemo1: action((state, payload = null) => {
    state.updatedDemo1 = payload
  }),
  updateDemo1: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedDemo1({ status: 1, payload: null })

    updateDemo1Api(id, data)
      .then(response => actions.setUpdatedDemo1({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedDemo1({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
