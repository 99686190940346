import React from 'react'
import { Space, Popconfirm, Button } from 'antd'
import { Link } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

export default (actions, user, basePath) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'No. of Users',
      dataIndex: 'user_count',
      key: 'user_count',
    },
    {
      title: 'No. of Permissions',
      dataIndex: 'no_of_permissions',
      render: (text, record) => record.permissions?.length || 0,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          {has(user, p.ROLES_RW) ? (
            <>
              <Link to={`${basePath}/${record.id}/`}>
                <Button type="danger" ghost icon={<EditOutlined />} />
              </Link>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => actions.deleteRole(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type="danger" ghost icon={<EditOutlined />} disabled />
              <Button type="primary" icon={<DeleteOutlined />} disabled />
            </>
          )}
        </Space>
      ),
    },
  ]
}
