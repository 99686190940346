import React from 'react'
import ViewContainer from './container'

import AppRowContainer from '@crema/core/AppRowContainer'
import AppCard from '@crema/core/AppCard'

import { Spin, Typography, Row, Col, Form, Select, Divider, DatePicker, Button } from 'antd'
import { SaveFilled, LoadingOutlined } from '@ant-design/icons'
import { _rules } from './validations'

const { Title } = Typography
const { Option } = Select

const View = props => {
  return (
    <ViewContainer {...props}>
      {({
        state,
        initialValues,
        onFinish,
        onFinishFailed,
        onFieldsChange,
        form,
        statuses,
        types,
        sources,
        groups,
        names,
        handleGroupChange,
        chartid,
        handleUserSearch,
        handleUserChange,
      }) => (
        <>
          <AppRowContainer>
            <Col span={24}>
              <Spin
                spinning={
                  state.nonSuperUsers.status === 1 ||
                  state.createdMemberchart.status === 1 ||
                  state.statuscodes.status === 1 ||
                  state.pcpgroups.status === 1 ||
                  state.pcpnames.status === 1
                }
              >
                <AppCard>
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onFieldsChange={onFieldsChange}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={2}>Create Chart History</Title>
                        <Divider />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Type: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="typeid" rules={_rules('typeid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select Type"
                          >
                            {types &&
                              types.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Status: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="statusid" rules={_rules('statusid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select Status"
                          >
                            {statuses &&
                              statuses.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>Appointment Date: </Col>

                      <Col span={24}>
                        <Form.Item
                          type="date"
                          name="appointmentdate"
                          className="form-field"
                          rules={_rules('appointmentdate')}
                        >
                          <DatePicker style={{ width: '100%' }} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>Rendering Group: </Col>
                      <Col span={24}>
                        <Form.Item
                          name="providergrouptin"
                          className="form-field"
                          rules={_rules('providergrouptin')}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select Rendering Group"
                            onChange={handleGroupChange}
                          >
                            {groups &&
                              groups.map((g, gk) => (
                                <Option key={gk} value={g.providergrouptin}>
                                  {g.providergroupname}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: !names ? '25px' : '' }}>
                      <Col span={24}>Rendering Provider: </Col>
                      <Col span={24}>
                        {!names && <Select style={{ width: '100%' }} disabled />}
                        {names && (
                          <Form.Item name="pcpnpi" className="form-field" rules={_rules('pcpnpi')}>
                            <Select
                              showSearch
                              defaultValue={null}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                              }
                              style={{ width: '100%' }}
                              placeholder="Select Rendering Provider"
                            >
                              {names.map((n, nk) => (
                                <Option key={nk} value={n.pcpnpi}>
                                  {n.pcpname}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Source: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="sourceid" rules={_rules('sourceid')}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select Source"
                          >
                            {sources &&
                              sources.map((g, index) => (
                                <Option key={index} value={g.statuscode}>
                                  {g.codedescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Assigned User: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="assigneduser" rules={_rules('assigneduser')}>
                          <Select
                            style={{ width: '100%' }}
                            showSearch
                            name="assigneduser"
                            placeholder=""
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={handleUserSearch}
                            onChange={handleUserChange}
                            notFoundContent={null}
                            allowClear
                          >
                            {state.nonSuperUsers.status === 1 && (
                              <Option disabled>
                                <LoadingOutlined /> Searching...
                              </Option>
                            )}
                            {state.nonSuperUsers.status === 2 &&
                              state.nonSuperUsers.payload.results.map(d => (
                                <Option key={d.username}>{d.username}</Option>
                              ))}
                            {state.nonSuperUsers.status === 2 &&
                              state.nonSuperUsers.payload.results.length === 0 && (
                                <Option disabled>No Result</Option>
                              )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Col span={24}>Audit By: </Col>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="auditby">
                          <Select
                            style={{ width: '100%' }}
                            showSearch
                            name="auditby"
                            placeholder=""
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={handleUserSearch}
                            onChange={handleUserChange}
                            notFoundContent={null}
                            allowClear
                          >
                            {state.nonSuperUsers.status === 1 && (
                              <Option disabled>
                                <LoadingOutlined /> Searching...
                              </Option>
                            )}
                            {state.nonSuperUsers.status === 2 &&
                              state.nonSuperUsers.payload.results.map(d => (
                                <Option key={d.username}>{d.username}</Option>
                              ))}
                            {state.nonSuperUsers.status === 2 &&
                              state.nonSuperUsers.payload.results.length === 0 && (
                                <Option disabled>No Result</Option>
                              )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>Audit Date: </Col>

                      <Col span={24}>
                        <Form.Item type="date" name="auditdate" className="form-field">
                          <DatePicker style={{ width: '100%' }} placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" icon={<SaveFilled />}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </AppCard>
              </Spin>
            </Col>
          </AppRowContainer>
        </>
      )}
    </ViewContainer>
  )
}

export default View
