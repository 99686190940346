import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { sizePerPage, p, MEMBER_STATUS_ACTIVE } from '../../../../shared/constants/AppConst'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'

import tableColumns from './columns'
import tableAppointmentColumns from './appointment_columns'
import qs from 'query-string'

const basePath = '/landing-page/groupdashboard'

const Container = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthUser()

  // Global state and actions
  const state = useStoreState(s => ({
    pcpactivegroups: s.pcpactivegroups,
    pcpactivenames: s.pcpactivenames,
    memberenrollmentlatestrecords: s.memberenrollmentlatestrecords,
    memberlists: s.memberlists,
  }))
  const actions = useStoreActions(a => ({
    getPcpactivegroups: a.getPcpactivegroups,
    getPcpactivenames: a.getPcpactivenames,
    setPcpactivenames: a.setPcpactivenames,
    getMemberlists: a.getMemberlists,
    getMemberenrollmentlatestrecords: a.getMemberenrollmentlatestrecords,
  }))

  // Local states
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedName, setSelectedName] = useState(null)
  const [t, setT] = useState(null)
  const [groups, setGroups] = useState(null)
  const [names, setNames] = useState(null)
  const [listData, setListData] = useState([])
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    showSizeChanger: false,
  })
  const [queries, setQueries] = useState({
    ...qs.parse(location.search),
    per_page: sizePerPage,
  })
  const [listAppointmentData, setListAppointmentData] = useState([])
  const [appointmentPagination, setAppointmentPagination] = useState({
    total: 0,
    current: 1,
    pageSize: sizePerPage,
    memberstatus: MEMBER_STATUS_ACTIVE,
  })

  // Columns
  const columns = tableColumns(actions, auth.user, basePath)

  // Appointment Columns
  const appointmentColumns = tableAppointmentColumns(
    qs.parse(location.search),
    actions,
    auth.user,
    basePath,
  )

  useEffect(() => {
    actions.getPcpactivegroups()
  }, [])

  useEffect(() => {
    if (!has(auth.user, [p.GROUP_DASHBOARD_VIEW_SEARCH_PAGE])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [auth.user])

  // Query String Side Effect
  useEffect(() => {
    if (location.search) {
      const query = qs.parse(`${location.search}`)

      setPagination(prev => ({
        ...prev,
        current: Number(query.page) || 1,
      }))
    }
  }, [location.search])

  // Side effect of queries
  useEffect(() => {
    if (queries?.pcpgrptaxid /* && queries?.pcp_npi */) {
      setSelectedGroup(queries.pcpgrptaxid)
      setSelectedName(queries.pcp_npi)
      actions.getPcpactivenames({ pcpgrptaxid: queries.pcpgrptaxid })
      actions.getMemberlists(queries)

      // if (queries.pcp_npi) {
      //   actions.getMemberenrollmentlatestrecords({
      //     groupnumber: queries.pcpgrptaxid,
      //     lastpcpnpi: queries.pcp_npi,
      //     paginate: 1,
      //   })
      // }
    }
  }, [queries])

  // Side effect of Get List
  useEffect(() => {
    const { status, payload } = state.memberlists

    if (status === 2) {
      setListData(payload.results)

      setPagination(prev => ({
        ...prev,
        total: payload.count,
      }))
    }
  }, [state.memberlists])

  useEffect(() => {
    const { status, payload } = state.memberenrollmentlatestrecords

    if (status === 2) {
      setListAppointmentData(payload)

      setAppointmentPagination(prev => ({
        ...prev,
        total: payload.length,
      }))
    }
  }, [state.memberenrollmentlatestrecords])

  // Side effect of Groups
  useEffect(() => {
    const { status, payload } = state.pcpactivegroups

    if (status === 2) {
      setGroups(payload)
    }
  }, [state.pcpactivegroups])

  // Side effect of Names
  useEffect(() => {
    const { status, payload } = state.pcpactivenames

    if (status === 2 && payload && payload.length > 0) {
      setNames(payload)
      actions.setPcpactivenames({ status: 0, payload: null })
    } else if (status === 3) {
      setNames(null)
      actions.setPcpactivenames({ status: 0, payload: null })
    }
  }, [state.pcpactivenames])

  // antd Table component's handleTableChange event function
  const onTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data
    setPagination(paginate)

    // Prepare Query Strings Filters
    const query = {
      ...queries,
      page: paginate.current,
    }

    delete query.ordering

    if (sorter.columnKey && sorter.order) {
      query.ordering = `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  // Appointment antd Table component's handleTableChange event function
  const onAppointmentTableChange = (paginate, filters, sorter) => {
    // Update Pagination Data
    setAppointmentPagination(paginate)
  }

  // On Search
  const onSearch = () => {
    // Prepare Query Strings Filters

    if (selectedGroup /* && selectedName */) {
      const query = {
        ...queries,
        pcpgrptaxid: selectedGroup,
        pcp_npi: selectedName,
        page: 1,
      }

      setQueries(query)

      // Change URL
      navigate(`${basePath}/?${qs.stringify(query)}`)
    }
  }

  const onResultFilter = value => {
    const query = {
      ...queries,
      search: value,
      page: 1,
    }

    setQueries(query)

    // Change URL
    navigate(`${basePath}/?${qs.stringify(query)}`)
  }

  const handleGroupChange = value => {
    actions.getPcpactivenames({ pcpgrptaxid: value })
    setNames(null)
    setSelectedName(null)
    setSelectedGroup(value)

    const query = {
      ...queries,
      pcpgrptaxid: value,
      pcp_npi: '',
      page: 1,
    }

    setQueries(query)
  }

  const handleNameChange = value => {
    setSelectedName(value)

    if (!value) {
      const query = {
        ...queries,
        pcpgrptaxid: selectedGroup,
        pcp_npi: '',
        page: 1,
      }
      setQueries(query)
    }
  }

  return children({
    state,
    columns,
    appointmentColumns,
    queries,
    listData,
    pagination,
    listAppointmentData,
    appointmentPagination,
    setT,
    onSearch,
    onTableChange,
    onAppointmentTableChange,
    onResultFilter,
    handleGroupChange,
    handleNameChange,
    t,
    groups,
    names,
  })
}

export default Container
