const commonValidations = {
  typeid: [{ required: true, message: 'Type is required.' }],
  appointmentdate: [{ required: true, message: 'Appointment Date is required.' }],
  providergrouptin: [{ required: true, message: 'Rendering Group is required.' }],
  pcpnpi: [{ required: true, message: 'Rendering Provider is required.' }],
  statusid: [{ required: true, message: 'Latest Status is required.' }],
  sourceid: [{ required: true, message: 'Source is required.' }],
  assigneduser: [{ required: true, message: 'Assigned User is required.' }],
}

export const _rules = name => {
  return commonValidations[name] || []
}
