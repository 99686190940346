import React from 'react'
import ViewComponent from './container'
import AppPageMetadata from '@crema/core/AppPageMetadata'
import AppRowContainer from '@crema/core/AppRowContainer'
import { Spin, Table, Typography, Row, Col, Input, Select, Divider, Button, Alert } from 'antd'
import {
  lineOfBusinesses,
  memberStatuses,
  MEMBER_STATUS_ACTIVE,
  LOB_COMMERCIAL,
  LOB_EXCHANGE,
  LOB_DSNP,
  LOB_ADVANTAGE,
  LOB_SELF_FUNDED,
} from '../../../../shared/constants/AppConst'

const { Option } = Select

const { Title } = Typography

const View = () => (
  <ViewComponent>
    {({
      state,
      columns,
      queries,
      searchInput,
      setSearchInput,
      listData,
      pagination,
      onSearch,
      onTableChange,
      onResultFilter,
      handleLOBChange,
      handleStatusChange,
    }) => (
      <Spin spinning={state.memberenrollmentlatestrecords.status === 1}>
        <AppPageMetadata title="Member Details" />
        <AppRowContainer>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={2}>Member Details</Title>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  size="large"
                  placeholder="Search member"
                  onChange={e => setSearchInput(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onSearch(e.target.value)
                    }
                  }}
                  disabled={state.memberenrollmentlatestrecords.status === 1}
                  defaultValue={(queries && queries?.search) || ''}
                />
              </Col>
              <Col>
                <Button type="primary" onClick={() => onSearch(searchInput)}>
                  Search
                </Button>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Title level={5}>LOB</Title>
                <Select
                  mode="multiple"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Select lob"
                  defaultValue={
                    (queries && queries.lob && queries?.lob.split(',')) || [
                      LOB_COMMERCIAL,
                      LOB_EXCHANGE,
                      LOB_DSNP,
                      LOB_ADVANTAGE,
                      LOB_SELF_FUNDED,
                    ]
                  }
                  onChange={handleLOBChange}
                  optionLabelProp="label"
                >
                  {lineOfBusinesses &&
                    lineOfBusinesses.map((lob, index) => (
                      <Option key={index} value={lob.value} label={lob.label}>
                        <div className="demo-option-label-item">{lob.label}</div>
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={6}>
                <Title level={5}>Status</Title>
                <Select
                  mode="multiple"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Select status"
                  defaultValue={
                    (queries && queries.memberstatus && queries?.memberstatus.split(',')) || [
                      MEMBER_STATUS_ACTIVE,
                    ]
                  }
                  onChange={handleStatusChange}
                  optionLabelProp="label"
                >
                  {memberStatuses &&
                    memberStatuses.map((status, index) => (
                      <Option key={index} value={status.value} label={status.label}>
                        <div className="demo-option-label-item">{status.label}</div>
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
            <Divider />
            {queries.search ? (
              <>
                <Row>
                  <Col span={12}>
                    {state.memberenrollmentlatestrecords.status === 2 && (
                      <Title level={4}>{listData.length} item(s) found</Title>
                    )}
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Input
                      style={{ maxWidth: 300 }}
                      size="large"
                      placeholder="Search From Results"
                      onChange={e => onResultFilter(e.target.value)}
                      disabled={state.memberenrollmentlatestrecords.status === 1}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {state.memberenrollmentlatestrecords.status === 3 ? (
                      <Alert
                        message={
                          state.memberenrollmentlatestrecords.payload.details ||
                          'Oops, something went wrong...'
                        }
                        type="error"
                      />
                    ) : (
                      <Table
                        columns={columns}
                        rowKey={record => record.internalmemberid}
                        dataSource={listData}
                        pagination={pagination}
                        loading={state.memberenrollmentlatestrecords.status === 1}
                        onChange={onTableChange}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </AppRowContainer>
      </Spin>
    )}
  </ViewComponent>
)

export default View
