import React from 'react'
import { p } from '../../shared/constants/AppConst'
import { BiBarChartAlt2 } from 'react-icons/bi'
import { BsBarChartFill } from 'react-icons/bs'
import { AiOutlineUser, AiFillDashboard } from 'react-icons/ai'

const analyticsRights = [p.ANALYTICS_RO]
const reportsRights = [p.REPORT_RO]
const memberDetailsRights = [
  p.MEMBER_DETAILS_VIEW_SEARCH_PAGE,
  p.MEMBER_DETAILS_VIEW_DETAILS_PAGE,
  p.MEMBER_DETAILS,
]

const groupDashboardRights = [p.GROUP_DASHBOARD_VIEW_SEARCH_PAGE]
const fieldcodeDashboardRights = [p.FD_VIEW]
const abstractorDashboardRights = [p.AD_VIEW]
const coderReviewDashboardRights = [p.CD_VIEW]
const providerEducationDashboardRights = [p.PED_VIEW]

export default {
  id: '/landing-page',
  title: 'Dashboard',
  messageId: 'sidebar.dashboard',
  type: 'group',
  rights: [...analyticsRights, ...reportsRights],
  alwaysVisible: true,
  children: [
    {
      id: '/landing-page/welcome',
      title: 'Analytics',
      messageId: 'sidebar.dashboard.welcome',
      type: 'item',
      icon: <AiFillDashboard />,
      path: '/landing-page/welcome',
      rights: [],
      alwaysVisible: true,
    },
    {
      id: '/landing-page/analytics',
      title: 'Analytics',
      messageId: 'sidebar.dashboard.analytics',
      type: 'item',
      icon: <BsBarChartFill />,
      path: '/landing-page/analytics',
      rights: analyticsRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/report',
      title: 'Report',
      messageId: 'sidebar.dashboard.report',
      type: 'item',
      icon: <BiBarChartAlt2 />,
      path: '/landing-page/report',
      rights: reportsRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/memberdetails',
      title: 'Member Details',
      messageId: 'sidebar.dashboard.member_details',
      type: 'item',
      icon: <AiOutlineUser />,
      path: '/landing-page/memberdetails',
      rights: memberDetailsRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/groupdashboard',
      title: 'Group Dashboard',
      messageId: 'sidebar.dashboard.group_dashboard',
      type: 'item',
      icon: <AiOutlineUser />,
      path: '/landing-page/groupdashboard',
      rights: groupDashboardRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/fieldcoderdashboard',
      title: 'Coder Dashboard',
      messageId: 'sidebar.dashboard.field_coder_dashboard',
      type: 'item',
      icon: <AiOutlineUser />,
      path: '/landing-page/fieldcoderdashboard',
      rights: fieldcodeDashboardRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/coderreviewdashboard',
      title: 'Coder Review',
      messageId: 'sidebar.dashboard.coder_review_dashboard',
      type: 'item',
      icon: <AiOutlineUser />,
      path: '/landing-page/coderreviewdashboard',
      rights: coderReviewDashboardRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/abstractordashboard',
      title: 'Abstractor Dashboard',
      messageId: 'sidebar.dashboard.abstractor_dashboard',
      type: 'item',
      icon: <AiOutlineUser />,
      path: '/landing-page/abstractordashboard',
      rights: abstractorDashboardRights,
      alwaysVisible: false,
    },
    {
      id: '/landing-page/providereducationdashboard',
      title: 'Provider Educatio Dashboard',
      messageId: 'sidebar.dashboard.providereducation_dashboard',
      type: 'item',
      icon: <AiOutlineUser />,
      path: '/landing-page/providereducationdashboard',
      rights: providerEducationDashboardRights,
      alwaysVisible: false,
    },
  ],
}
