export default (filters, filteredInfo, sortedInfo, handleHccModal) => {
  const columns = [
    {
      title: 'HCC',
      width: 120,
      dataIndex: 'hcc',
      key: 'hcc',
      sorter: (a, b) => a.hcc - b.hcc,
      sortOrder: sortedInfo.columnKey === 'hcc' ? sortedInfo.order : null,
      filters: filters.hcc,
      onFilter: (value, record) => record.hcc === value,
      filteredValue: filteredInfo.hcc || null,
    },
    {
      title: 'HCC Description',
      dataIndex: 'hccdescription',
      key: 'hccdescription',
      sorter: (a, b) => a.hccdescription - b.hccdescription,
      sortOrder: sortedInfo.columnKey === 'hccdescription' ? sortedInfo.order : null,
      filters: filters.hccdescription,
      onFilter: (value, record) => record.hccdescription === value,
      filteredValue: filteredInfo.hccdescription || null,
    },
    {
      title: 'Y2020',
      width: 100,
      dataIndex: 'y2020',
      key: 'y2020',
      sorter: (a, b) => a.y2020 - b.y2020,
      sortOrder: sortedInfo.columnKey === 'y2020' ? sortedInfo.order : null,
      filters: filters.y2020,
      onFilter: (value, record) => record.y2020 === value,
      filteredValue: filteredInfo.y2020 || null,
      render: (text, record) =>
        record.y2020 !== null && (
          <a href="#" onClick={() => handleHccModal(record.hcc, 2020, record.y2020)}>
            {record.y2020}
          </a>
        ),
    },
    {
      title: 'Y2021',
      width: 100,
      dataIndex: 'y2021',
      key: 'y2021',
      sorter: (a, b) => a.y2021 - b.y2021,
      sortOrder: sortedInfo.columnKey === 'y2021' ? sortedInfo.order : null,
      filters: filters.y2021,
      onFilter: (value, record) => record.y2021 === value,
      filteredValue: filteredInfo.y2021 || null,
      render: (text, record) =>
        record.y2021 !== null && (
          <a href="#" onClick={() => handleHccModal(record.hcc, 2021, record.y2021)}>
            {record.y2021}
          </a>
        ),
    },
    {
      title: 'Y2022',
      width: 100,
      dataIndex: 'y2022',
      key: 'y2022',
      sorter: (a, b) => a.y2022 - b.y2022,
      sortOrder: sortedInfo.columnKey === 'y2022' ? sortedInfo.order : null,
      filters: filters.y2022,
      onFilter: (value, record) => record.y2022 === value,
      filteredValue: filteredInfo.y2022 || null,
      render: (text, record) =>
        record.y2022 !== null && (
          <a href="#" onClick={() => handleHccModal(record.hcc, 2022, record.y2022)}>
            {record.y2022}
          </a>
        ),
    },
    {
      title: 'Y2023',
      width: 100,
      dataIndex: 'y2023',
      key: 'y2023',
      sorter: (a, b) => a.y2023 - b.y2023,
      sortOrder: sortedInfo.columnKey === 'y2023' ? sortedInfo.order : null,
      filters: filters.y2023,
      onFilter: (value, record) => record.y2023 === value,
      filteredValue: filteredInfo.y2023 || null,
      render: (text, record) =>
        record.y2023 !== null && (
          <a href="#" onClick={() => handleHccModal(record.hcc, 2023, record.y2023)}>
            {record.y2023}
          </a>
        ),
    },
  ]

  return columns
}
