import { initialUrl } from '../shared/constants/AppConst'

import { errorPagesConfigs } from './errorPages'
import { authRouteConfig } from './auth'
import { profileConfig } from './profile'

import userManagement from './userManagement'
import landingPage from './landingPage'
import demoPages from './demoPages'

import Error404 from './errorPages/Error404'
import Error403 from './errorPages/Error403'
import React from 'react'

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    // eslint-disable-next-line prettier/prettier
    // @crema defaults
    ...profileConfig,

    // Custom Containers
    ...userManagement.routes,
    ...landingPage.routes,
    ...demoPages.routes,
  ],
}

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
}

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
}

export { authorizedStructure, unAuthorizedStructure, anonymousStructure }
