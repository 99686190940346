import React from 'react'
import { Space, Popconfirm, Button, Select, DatePicker } from 'antd'
import { EditOutlined, UploadOutlined } from '@ant-design/icons'

import moment from 'moment'

import {
  QUALITY_GAP_STATUS_OPEN,
  QUALITY_GAP_STATUS_FOR_REVIEW,
  qGapCoderStatuses,
} from '../../../../../../shared/constants/AppConst'

export default (filters, filteredInfo, users, handleUpdate, setQualityGapId, setViewDocument) => {
  const statusHandler = status => {
    let labeledStatus = 'Open'

    if (status === 1) {
      labeledStatus = 'Closed'
    } else if (status === 2) {
      labeledStatus = 'For Review'
    }

    return labeledStatus
  }

  const columns = [
    {
      title: 'Measure Code',
      dataIndex: 'measureid',
      key: 'measureid',
      sorter: (a, b) => a.measureid - b.measureid,
      filters: filters.measureid,
      onFilter: (value, record) => record.measureid === value,
      filteredValue: filteredInfo.measureid || null,
    },
    {
      title: 'Measure',
      dataIndex: 'measurenameshort',
      key: 'measurenameshort',
      sorter: (a, b) => a.measurenameshort - b.measurenameshort,
      filters: filters.measurenameshort,
      onFilter: (value, record) => record.measurenameshort === value,
      filteredValue: filteredInfo.measurenameshort || null,
    },
    {
      title: 'Status',
      dataIndex: 'compliant',
      key: 'compliant',
      sorter: (a, b) => a.compliant - b.compliant,
      render: (text, record) => statusHandler(Number(text)),
      filters: [
        {
          text: 'Open',
          value: 0,
        },
        {
          text: 'Closed',
          value: 1,
        },
        {
          text: 'For Review',
          value: 2,
        },
      ],
      onFilter: (value, record) => record.compliant === value,
      filteredValue: filteredInfo.compliant || null,
    },
    {
      title: 'Coder Status',
      key: `coderstatus`,
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.coderstatus}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) handleUpdate(record, 'coderstatus', v)
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {qGapCoderStatuses &&
              qGapCoderStatuses.map((s, index) => (
                <Select.Option key={index} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'PHA date',
      key: `phadate`,
      render: (text, record) => (
        <>
          <DatePicker
            defaultValue={record.phadate ? moment(record.phadate) : null}
            style={{ width: '100%' }}
            placeholder=""
            onChange={v => {
              if (v) handleUpdate(record, 'phadate', moment(v).format('YYYY-MM-DD'))
            }}
          />
        </>
      ),
    },
    {
      title: 'Date of service',
      key: `requestdate`,
      render: (text, record) => `${moment(record?.requestdate, 'YYYY-MM-DD').format('MM/DD/YYYY')}`,
    },
    {
      title: 'Documents',
      key: `documents`,
      render: (text, record) =>
        record.filename ? (
          <a href={record.file_url} target="_blank" rel="noreferrer">
            {record.filename && record.filename.split('/').pop(-1)}
          </a>
        ) : (
          <Button
            icon={<UploadOutlined />}
            onClick={() => {
              setViewDocument(true)
              setQualityGapId(record.id)
            }}
          >
            Upload
          </Button>
        ),
    },
    {
      title: 'Assigned Reviewer',
      dataIndex: 'assignedviewer',
      key: 'assignedviewer',
      render: (text, record) => (
        <>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={record?.assignedviewer}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => {
              if (v) {
                handleUpdate(record, 'assignedviewer', v)
              }
            }}
            style={{ width: '100%' }}
            placeholder=""
          >
            {users &&
              users.map((u, index) => (
                <Select.Option key={index} value={u.email}>
                  {u.email}
                </Select.Option>
              ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          {record?.compliant === QUALITY_GAP_STATUS_OPEN ? (
            <>
              <Popconfirm
                title="Press confirm to change status"
                onConfirm={() => handleUpdate(record, 'compliant', QUALITY_GAP_STATUS_FOR_REVIEW)}
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button type="primary" icon={<EditOutlined />}>
                  Request Change
                </Button>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type="primary" icon={<EditOutlined />} disabled />
            </>
          )}
        </Space>
      ),
    },
  ]

  return columns
}
