import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listNonSuperUsersApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/nonsuperusers`, { params })
export const createNonSuperUserApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/nonsuperusers`, data)
export const getNonSuperUserApi = id => Api.get(`${API_URL}/api/v1/nonsuperusers/${id}`)
export const deleteNonSuperUserApi = id => Api.delete(`${API_URL}/api/v1/nonsuperusers/${id}`)
export const updateNonSuperUserApi = (id, data = {}) =>
  Api.patch(`${API_URL}/api/v1/nonsuperusers/${id}`, data)
