import { thunk, action } from 'easy-peasy'
import {
  listPermissionGroupsApi,
  getPermissionGroupApi,
  createPermissionGroupApi,
  updatePermissionGroupApi,
  deletePermissionGroupApi,
} from '../../api/permission_group'

export default {
  // List PermissionGroups
  permissionGroups: { status: 0, payload: null },
  setPermissionGroups: action((state, payload = null) => {
    state.permissionGroups = payload
  }),
  getPermissionGroups: thunk(async (actions, params = {}) => {
    actions.setPermissionGroups({ status: 1, payload: null })

    listPermissionGroupsApi(params)
      .then(response => actions.setPermissionGroups({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPermissionGroups({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Create PermissionGroup
  createdPermissionGroup: { status: 0, payload: null },
  setCreatedPermissionGroup: action((state, payload = null) => {
    state.createdPermissionGroup = payload
  }),
  createPermissionGroup: thunk(async (actions, data = {}) => {
    actions.setCreatedPermissionGroup({ status: 1, payload: null })

    createPermissionGroupApi(data)
      .then(response => actions.setCreatedPermissionGroup({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setCreatedPermissionGroup({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Retrieve PermissionGroup
  permissionGroup: { status: 0, payload: null },
  setPermissionGroup: action((state, payload = null) => {
    state.permissionGroup = payload
  }),
  getPermissionGroup: thunk(async (actions, id) => {
    actions.setPermissionGroup({ status: 1, payload: null })

    getPermissionGroupApi(id)
      .then(response => actions.setPermissionGroup({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setPermissionGroup({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Delete PermissionGroup
  deletedPermissionGroup: { status: 0, payload: null },
  setDeletedPermissionGroup: action((state, payload = null) => {
    state.deletedPermissionGroup = payload
  }),
  deletePermissionGroup: thunk(async (actions, id) => {
    actions.setDeletedPermissionGroup({ status: 1, payload: null })

    deletePermissionGroupApi(id)
      .then(response => actions.setDeletedPermissionGroup({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setDeletedPermissionGroup({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),

  // Update PermissionGroup
  updatedPermissionGroup: { status: 0, payload: null },
  setUpdatedPermissionGroup: action((state, payload = null) => {
    state.updatedPermissionGroup = payload
  }),
  updatePermissionGroup: thunk(async (actions, { id, ...data }) => {
    actions.setUpdatedPermissionGroup({ status: 1, payload: null })

    updatePermissionGroupApi(id, data)
      .then(response => actions.setUpdatedPermissionGroup({ status: 2, payload: response.data }))
      .catch(e =>
        actions.setUpdatedPermissionGroup({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e,
        }),
      )
  }),
}
