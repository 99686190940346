import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'
import { message, Form } from 'antd'
import { useAuthUser } from '@crema/utility/AuthHooks'
import { has } from '../../../../shared/helpers/permissions'
import { p } from '../../../../shared/constants/AppConst'

const initialValues = {
  name: '',
}

const basePath = '/access-management/permission_groups'

const Container = ({ children }) => {
  const { id } = useParams()

  // Global state and actions
  const state = useStoreState(s => ({
    createdPermissionGroup: s.createdPermissionGroup,
    updatedPermissionGroup: s.updatedPermissionGroup,
    permissionGroup: s.permissionGroup,
  }))

  const actions = useStoreActions(a => ({
    setPermissionGroup: a.setPermissionGroup,
    setCreatedPermissionGroup: a.setCreatedPermissionGroup,
    setUpdatedPermissionGroup: a.setUpdatedPermissionGroup,
    createPermissionGroup: a.createPermissionGroup,
    updatePermissionGroup: a.updatePermissionGroup,
    getPermissionGroup: a.getPermissionGroup,
  }))

  // Form Hook
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)

  const navigate = useNavigate()
  const auth = useAuthUser()

  const onFinish = values => {
    if (id === 'new') {
      actions.createPermissionGroup(values)
    } else {
      actions.updatePermissionGroup({ id, ...values })
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Please check the form for errors')
    // eslint-disable-next-line no-console
    console.info('Failed', errorInfo)
  }

  const onFieldsChange = changedFields => {
    changedFields.forEach(field => {
      setFormValues(prev => ({ ...prev, [field.name]: field.value }))
    })
  }

  useEffect(() => {
    if (!has(auth.user, [p.PERMISSIONS_RW])) {
      navigate('/extra-pages/error-pages/error-401')
    }
  }, [])

  // On Load
  useEffect(() => {
    if (id !== 'new') {
      actions.getPermissionGroup(id)
    }

    // Reset retrieve state on unmount
    return () => {
      actions.setPermissionGroup({ status: 0, payload: null })
    }
  }, [])

  // Side effect of state.permissionGroup
  useEffect(() => {
    const { status, payload } = state.permissionGroup

    if (status === 2) {
      // Set initial values of form
      setFormValues({
        name: payload.name,
        code: payload.code,
        description: payload.description,
      })
    }
  }, [state.permissionGroup])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  // Side effect of createdPermissionGroup
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.createdPermissionGroup

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setCreatedPermissionGroup({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully added new record')
      navigate(basePath)
      actions.setCreatedPermissionGroup({ status: 0, payload: null })
    }
  }, [state.createdPermissionGroup])

  // Side effect of updatedPermissionGroup
  useEffect(() => {
    // code here will execute
    const { status, payload } = state.updatedPermissionGroup

    if (status === 3) {
      const errorMsg = Object.keys(payload).map(key => `${key}: ${payload[key]}`)
      message.error(errorMsg.join(', '))
      actions.setUpdatedPermissionGroup({ status: 0, payload: null })
    } else if (status === 2) {
      message.success('Successfully updated the record')
      navigate(basePath)
      actions.setUpdatedPermissionGroup({ status: 0, payload: null })
    }
  }, [state.updatedPermissionGroup])

  useEffect(() => {
    setLoading(
      state.permissionGroup.status === 1 ||
        state.createdPermissionGroup.status === 1 ||
        state.updatedPermissionGroup.status === 1,
    )
  }, [state.permissionGroup, state.createdPermissionGroup, state.updatedPermissionGroup])

  return children({
    state,
    initialValues,
    onFinish,
    onFinishFailed,
    onFieldsChange,
    form,
    id,
    loading,
    basePath,
  })
}

export default Container
