import Api from '@crema/services/ApiConfig'

const API_URL = process.env.REACT_APP_API_URL

export const listMemberenrollmentlatestrecordsApi = (params = {}) =>
  Api.get(`${API_URL}/api/v1/crm/memberenrollmentlatestrecords/`, { params })
export const createMemberenrollmentlatestrecordApi = (data = {}) =>
  Api.post(`${API_URL}/api/v1/memberenrollmentlatestrecords`, data)
export const getMemberenrollmentlatestrecordApi = id =>
  Api.get(`${API_URL}/api/v1/crm/memberenrollmentlatestrecords/${id}`)
