export const listChartsApi = (params = {}) => {
  return new Promise(resolve => {
    const data = {
      count: 0,
      per_page: 10,
      total_pages: 0,
      results: [],
    }
    resolve({ data })
  })
}

export const listMedicalrecordsApi = (params = {}) => {
  return new Promise(resolve => {
    const data = {
      count: 0,
      per_page: 10,
      total_pages: 0,
      results: [],
    }
    resolve({ data })
  })
}

export const listPcpsApi = (params = {}) => {
  return new Promise(resolve => {
    const data = {
      count: 0,
      per_page: 10,
      total_pages: 0,
      results: [],
    }
    resolve({ data })
  })
}
